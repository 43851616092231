import { InvoiceValues } from '@components/contracts/invoices/types';

export const transformInvoiceData = (
  data: InvoiceFragment | undefined,
): InvoiceValues => {
  return {
    order: data?.attributes?.orderId?.data?.id ?? '',
    orderUuid: data?.attributes?.orderId?.data?.attributes?.orderId ?? '',
    invoice: data?.id,
    dueDate: data?.attributes?.orderId?.data?.attributes?.dueDate ?? '',
    businessLocation:
      data?.attributes?.orderId?.data?.attributes?.businessLocation?.data,
    invoiceId: data?.attributes?.invoiceId,
    comment: data?.attributes?.comment ?? '',
    createdAt: data?.attributes?.createdAt ?? '',
    customerName:
      data?.attributes?.orderId?.data?.attributes?.contact?.data?.attributes
        ?.fullName ||
      data?.attributes?.orderId?.data?.attributes?.company?.data?.attributes
        ?.name,
    shippingContact: {
      id: data?.attributes?.shippingContact?.data?.id ?? '',
      name: data?.attributes?.shippingContact?.data?.attributes?.name ?? '',
      address:
        data?.attributes?.shippingContact?.data?.attributes?.address ?? '',
      email: data?.attributes?.shippingContact?.data?.attributes?.email ?? '',
      phoneNumber:
        data?.attributes?.shippingContact?.data?.attributes?.phoneNumber ?? '',
    },
    clientMessage: data?.attributes?.clientMessage,
    fileId:
      data?.attributes?.fileItem?.data?.attributes?.attachedFile?.data?.id ??
      '',
    fileItemId: data?.attributes?.fileItem?.data?.id ?? '',
    terms: data?.attributes?.terms,
    isShowOrderItemsImages: data?.attributes?.isShowOrderItemsImages ?? false,
  };
};
