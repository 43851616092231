import { MERGE_TAGS_LIST } from '@components/marketing/constants';
import { CustomButton } from '@ui/button/Button';
import { Flex } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import styles from './index.module.scss';

interface Props {
  onChange?: (value: string) => void;
  defaultValue?: string;
  customTags?: { title: string; value: string }[];
}

export const CustomTextArea: FC<Props> = ({
  customTags,
  onChange,
  defaultValue,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue ?? '');
  const inputRef = useRef<any>(null);

  const handleTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(e.target.value);
      setInputValue(e.target.value);
    },
    [onChange],
  );

  const handleInsertTag = useCallback(
    (tag: string) => () => {
      const input = inputRef.current?.resizableTextArea?.textArea;

      if (!input) return;

      const start = input.selectionStart;
      const end = input.selectionEnd;

      const newValue =
        inputValue.substring(0, start) +
        `{{${tag}}}` +
        inputValue.substring(end);

      setInputValue(newValue);
      onChange?.(newValue);

      setTimeout(() => {
        input.setSelectionRange(start + tag.length + 4, start + tag.length + 4);
        input.focus();
      }, 0);
    },
    [inputValue, onChange],
  );

  const tags = customTags ?? MERGE_TAGS_LIST;

  return (
    <div className={styles.container}>
      <TextArea
        className={styles.textarea}
        ref={inputRef}
        onChange={handleTextChange}
        value={inputValue}
        placeholder={'Enter Text'}
      />
      <Flex gap={8} className={styles.tagList} wrap>
        {tags.map((item) => (
          <CustomButton
            key={item.title}
            className={styles.tag}
            type={'text'}
            size={'small'}
            onClick={handleInsertTag(item.value)}
          >
            {item.title}
          </CustomButton>
        ))}
      </Flex>
    </div>
  );
};
