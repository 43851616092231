import { useUpdateInvoice } from '@components/contracts/invoices/hooks/useUpdateInvoice';
import { RepairTicketViewFieldsUi } from '@components/contracts/repairTickets/RepairTicketViewFieldsUi';
import { useDocumentPermissionInfo } from '@hooks/documentPermissions/useDocumentPermissionInfo';
import { FC } from 'react';

interface Props {
  uuid: string;
  useItemsVisibility?: boolean;
}

export const ExternalRepairTicketViewFields: FC<Props> = ({
  uuid,
  useItemsVisibility,
}) => {
  const { initialValues } = useUpdateInvoice(uuid as string);
  const {
    isExternalRepairTicketPriceEnabled,
    isShowOrderNoteAtExternalRepairTicker,
  } = useDocumentPermissionInfo();

  return (
    <RepairTicketViewFieldsUi
      orderId={initialValues.order ?? ''}
      useItemsVisibility={useItemsVisibility}
      isPriceVisible={isExternalRepairTicketPriceEnabled}
      isCustomerInfoVisible={false}
      isOrderNoteVisible={isShowOrderNoteAtExternalRepairTicker}
    />
  );
};
