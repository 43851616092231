import { FC, lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const RequestsPage = lazy<FC>(
  () => import('@pages/contracts/purchase-requests/index'),
);
const UpdateInvoiceFrom = lazy<FC>(
  () => import('@components/contracts/invoices/forms/UpdateInvoiceFrom'),
);
const RequestView = lazy<FC>(
  () => import('@pages/contracts/purchase-requests/RequestView'),
);

const { index, preview } = ROUTES.tenant.contracts.purchase;
export const purchaseRequestRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      Component: RequestsPage,
    },
    {
      path: `${preview}/:uuid`,
      Component: RequestView,
    },
    {
      path: ':uuid',
      Component: UpdateInvoiceFrom,
    },
  ],
};
