import { FC, useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib';

import { useTaskLocationsLazyQuery } from '@/graphql';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

interface Props extends SelectProps {
  onChange?: (value: string) => void;
  initFilter?: CompanyFiltersInput;
}

export const TaskLocationSelect: FC<Props> = ({
  onChange,
  defaultValue,
  initFilter,
  ...props
}) => {
  const [filters, setFilters] = useState<TaskLocationFiltersInput>({});

  const [fetch, { data, loading }] = useTaskLocationsLazyQuery({
    variables: {
      filters: {
        ...initFilter,
        ...filters,
      },
      pagination: {
        limit: 10,
      },
    },
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  const locations = data?.taskLocations?.data || [];

  const options = locations?.map((taskLocation) => ({
    value: taskLocation?.id,
    label: taskLocation?.attributes?.name,
  }));

  const handleSearch = (value: string) => {
    setFilters({
      name: {
        containsi: value,
      },
    });
  };

  return (
    <SearchSelect
      placeholder={'Please select the Task Location'}
      onChange={onChange}
      options={options}
      loading={loading}
      onSearch={handleSearch}
      defaultValue={defaultValue}
      {...props}
    />
  );
};
