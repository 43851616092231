import { ROUTES } from '@/router/routes';

import { estimatesRoutes } from '@pages/contracts/estimates/routes';
import { invoicesManagementRoutes } from '@pages/contracts/invoices/routes';
import { purchaseRequestRoutes } from '@pages/contracts/purchase-requests/routes';
import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const AppraisalView = lazy<FC>(() => import('@pages/contracts/appraisal'));
const AppraisalPreview = lazy<FC>(
  () => import('@pages/contracts/appraisal/AppraisalPreview'),
);

const CreateAppraisal = lazy<FC>(
  () => import('@pages/contracts/appraisal/CreateAppraisal'),
);
const EditAppraisal = lazy<FC>(
  () => import('@pages/contracts/appraisal/EditAppraisal'),
);

const EditFormTemplate = lazy<FC>(
  () => import('@pages/contracts/form/EditFormTemplate'),
);
const ContractPreview = lazy<FC>(
  () => import('@pages/contracts/contract/ContractPreview'),
);
const ContractView = lazy<FC>(() => import('@pages/contracts/contract'));
const Forms = lazy<FC>(() => import('@pages/contracts/form'));

const ContractsMainPage = lazy<FC>(() => import('@/pages/contracts'));
const InvoicesContract = lazy<FC>(() => import('@pages/contracts/invoices'));
const CreateContract = lazy<FC>(
  () => import('@pages/contracts/contract/CreateContract'),
);
const CreateForm = lazy<FC>(() => import('@pages/contracts/form/CreateForm'));

const EditContractTemplate = lazy<FC>(
  () => import('@pages/contracts/contract/EditContractTemplate'),
);

const {
  index,
  forms,
  contracts,
  createForm,
  createContract,
  editContractTemplate,
  editFormTemplate,
  appraisal,
  createAppraisal,
  appraisalPreview,
} = ROUTES.tenant.contracts;

export const contractsRoutes: RouteObject = {
  Component: ContractsMainPage,
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={forms} />,
    },
    {
      path: contracts,
      Component: ContractView,
    },
    {
      path: appraisal,
      Component: AppraisalView,
    },
    {
      path: forms,
      Component: Forms,
    },
    {
      path: createContract,
      Component: CreateContract,
    },
    {
      path: createAppraisal,
      Component: CreateAppraisal,
    },
    {
      path: createForm,
      Component: CreateForm,
    },
    {
      path: `${appraisal}/:uuid`,
      Component: EditAppraisal,
    },
    {
      path: `${editContractTemplate}/:uuid`,
      Component: EditContractTemplate,
    },
    {
      path: `${contracts}/:uuid`,
      Component: ContractPreview,
    },
    {
      path: `${appraisalPreview}/:uuid`,
      Component: AppraisalPreview,
    },
    {
      path: `${editFormTemplate}/:uuid`,
      Component: EditFormTemplate,
    },
    purchaseRequestRoutes,
    estimatesRoutes,
    invoicesManagementRoutes,
  ],
};
