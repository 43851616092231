import {
  useCreateFileItemMutation,
  useSendEstimateMutation,
  useSendPurchaseRequestMutation,
  useUpdateEstimateMutation,
  useUpdatePurchaseRequestMutation,
} from '@/graphql';
import { ApolloError } from '@apollo/client';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { useUploadFile } from '@components/uploadFile/hooks/useUploadFile';
import { handleApplicationError } from '@helpers/errors';
import { useDeleteFileAndFileItem } from '@hooks/files/useDeleteFileAndFileItem';
import { useDownloadRecord } from '@hooks/files/useDownloadRecord';
import dayjs from 'dayjs';
import { MutableRefObject } from 'react';
import generatePDF from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';

const getFileName = (prefix?: string, isEstimate?: boolean) => {
  return `${isEstimate ? 'Estimate_' : 'Purchase_Request_'}${
    prefix ? `${prefix}_` : ''
  }${dayjs(new Date()).format('DD_MM_YY')}.pdf`;
};

export function usePurchaseRequestActions(
  ref: MutableRefObject<null | HTMLElement>,
  id: string,
  isEstimate?: boolean,
) {
  const message = useStatusMessage();
  const { meData } = useUserSettingsContext();
  const { handleDeleteFileAndFileItem } = useDeleteFileAndFileItem();
  const { createRecord, removeRecord } = useDownloadRecord();

  const [updatePurchaseRequest] = useUpdatePurchaseRequestMutation();
  const [updateEstimate] = useUpdateEstimateMutation();

  const [createFile] = useCreateFileItemMutation({
    refetchQueries: ['fileItems'],
  });

  const userId = meData?.me?.id;

  const { handleUpload } = useUploadFile();

  const [sendEstimate] = useSendEstimateMutation({
    onCompleted: (): void => {
      message.open('success', 'Purchase request completed with some errors');
    },
    onError: (error: ApolloError) => {
      handleApplicationError(error, message);
    },
  });

  const [sendRequest] = useSendPurchaseRequestMutation({
    onCompleted: (data: SendPurchaseRequestMutation): void => {
      if (data?.sendPurchaseRequest?.errors?.length) {
        data.sendPurchaseRequest.errors.forEach((error) => {
          error && message.open('error', error);
        });
        message.open('success', 'Purchase request completed with some errors');
      } else if (
        data?.sendPurchaseRequest?.success &&
        data?.sendPurchaseRequest?.errors?.length === 0
      ) {
        message.open('success', 'Purchase request sent successfully');
      }
    },
    onError: (error: ApolloError) => {
      handleApplicationError(error, message);
    },
  });

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleDownload = async (
    fileId: string,
    fileItemId: string,
    vendor: string,
    vendorName?: string,
  ) => {
    await generatePDF(ref, {
      filename: getFileName(vendorName, isEstimate),
      method: 'save',
    });

    if (fileId || fileItemId) {
      await handleDeleteFileAndFileItem(fileId, fileItemId);
      await removeRecord(fileId);
    }

    const fileName = getFileName(vendorName, isEstimate);
    const jsPdfFile = await generatePDF(ref, {
      method: 'build',
    });

    const fileBlob = jsPdfFile.output('blob');

    const file = new File([fileBlob], fileName, {
      type: 'application/pdf',
    });

    await createFile({
      variables: {
        input: {
          name: file.name,
          type: 'generated',
          uploadedBy: userId,
          isFavourite: false,
          company: isEstimate ? undefined : vendor,
        },
      },
      onCompleted: async (resultItem) => {
        if (resultItem?.createFileItem?.data?.id) {
          await handleUpload(file, {
            ref: 'api::file-item.file-item',
            refId: resultItem?.createFileItem?.data?.id,
            field: 'attachedFile',
          });
          if (isEstimate) {
            await updateEstimate({
              variables: {
                id: id,
                input: {
                  fileItem: resultItem?.createFileItem?.data?.id,
                },
              },
            });
          } else {
            await updatePurchaseRequest({
              variables: {
                id: id,
                input: {
                  fileItem: resultItem?.createFileItem?.data?.id,
                },
              },
            });
          }

          await createRecord(
            userId as string,
            resultItem?.createFileItem?.data?.id,
          );
        }
      },
    });
  };

  const handleUploadPurchaseRequestFile = async (
    fileId: string,
    fileItemId: string,
    customer: string,
    email?: string,
    contactId?: string,
    phone?: string,
    sendBySms?: boolean,
    sendByEmail?: boolean,
    customSmsContactContent?: string,
    customSmsSubjectContent?: string,
  ) => {
    if (fileId || fileItemId) {
      await handleDeleteFileAndFileItem(fileId, fileItemId);
    }

    const fileName = getFileName('', isEstimate);
    const jsPdfFile = await generatePDF(ref, {
      method: 'build',
    });

    const fileBlob = jsPdfFile.output('blob');

    const file = new File([fileBlob], fileName, {
      type: 'application/pdf',
    });

    await createFile({
      variables: {
        input: {
          name: file.name,
          type: 'generated',
          uploadedBy: userId,
          isFavourite: false,
          company: isEstimate ? undefined : customer ? customer : undefined,
        },
      },
      onCompleted: async (resultItem) => {
        if (resultItem?.createFileItem?.data?.id) {
          await handleUpload(file, {
            ref: 'api::file-item.file-item',
            refId: resultItem?.createFileItem?.data?.id,
            field: 'attachedFile',
          });

          if (isEstimate) {
            await updateEstimate({
              variables: {
                id: id,
                input: {
                  fileItem: resultItem?.createFileItem?.data?.id,
                },
              },
            });
          } else {
            await updatePurchaseRequest({
              variables: {
                id: id,
                input: {
                  fileItem: resultItem?.createFileItem?.data?.id,
                },
              },
            });
          }
          if (isEstimate) {
            await sendEstimate({
              variables: {
                id: id,
                email: email,
                contactId: contactId,
                phone: phone,
                sendByEmail: sendByEmail,
                sendBySms: sendBySms,
                customSmsContactContent: customSmsContactContent,
                customSmsSubjectContent: customSmsSubjectContent,
              },
            });
          } else {
            await sendRequest({
              variables: {
                id: id,
                email: email,
                contactId: contactId,
                phone: phone,
                sendByEmail: sendByEmail,
                sendBySms: sendBySms,
                customSmsContactContent: customSmsContactContent,
                customSmsSubjectContent: customSmsSubjectContent,
              },
            });
          }
        }
      },
    });
  };

  return {
    handlePrint,
    handleDownload,
    handleUploadPurchaseRequestFile,
  };
}
