export enum RecurringPeriod {
  ONCE = 'once',
  DAY = 'daily',
  WEEK = 'weekly',
  MONTH = 'monthly',
  YEAR = 'yearly',
}
export enum CardPaymentMethod {
  swipe = 'Card swipe',
  manual = 'Enter card details',
}
export enum PaymentStatus {
  success = 'success',
  failed = 'failed',
}
export enum TransactionStatus {
  open = 'Open',
  paid = 'Paid',
  cancelled = 'Cancelled',
  running = 'Running',
}
export enum TransactionType {
  downpayment = 'downPayment',
  full = 'full',
  installments = 'installments',
}

export enum PaymentType {
  card = 'card',
  ach = 'ach',
  cash = 'cash',
  custom = 'custom',
}

export enum PaymentActionType {
  INITIAL = 'initial',
  CASH = 'cash',
  CARD = 'card',
  ACH = 'ach',
  SWIPE = 'Card swipe',
  MANUAL = 'Enter card details',
  CUSTOM = 'custom',
}
