import { useStatusMessage } from '@/components/app/StatusMessageContext/statusMessageContext';
import ModuleTitle from '@/components/ui/module-title';
import { CustomSpace } from '@/components/ui/space';
import { useRefundTransactionMutation } from '@/graphql';
import { MAXIMUM_STRIPE_PAYMENT_AMOUNT } from '@/helpers/constants';
import { useTransactionModal } from '@components/accounting/transactions/DealTransactionDropdown/TransactionRefundModalContext';
import { useAvailableRefundAmount } from '@components/accounting/transactions/hooks/useAvailableRefundAmount';
import { CustomFormItem } from '@form/item/FormItem';
import { formatToCurrency } from '@helpers/formatter';
import { CustomButton } from '@ui/button/Button';
import { Col, Flex, Form, InputNumber, Modal, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useCallback } from 'react';

interface Props {
  entityId: string;
  sellingOrder: string;
  accountId: string | null | undefined;
  handlePointsRefund: (
    pointsValue: number,
    isPointsRefund: boolean,
  ) => Promise<void>;
  activityLoading?: boolean;
}
export const PartialRefundModal: FC<Props> = ({
  entityId,
  accountId,
  handlePointsRefund,
  activityLoading,
  sellingOrder,
}) => {
  const [form] = useForm();
  const message = useStatusMessage();
  const { isOpen, isPointsRefundModalOpen, closePointsRefundModal } =
    useTransactionModal();
  const { destroyAll } = Modal;

  const handleModalClose = () => {
    form.resetFields();
    closePointsRefundModal();
    destroyAll();
  };
  const [refundTransaction, { loading: refundLoading }] =
    useRefundTransactionMutation();

  const { availableRefundedAmount, availableAmountToRefundLoading } =
    useAvailableRefundAmount(sellingOrder, entityId);

  const refundModalDescription = `Amount left to refund: ${
    isPointsRefundModalOpen
      ? `${availableRefundedAmount} points`
      : formatToCurrency(availableRefundedAmount)
  }`;

  const refundModalLoading =
    refundLoading || activityLoading || availableAmountToRefundLoading;

  const onFinish = useCallback(
    async (values: { amount: number }) => {
      const { amount } = values;
      try {
        if (isPointsRefundModalOpen && amount > availableRefundedAmount) {
          closePointsRefundModal();
          destroyAll();
          return message.open('warning', 'Incorrect refund amount');
        }
        await handlePointsRefund(amount, isPointsRefundModalOpen);

        await refundTransaction({
          variables: {
            input: {
              txnId: entityId,
              accountId: accountId as string,
              amount: amount,
              paymentType: isPointsRefundModalOpen ? 'points' : 'money',
            },
          },
          refetchQueries: ['dealTransactions', 'cardTotals'],
        });
        closePointsRefundModal();
        destroyAll();
        message.open('success', 'Refund initiated successfully');
      } catch (error) {
        message.open('error', (error as Error).message);
      }
    },
    [
      isPointsRefundModalOpen,
      availableRefundedAmount,
      handlePointsRefund,
      refundTransaction,
      entityId,
      accountId,
      closePointsRefundModal,
      destroyAll,
      message,
    ],
  );

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={handleModalClose}
        footer={null}
        closeIcon
        forceRender
      >
        <ModuleTitle titleLevel={2} title={'Partial Refund'} />
        <Typography.Title level={4} style={{ marginBottom: 6 }}>
          {refundModalDescription}
        </Typography.Title>

        <Col span={24}>
          <Form
            form={form}
            layout={'vertical'}
            autoComplete={'off'}
            onFinish={onFinish}
          >
            <Row gutter={12}>
              <Col span={24} md={24}>
                <CustomFormItem
                  required
                  label={'Amount to refund'}
                  name={'amount'}
                >
                  <InputNumber
                    placeholder={'Enter Amount'}
                    maxLength={9}
                    min={0.5}
                    max={MAXIMUM_STRIPE_PAYMENT_AMOUNT}
                    style={{ width: '100%' }}
                  />
                </CustomFormItem>
              </Col>
            </Row>

            <Flex vertical gap={10}>
              <CustomSpace block style={{ justifyContent: 'flex-end' }}>
                <>
                  <CustomButton onClick={handleModalClose} size={'large'}>
                    Cancel
                  </CustomButton>
                  <CustomButton
                    htmlType={'submit'}
                    size={'large'}
                    type={'primary'}
                    loading={refundModalLoading}
                  >
                    Submit
                  </CustomButton>
                </>
              </CustomSpace>
            </Flex>
          </Form>
        </Col>
      </Modal>
    </>
  );
};
