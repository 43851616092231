import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { inventoryAdjustmentManagementRoutes } from '@pages/inventory/inventory-adjustment/routes';
import { inventoryAuditRoutes } from '@pages/inventory/inventory-audit/routes';
import { inventoryManagementRoutes } from '@pages/inventory/inventory-management/routes';
import { maintenanceManagementRoutes } from '@pages/inventory/maintenance/routes';
import { returnManagementRoutes } from '@pages/inventory/return/routes';
import { shipmentManagementRoutes } from '@pages/inventory/shipment/routes';
import { transferOrdersManagementRoutes } from '@pages/inventory/transfer-order/routes';
import { warehousesManagementRoutes } from '@pages/inventory/warehouses/routes';

import { ROUTES } from '@router/routes';

const InventoryPage = lazy<FC>(() => import('@/pages/inventory'));

const { index, inventoryManagement } = ROUTES.tenant.inventory;

export const inventoryRoutes: RouteObject = {
  path: index,
  Component: InventoryPage,
  children: [
    {
      index: true,
      element: <Navigate to={inventoryManagement.index} />,
    },
    inventoryAuditRoutes,
    inventoryManagementRoutes,
    shipmentManagementRoutes,
    warehousesManagementRoutes,
    transferOrdersManagementRoutes,
    returnManagementRoutes,
    inventoryAdjustmentManagementRoutes,
    maintenanceManagementRoutes,
  ],
};
