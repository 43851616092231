import { useUpdateProductOrderItemMutation } from '@/graphql';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

type MemoOutReportPartialReturnVariables = {
  id: string;
  input: {
    isPartiallyReturned: boolean;
  };
};

type MemoOutReportPartialReturnHandlers = {
  handleMemoOutReportPartialReturnChange: (value: boolean) => Promise<void>;
  loading: boolean;
};

export function useUpdateMemoOutReportPartialReturn(
  itemId: Maybe<string>,
): MemoOutReportPartialReturnHandlers {
  const message = useStatusMessage();

  const [updateProductInventoryItemMemoOutReport, { loading }] =
    useUpdateProductOrderItemMutation({
      onCompleted: () => {
        message.open('success', 'The return status has been updated');
      },
      refetchQueries: ['productOrderItemsWithCustomer'],
    });

  const updateMemoOutReportPartialReturn = async (
    variables: MemoOutReportPartialReturnVariables,
  ) => {
    return updateProductInventoryItemMemoOutReport({
      variables,
    });
  };

  const handleMemoOutReportPartialReturnChange = async (value: boolean) => {
    try {
      itemId &&
        (await updateMemoOutReportPartialReturn({
          id: itemId,
          input: {
            isPartiallyReturned: value,
          },
        }));
    } catch (error) {
      message.open('error', 'Failed to update the return status');
    }
  };

  return { handleMemoOutReportPartialReturnChange, loading };
}
