import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@/router/routes';
import PaymentGatewayIntegration from './paymentGateway';

const Settings = lazy<FC>(() => import('@/pages/settings'));
const Password = lazy<FC>(() => import('@/pages/settings/password'));
const General = lazy<FC>(() => import('@/pages/settings/general'));
const Plan = lazy<FC>(() => import('@/pages/settings/plan'));
const Permissions = lazy<FC>(() => import('@/pages/settings/permissions'));
const Integrations = lazy<FC>(() => import('@/pages/settings/integrations'));
const TwilioIntegration = lazy<FC>(() => import('@/pages/settings/twilio'));
const NylasIntegration = lazy<FC>(() => import('@pages/settings/nylas'));
const WebChat = lazy<FC>(
  () => import('@components/settings/integrations/WebChat'),
);
const EcommerceIntegration = lazy<FC>(
  () => import('@pages/settings/ecommerce'),
);

const { index, password, plan, permissions, integrations } =
  ROUTES.tenant.settings;

export const settingsRoutes: RouteObject = {
  Component: Settings,
  path: index,
  children: [
    {
      Component: General,
      index: true,
    },
    {
      Component: Password,
      path: password,
    },
    {
      Component: Plan,
      path: plan,
    },
    {
      Component: Permissions,
      path: permissions,
    },
    {
      Component: Integrations,
      path: integrations.index,
      children: [
        {
          index: true,
          element: <Navigate to={integrations.nylas} />,
        },
        {
          Component: TwilioIntegration,
          path: integrations.twilio,
        },
        {
          Component: NylasIntegration,
          path: integrations.nylas,
        },
        {
          Component: WebChat,
          path: integrations.webchat,
        },
        {
          Component: EcommerceIntegration,
          path: integrations.ecommerce,
        },
        {
          Component: WebChat,
          path: integrations.webchat,
        },
        {
          Component: PaymentGatewayIntegration,
          path: integrations.paymentGateway,
        },
      ],
    },
  ],
};
