import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { OrderIdSelect } from '@components/accounting/transactions/ui/selects/OrderIdSelect';
import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import EmployeeSelect from '@ui/form/EmployeeSelect';
import { OrderTypeSelect } from '@ui/form/OrderTypeSelect';

export const DailySummaryAdditionalFields: FC<
  AdditionalFieldProps & { entityName?: string }
> = ({ values, entityName }) => {
  return (
    <>
      <ExpandableFormItem
        label={'Due Date'}
        name={'dueDate'}
        defaultOpen={!!values?.dueDate}
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'orderId'}
        defaultOpen={!!values?.orderId}
        label={`Order Id`}
      >
        <OrderIdSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'locationIds'}
        label={'Business Location'}
        defaultOpen={!!values?.locationIds}
      >
        <BusinessLocationsSelect placeholder={'Choose location'} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'sales'}
        label={'Employee'}
        defaultOpen={!!values?.sales}
      >
        <EmployeeSelect shouldApplyDefaultValue={false} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'type'}
        label={'Order Type'}
        defaultOpen={!!values?.type}
      >
        <OrderTypeSelect />
      </ExpandableFormItem>
    </>
  );
};
