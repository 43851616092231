import { Icon } from '@assets/icon';
import { CompanyTaskSelect } from '@ui/form/CompaniesMultipleSelect/CompanyTaskSelect';
import { useSelectCompaniesQuery } from '@ui/form/CompaniesMultipleSelect/useSelectCompaniesQuery';
import { SelectProps } from 'antd/lib';
import { FC } from 'react';

interface Props extends SelectProps {
  onChange?: (value: string[]) => void;
  initValue?: string;
}

const CompaniesSelect: FC<Props> = ({
  value,
  onChange,
  initValue,
  ...props
}) => {
  const { data, loading, changeFilters } = useSelectCompaniesQuery({
    pagination: {
      limit: 10,
    },
  });

  return (
    <CompanyTaskSelect
      data={data}
      changeFilters={changeFilters}
      onChange={onChange}
      loading={loading}
      defaultValue={initValue ? initValue : undefined}
      mode={'multiple'}
      value={value}
      placeholder={'Please select the company'}
      menuItemSelectedIcon={<Icon type={'circle-remove'} />}
      {...props}
    />
  );
};

export default CompaniesSelect;
