import { FC } from 'react';

import { Tag, TagProps } from 'antd/lib';

interface Props extends TagProps {
  title?: string;
  width?: number;
}

const CustomTag: FC<Props> = ({ title, color, width = 'auto', ...props }) => {
  return (
    title && (
      <Tag
        bordered={false}
        color={color}
        style={{
          padding: '4px 8px',
          margin: 0,
          marginInlineEnd: 0,
          width,
          height: '32px',
        }}
        {...props}
      >
        {title}
      </Tag>
    )
  );
};

export default CustomTag;
