export const DAYS_IN_MONTHS = 30;
export const DAYS_IN_YEAR = 365;

export const MINIMUM_AFTERPAY_CLEARPAY_AMOUNT = 1;
export const MAXIMUM_AFTERPAY_CLEARPAY_AMOUNT = 4000;

export const MINIMUM_AFFIRM_AMOUNT = 50;
export const MAXIMUM_AFFIRM_AMOUNT = 30000;

export const MAXIMUM_STRIPE_PAYMENT_AMOUNT = 999999.99;
