import {
  useCreateReturnMethodMutation,
  useReturnMethodsLazyQuery,
} from '@/graphql';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { useDataSelect } from '@inventory/inventoryManagement/products/inputs/useDataSelect';
import { AddNewSelectButton } from '@ui/form/AddNewSelectButton';
import { SelectProps } from 'antd/lib';
import { FC, useCallback, useEffect, useMemo } from 'react';

interface Props extends SelectProps {
  onChange?: (value: string | null) => void;
  initialValue?: Maybe<string>;
}

export const ReturnMethodSelect: FC<Props> = ({
  onChange,
  initialValue,
  ...props
}) => {
  const { handleChange, handleSearch, queryParams, selectedValue } =
    useDataSelect({
      onChange,
      initialSelectedValue: initialValue,
    });

  const [fetch, { data, loading }] = useReturnMethodsLazyQuery({
    variables: queryParams,
  });

  useEffect(() => {
    fetch();
  }, [queryParams, fetch]);

  const [createReturnMethod, { loading: mutationLoading }] =
    useCreateReturnMethodMutation({
      refetchQueries: ['returnMethods'],
    });

  const options = useMemo(() => {
    const methods = data?.returnMethods?.data || [];

    return methods?.map((method) => ({
      value: method?.id,
      label: method?.attributes?.name,
    }));
  }, [data?.returnMethods?.data]);

  const createNewMethod = useCallback(
    async (name: string) => {
      await createReturnMethod({
        variables: {
          input: {
            name,
          },
        },
        onCompleted: (data) => {
          if (data?.createReturnMethod?.data?.id) {
            handleChange(data.createReturnMethod.data.id);
          }
        },
      });
    },
    [createReturnMethod, handleChange],
  );

  return (
    <SearchSelect
      {...props}
      defaultValue={initialValue}
      placeholder={'Select the method or create new one'}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      loading={loading || mutationLoading}
      onSearch={handleSearch}
      dropdownRender={(menu) => (
        <>
          {menu}
          <AddNewSelectButton handleAddNew={createNewMethod} />
        </>
      )}
    />
  );
};
