import { FC, useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib';

import { useApprovalMethodsLazyQuery } from '@/graphql';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

interface Props extends SelectProps {
  initFilter?: CompanyFiltersInput;
}

export const ApprovalMethodSelect: FC<Props> = ({
  defaultValue,
  initFilter,
  ...props
}) => {
  const [filters, setFilters] = useState<ApprovalMethodFiltersInput>({});

  const [fetch, { data, loading }] = useApprovalMethodsLazyQuery({
    variables: {
      filters: {
        ...initFilter,
        ...filters,
      },
      pagination: {
        limit: 10,
      },
    },
  });

  useEffect(() => {
    fetch();
  }, [filters, fetch]);

  const methods = data?.approvalMethods?.data || [];

  const options = methods?.map((approvalMethod) => ({
    value: approvalMethod?.id,
    label: approvalMethod?.attributes?.name,
  }));

  const handleSearch = (value: string) => {
    setFilters({
      name: {
        containsi: value,
      },
    });
  };

  return (
    <SearchSelect
      placeholder={'Please select the Approval Method'}
      options={options}
      loading={loading}
      onSearch={handleSearch}
      defaultValue={defaultValue}
      {...props}
    />
  );
};
