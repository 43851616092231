import { Input } from 'antd';

import { ChangeEvent, FC } from 'react';

interface INoteInput {
  customName: (string | number)[];
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  maxLength?: number;
  initialValue?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
}

const { TextArea } = Input;

export const NoteInput: FC<INoteInput> = ({
  customName,
  placeholder = 'Text',
  maxLength = 300,
  maxRows = 10,
  minRows = 3,
  initialValue,
  onFocus,
  onBlur,
  onChange,
  value,
}) => {
  return (
    <TextArea
      name={String(customName)}
      defaultValue={initialValue}
      value={value}
      placeholder={placeholder}
      maxLength={maxLength}
      autoSize={{ minRows, maxRows }}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      style={{ width: '100%', paddingLeft: 5 }}
    />
  );
};
