import { FilterFunction } from '@filters/types';
import { isNil } from 'lodash';

type RangeRequestType = {
  key: string;
  minValue?: number | string;
  maxValue?: number | string;
};
type CustomFilterValues = {
  name?: string;
  barcode?: string;
  vendor?: string;
  price?: string;
};

export const handlerRangeRequest = ({
  key,
  minValue,
  maxValue,
}: RangeRequestType) => {
  const rangeQuery: { [key: string]: { gte?: number; lte?: number } } = {};

  if (minValue !== undefined && maxValue !== undefined) {
    rangeQuery[key] = {
      gte: Number(minValue),
      lte: Number(maxValue),
    };
  } else if (isNil(minValue)) {
    rangeQuery[key] = {
      gte: Number(minValue),
    };
  } else if (isNil(maxValue)) {
    rangeQuery[key] = {
      lte: Number(maxValue),
    };
  } else {
    rangeQuery[key] = {
      gte: 0,
      lte: 0,
    };
  }

  return rangeQuery;
};

export const createInventoryAuditDetailsFilters: FilterFunction<
  Maybe<CustomFilterValues>
> = (values: Maybe<CustomFilterValues>): InventoryAuditItemFiltersInput => {
  const filters: InventoryAuditItemFiltersInput = {};

  if (values?.name) {
    filters.productInventoryItem = {
      ...filters.productInventoryItem,
      product: {
        ...filters.productInventoryItem?.product,
        name: {
          containsi: values.name,
        },
      },
    };
  }

  if (values?.barcode) {
    filters.productInventoryItem = {
      ...filters.productInventoryItem,
      product: {
        ...filters.productInventoryItem?.product,
        barcode: {
          containsi: values.barcode,
        },
      },
    };
  }

  if (values?.vendor) {
    filters.productInventoryItem = {
      ...filters.productInventoryItem,
      vendor: {
        id: {
          eq: values.vendor,
        },
      },
    };
  }

  if (values?.price) {
    const [minPrice, maxPrice] = values.price.split('-').map(Number);
    filters.productInventoryItem = {
      ...filters.productInventoryItem,
      ...handlerRangeRequest({
        key: 'price',
        minValue: minPrice,
        maxValue: maxPrice,
      }),
    };
  }

  return filters;
};

export const createInventoryAuditDetailsInventoryQtyFilters: FilterFunction = (
  value,
): InventoryAuditItemFiltersInput => {
  const minValue = String(value).split('-')[0];
  const maxValue = String(value).split('-')[1];

  return {
    ...handlerRangeRequest({ key: 'inventoryQty', minValue, maxValue }),
  };
};

export const createInventoryAuditDetailsScannedQtyFilters: FilterFunction = (
  value,
): InventoryAuditItemFiltersInput => {
  const [minValue, maxValue] = String(value).split('-');

  return minValue || maxValue
    ? {
        ...handlerRangeRequest({ key: 'scannedQty', minValue, maxValue }),
      }
    : {
        ...handlerRangeRequest({ key: 'scannedQty', minValue: 0, maxValue: 0 }),
      };
};

export const createInventoryAuditDetailsAdjustedFilters: FilterFunction<
  string
> = (value): InventoryAuditItemFiltersInput =>
  value
    ? {
        adjusted: { eq: value?.toLowerCase() !== 'none' ? true : false },
      }
    : {};
