import { Icon, IconSize } from '@assets/icon';
import { CustomButton } from '@ui/button/Button';
import { Modal } from 'antd';

export const iconColor = '#0146C5';
export const defaultConfirmData = {
  maskClosable: false,
  centered: true,
  cancelText: 'Repair Ticket',
  okText: 'Customer Invoice',
  closable: true,
  closeIcon: (
    <CustomButton
      icon={<Icon type={'close'} size={IconSize.Small} />}
      type={'link'}
      size={'small'}
      onClick={() => Modal.destroyAll()}
    />
  ),
};
