import { FilterFunction } from '@filters/types';

export const createDailySummaryOrderIdFilter: FilterFunction<string> = (
  orderId?: string,
): OrderFiltersInput => {
  return orderId
    ? {
        id: {
          eq: orderId,
        },
      }
    : {};
};

export const createDailySummaryOrderTypeFilter: FilterFunction<string> = (
  orderType?: string,
): OrderFiltersInput => {
  return orderType
    ? {
        type: {
          eq: orderType,
        },
      }
    : {};
};

export const createDailySummaryBusinessLocationFilter: FilterFunction<
  string[]
> = (locationIds?: string[]): OrderFiltersInput => {
  return locationIds?.length !== 0
    ? ({
        businessLocation: {
          id: {
            in: locationIds,
          },
        },
      } as OrderFiltersInput)
    : {};
};

export const createDailySummarySalesFilter: FilterFunction<string> = (
  employee?: string,
): OrderFiltersInput => {
  return employee
    ? {
        sales: {
          id: {
            eq: employee,
          },
        },
      }
    : {};
};
