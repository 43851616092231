import { Col, Row, Typography } from 'antd';
import { FC } from 'react';

type Props = {
  orderImage: string;
  orderNote: Maybe<string>;
  isOrderNoteVisible?: boolean;
};

export const RepairTicketImage: FC<Props> = ({
  orderImage,
  orderNote,
  isOrderNoteVisible = true,
}) => {
  return (
    <Row justify={'center'} gutter={[0, 2]}>
      {orderImage && (
        <Col span={18}>
          <img
            alt={'order-image'}
            style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }}
            src={orderImage}
          />
        </Col>
      )}
      {orderNote && isOrderNoteVisible && (
        <Col span={24}>
          <Typography.Text
            style={{
              color: '#959595',
              fontSize: 12,
              whiteSpace: 'break-spaces',
            }}
          >
            {orderNote}
          </Typography.Text>
        </Col>
      )}
    </Row>
  );
};
