import { FC } from 'react';

import { formatToCurrency } from '@helpers/formatter';
import { Col, Flex, Row, Typography } from 'antd';

interface ClientMessageProps {
  clientMessage: string;
  paidAmount?: number;
  orderTotal?: number;
  currency?: string;
}

const typographyStyles = { fontSize: 12, color: '#626670' };
const typographyCountStyles = { fontSize: 12, fontWeight: 600, marginLeft: 8 };

export const ClientMessageSection: FC<ClientMessageProps> = ({
  clientMessage,
  paidAmount,
  orderTotal,
  currency,
}) => {
  return (
    <Flex
      vertical
      gap={12}
      style={{
        width: '100%',
        margin: 0,
        padding: '20px 0',
        borderTop: '1px #E7E7E7 solid',
      }}
    >
      {clientMessage && (
        <Typography.Title level={5}>Client Message:</Typography.Title>
      )}
      <Row align={'bottom'}>
        <Col span={16}>
          <Typography.Text
            style={{ ...typographyStyles, whiteSpace: 'break-spaces' }}
          >
            {clientMessage}
          </Typography.Text>
        </Col>
        <Col span={8}>
          <Row justify={'end'}>
            <Typography.Text style={typographyStyles}>
              Paid Amount:
            </Typography.Text>
            <Typography.Text style={typographyCountStyles}>
              {formatToCurrency(paidAmount, currency)}
            </Typography.Text>
          </Row>
          <Row justify={'end'}>
            <Typography.Text style={{ ...typographyStyles, textAlign: 'end' }}>
              Remaining Amount:
            </Typography.Text>
            <Typography.Text style={typographyCountStyles}>
              {formatToCurrency(
                Number(orderTotal) - Number(paidAmount),
                currency,
              )}
            </Typography.Text>
          </Row>
        </Col>
      </Row>
    </Flex>
  );
};
