import { useChatNotificationsQuery } from '@/graphql';
import { useSocket } from '@/socket/useSocket';
import { useTenantInfo } from '@hooks/useTenantInfo';
import { useToken } from '@hooks/useToken';
import { Badge } from 'antd';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import { useParams } from 'react-router';

interface Props extends PropsWithChildren {
  userId: string;
  additionalFilters?: ChatNotificationFiltersInput;
  isDot?: boolean;
  isCount?: boolean;
  isActiveChat?: boolean;
}

export const ChatNotificationBadge: FC<Props> = ({
  userId,
  additionalFilters = {},
  isDot = false,
  isCount = false,
  isActiveChat,
  children,
}) => {
  const socket = useSocket();
  const { token } = useToken();

  const { uuid } = useParams();
  const { tenantId } = useTenantInfo();

  const { data, refetch } = useChatNotificationsQuery({
    variables: {
      filters: {
        isActive: {
          eq: true,
        },
        ...(uuid?.includes('webchat')
          ? {
              conversation: {
                user: {
                  id: {
                    eq: userId,
                  },
                },
              },
            }
          : {
              tenant: {
                id: {
                  eq: tenantId,
                },
              },
            }),
        ...additionalFilters,
      },
    },
  });

  const count =
    isCount && data?.chatNotifications?.meta.pagination.total
      ? data?.chatNotifications?.meta.pagination.total
      : undefined;
  const dot = useMemo(
    () => isDot && Number(data?.chatNotifications?.meta.pagination.total) > 0,
    [data, isDot],
  );

  useEffect(() => {
    const handleNotificationUpdate = () => {
      refetch();
    };

    if (!(isCount && isActiveChat)) {
      socket.on('chat-notification:create', handleNotificationUpdate);
    }
    socket.on('chat-notification:delete', handleNotificationUpdate);

    return () => {
      socket.off('chat-notification:create', handleNotificationUpdate);
      socket.off('chat-notification:delete', handleNotificationUpdate);
    };
  }, [socket, userId, uuid, isActiveChat, isCount, refetch, tenantId]);

  return (
    <Badge size={'small'} color={token.colorPrimary} count={count} dot={dot}>
      {children}
    </Badge>
  );
};
