import { DatePicker } from '@ui/form';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';

import { useUpdateMemoOutReportReturnedDate } from '@components/reports/memoOut/hooks/useUpdateMemoOutReportReturnedDate';

type Props = {
  reportItemId: Maybe<string>;
  returnedDate: Date | null | undefined;
};

export const MemoOutReportReturnedDateActions: FC<Props> = ({
  reportItemId,
  returnedDate,
}) => {
  const { handleMemoOutReportReturnedDateChange, loading } =
    useUpdateMemoOutReportReturnedDate(reportItemId);

  const onDateChange = async (date: Dayjs | null) => {
    if (date) {
      await handleMemoOutReportReturnedDateChange(date.toDate());
    }
  };

  return (
    <DatePicker
      onChange={onDateChange}
      defaultValue={returnedDate ? dayjs(returnedDate) : null}
      disabled={loading}
      allowClear={false}
    />
  );
};
