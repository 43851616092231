import { FC } from 'react';

import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Row,
  Select,
  UploadFile,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { DealTransactionFormValues } from 'src/components/accounting/transactions/types';

import { GetChangeHandler } from '@/components/form/hooks/useCustomForm';
import { ContactsANDCompaniesSelect } from 'src/components/ui/form/ContactsANDCompaniesSelect';

import DealTransactionTreeSelect from '@components/accounting/transactions/ui/selects/DealTransactionTreeSelect';

import { CurrencyInput } from '@form/inputs/currencyInput/CurrencyInput';
import { CustomFormItem } from '@form/item/FormItem';

import { CustomButton } from '@ui/button/Button';
import AttachmentsModal from '@ui/modal/filesModal/AttachmentsModal';
import ImageItem from '@ui/modal/filesModal/ImageItem/ImageItem';
import PreviewModal from '@ui/modal/filesModal/PreviewModal';
import useFilesModalHook from '@ui/modal/filesModal/useFlesModalHook';
import usePreviewModal from '@ui/modal/filesModal/usePreviewModal';
import { PaymentMethodTypeInput } from './inputs/PaymentMethodTypeInput';
import { OrderIdSelect } from './ui/selects/OrderIdSelect';
import { PERIOD_OPTIONS, STATUS_OPTIONS } from './utils';

interface Props {
  form: FormInstance<DealTransactionFormValues>;
  handleChange: GetChangeHandler<DealTransactionFormValues>;
  type: 'create' | 'update';
  specialInitValues?: {
    files?: UploadFile[];
  };
}
const DealTransactionInputsList: FC<Props> = ({
  form,
  handleChange,
  type,
  specialInitValues,
}) => {
  const handleStatusChange = (value: string) => {
    if (value === 'Paid') {
      form.setFieldValue('paid', form.getFieldValue('summary'));
    }
    handleChange('status');
  };
  const {
    open,
    showUserModal,
    hideUserModal,
    filesForm,
    handleFilesChange,
    files,
  } = useFilesModalHook();
  const { isModalOpen, showModal, handleCancel, activeSrc } = usePreviewModal();
  const filesArray = files ? files : specialInitValues?.files;

  return (
    <>
      <Form.Item name={'files'} noStyle />
      {type === 'create' && (
        <CustomFormItem
          required
          label={'Chart Property'}
          name={'chart'}
          rules={[
            {
              message: `Please select Category if needed`,
            },
          ]}
        >
          <DealTransactionTreeSelect onChange={handleChange('chart')} />
        </CustomFormItem>
      )}

      <CustomFormItem required label={'Amount of Total'} name={'summary'}>
        <CurrencyInput />
      </CustomFormItem>

      {type === 'create' && (
        <>
          <CustomFormItem label={'Customer/Vendor'} name={'allContacts'}>
            <ContactsANDCompaniesSelect />
          </CustomFormItem>
          <CustomFormItem label={'Order Id'} name={'sellingOrder'}>
            <OrderIdSelect onChange={handleChange('sellingOrder')} />
          </CustomFormItem>
        </>
      )}
      <CustomFormItem required label={'Date Due'} name={'dueDate'}>
        <DatePicker style={{ width: '100%', padding: '11px 15px' }} />
      </CustomFormItem>
      <CustomFormItem required label={'Repetition'} name={'repetitive'}>
        <Select
          placeholder={'Repetition'}
          options={PERIOD_OPTIONS}
          defaultActiveFirstOption
        />
      </CustomFormItem>

      <CustomFormItem label={'Status'} name={'status'}>
        <Select
          placeholder={'Status'}
          options={STATUS_OPTIONS}
          onChange={handleStatusChange}
          defaultActiveFirstOption
        />
      </CustomFormItem>
      <CustomFormItem required label={'Payment Method'} name={'paymentMethod'}>
        <PaymentMethodTypeInput />
      </CustomFormItem>

      <CustomFormItem required name={'paid'} label={'Amount of Paid'}>
        <CurrencyInput />
      </CustomFormItem>
      <CustomFormItem label={'Notes'} name={'note'}>
        <TextArea />
      </CustomFormItem>
      <CustomButton style={{ width: '100%' }} onClick={showUserModal}>
        Add Attachments
      </CustomButton>
      <Row style={{ marginTop: 5 }} gutter={[5, 5]}>
        {filesArray &&
          !!filesArray.length &&
          filesArray.map((file: UploadFile) => {
            return (
              <>
                <Col key={file?.uid} span={8}>
                  <ImageItem file={file} showModal={showModal} />
                </Col>
              </>
            );
          })}
      </Row>
      <AttachmentsModal
        open={open}
        hideUserModal={hideUserModal}
        filesForm={filesForm}
        handleFilesChange={handleFilesChange}
        files={filesArray}
      />
      <PreviewModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        activeSrc={activeSrc}
      />
    </>
  );
};

export default DealTransactionInputsList;
