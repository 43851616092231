import { useUpdateProductOrderItemMutation } from '@/graphql';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

type MemoOutReportFullReturnVariables = {
  id: string;
  input: {
    isFullyReturned: boolean;
  };
};

type MemoOutReportFullReturnHandlers = {
  handleMemoOutReportFullReturnChange: (value: boolean) => Promise<void>;
  loading: boolean;
};

export function useUpdateMemoOutReportFullReturn(
  itemId: Maybe<string>,
): MemoOutReportFullReturnHandlers {
  const message = useStatusMessage();

  const [updateProductInventoryItemMemoOutReport, { loading }] =
    useUpdateProductOrderItemMutation({
      onCompleted: () => {
        message.open('success', 'The return status has been updated');
      },
      refetchQueries: ['productOrderItemsWithCustomer'],
    });

  const updateMemoOutReportFullReturn = async (
    variables: MemoOutReportFullReturnVariables,
  ) => {
    return updateProductInventoryItemMemoOutReport({
      variables,
    });
  };

  const handleMemoOutReportFullReturnChange = async (value: boolean) => {
    try {
      itemId &&
        (await updateMemoOutReportFullReturn({
          id: itemId,
          input: {
            isFullyReturned: value,
          },
        }));
    } catch (error) {
      message.open('error', 'Failed to update the return status');
    }
  };

  return { handleMemoOutReportFullReturnChange, loading };
}
