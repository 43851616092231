import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import ReturnPage from '@pages/inventory/return';
import { ROUTES } from '@router/routes';

const Selling = lazy<FC>(() => import('@/pages/selling'));
const SellingDashboard = lazy<FC>(() => import('@/pages/selling/dashboard'));
const OrderManagement = lazy<FC>(() => import('@/pages/selling/management'));
const POS = lazy<FC>(() => import('@/pages/selling/pos'));
const CreateReturnForm = lazy<FC>(
  () => import('@inventory/return/forms/CreateReturnForm'),
);
const UpdateReturnForm = lazy<FC>(
  () => import('@inventory/return/forms/UpdateReturnForm'),
);

const {
  index,
  dashboard,
  pos,
  management,
  layaway,
  rental,
  trade,
  purchase,
  returns,
} = ROUTES.tenant.selling;

export const sellingRoutes: RouteObject = {
  Component: Selling,
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={dashboard} />,
    },
    {
      Component: SellingDashboard,
      path: dashboard,
    },

    {
      path: pos.index,
      children: [
        {
          index: true,
          Component: POS,
        },
        {
          path: `${pos.purchase}/:orderId`,
          Component: POS,
        },
      ],
    },
    {
      Component: OrderManagement,
      path: management,
    },
    {
      Component: OrderManagement,
      path: layaway,
    },
    {
      Component: OrderManagement,
      path: rental,
    },
    {
      Component: OrderManagement,
      path: trade,
    },
    {
      Component: OrderManagement,
      path: purchase,
    },
    {
      path: returns.index,
      children: [
        {
          index: true,
          Component: ReturnPage,
        },
        {
          path: returns.create,
          Component: CreateReturnForm,
        },
        {
          path: ':uuid',
          Component: UpdateReturnForm,
        },
      ],
    },
  ],
};
