import { FilterFunction } from '@filters/types';

export const createSalesByItemCategoryBusinessLocationFilter: FilterFunction<
  string[]
> = (locationIds?: string[]): OrderFiltersInput => {
  return locationIds?.length !== 0
    ? ({
        businessLocation: {
          id: {
            in: locationIds,
          },
        },
      } as OrderFiltersInput)
    : {};
};

export const createSalesByItemCategorySalesFilter: FilterFunction<string> = (
  employee?: string,
): OrderFiltersInput => {
  return employee
    ? {
        sales: {
          id: {
            eq: employee,
          },
        },
      }
    : {};
};

export const createSalesByItemCategoryOrderTypeFilter: FilterFunction<
  string
> = (orderType?: string): OrderFiltersInput => {
  return orderType
    ? {
        type: {
          eq: orderType,
        },
      }
    : {};
};
