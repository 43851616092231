import { useProductAttributesLazyQuery } from '@/graphql';
import { CustomFormItem } from '@form/item/FormItem';
import { AnyObject } from '@helpers/types';
import { CustomAttributeOptionSelect } from '@inventory/inventoryManagement/products/CustomAttributesForm/CustomAttributeOptionSelect';
import { Col, Row, Skeleton, Typography } from 'antd';
import { get } from 'lodash';
import { CSSProperties, FC, useEffect } from 'react';

interface Props {
  initialValues?: Maybe<AnyObject>;
  editable?: boolean;
  title?: Maybe<string>;
  titleFontSize?: string | number | undefined;
  selectStyle?: CSSProperties | undefined;
}

export const CustomAttributesSelectList: FC<Props> = ({
  initialValues,
  editable = true,
  title,
  titleFontSize = 14,
  selectStyle,
}) => {
  const [fetch, { data, loading }] = useProductAttributesLazyQuery({
    variables: {
      filters: {
        productAttributeOptions: {
          id: { ne: null },
        },
      },
      pagination: {
        limit: -1,
      },
    },
  });

  const productAttributesData: ProductAttributeFragment[] = get(
    data,
    'productAttributes.data',
    [],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return <Skeleton.Input active={true} size={'default'} block={true} />;
  }

  if (productAttributesData?.length === 0) return null;

  return (
    <Row gutter={[12, 12]}>
      {title && (
        <Col span={24}>
          <Typography.Title level={4} style={{ fontSize: titleFontSize }}>
            {title}
          </Typography.Title>
        </Col>
      )}
      <Col span={24}>
        <Row gutter={[24, 24]}>
          {productAttributesData?.map((productAttribute) => {
            const initialValue = productAttribute?.attributes?.name
              ? (initialValues?.[productAttribute.attributes.name] as
                  | string
                  | undefined)
              : undefined;

            return (
              <Col span={24} key={productAttribute?.id}>
                <CustomFormItem
                  label={productAttribute?.attributes?.name}
                  name={productAttribute?.attributes?.name}
                  style={selectStyle}
                >
                  <CustomAttributeOptionSelect
                    attributeId={productAttribute?.id}
                    initialValue={initialValue}
                    editable={editable}
                  />
                </CustomFormItem>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};
