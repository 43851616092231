import { useDocumentPermissionsLazyQuery } from '@/graphql';
import { useRouteTenant } from '@hooks/useRouteTenant';
import { get } from 'lodash';
import { useEffect } from 'react';

type ReturnType = {
  invoiceTerms: string;
  appraisalTerms: string;
  taskContent: string;
  purchaseTerms: string;
  invoiceClientMessage: string;
  documentPermissionsId: string;
  isShowOrderItemsImages: boolean;
  isInvoiceTermsEnabled: boolean;
  isInvoiceClientMessageEnabled: boolean;
  isAppraisalTermsEnabled: boolean;
  isPurchaseTermsEnabled: boolean;
  isInvoiceCreationDateEnabled: boolean;
  isInvoiceDiscountEnabled: boolean;
  isInvoiceTaxEnabled: boolean;
  isInvoiceTipEnabled: boolean;
  isPurchaseCreationDateEnabled: boolean;
  isPurchaseDiscountEnabled: boolean;
  isPurchaseTaxEnabled: boolean;
  isPurchaseTipEnabled: boolean;
  invoiceSignature: boolean;
  isInternalRepairTicketPriceEnabled: boolean;
  isExternalRepairTicketPriceEnabled: boolean;
  isShowOrderNoteAtInternalRepairTicker: boolean;
  isShowOrderNoteAtExternalRepairTicker: boolean;
  isOnboardingMessage: boolean;
  estimateTerms: string;
  estimateClientMessage: string;
  isEstimateTermsEnabled: boolean;
  isEstimateClientMessageEnabled: boolean;
  isEstimateCreationDateEnabled: boolean;
  isEstimateDiscountEnabled: boolean;
  isEstimateTaxEnabled: boolean;
  isEstimateTipEnabled: boolean;
  estimateSignature: boolean;
  isPriceOnLabelEnabled: boolean;
};

export function useDocumentPermissionInfo(): ReturnType {
  const tenantSlug = useRouteTenant();

  const [fetch, { data }] = useDocumentPermissionsLazyQuery({
    variables: {
      filters: {
        tenant: {
          slug: {
            eq: tenantSlug,
          },
        },
      },
    },
  });

  const documentPermissionsData: DocumentPermissionFragment = get(
    data,
    'documentPermissions.data',
    [],
  )[0];

  const invoiceTerms = get(
    documentPermissionsData,
    'attributes.invoiceTerms',
    '',
  );
  const appraisalTerms = get(
    documentPermissionsData,
    'attributes.appraisalTerms',
    '',
  );

  const purchaseTerms = get(
    documentPermissionsData,
    'attributes.purchaseTerms',
    '',
  );
  const invoiceClientMessage = get(
    documentPermissionsData,
    'attributes.invoiceClientMessage',
    '',
  );

  const taskContent = get(
    documentPermissionsData,
    'attributes.taskNotificationContent',
    '',
  );

  const isShowOrderItemsImages = get(
    documentPermissionsData,
    'attributes.isShowOrderItemsImages',
    false,
  );

  const isInvoiceTermsEnabled = get(
    documentPermissionsData,
    'attributes.isInvoiceTermsEnabled',
    false,
  );
  const isInvoiceClientMessageEnabled = get(
    documentPermissionsData,
    'attributes.isInvoiceClientMessageEnabled',
    false,
  );
  const isAppraisalTermsEnabled = get(
    documentPermissionsData,
    'attributes.isAppraisalTermsEnabled',
    false,
  );
  const isPurchaseTermsEnabled = get(
    documentPermissionsData,
    'attributes.isPurchaseTermsEnabled',
    false,
  );

  const invoiceSignature = get(
    documentPermissionsData,
    'attributes.invoiceSignature',
    false,
  );

  const documentPermissionsId = get(
    documentPermissionsData,
    'attributes.id',
    '',
  );

  const isInvoiceCreationDateEnabled = get(
    documentPermissionsData,
    'attributes.isInvoiceCreationDateEnabled',
    true,
  );

  const isInvoiceDiscountEnabled = get(
    documentPermissionsData,
    'attributes.isInvoiceDiscountEnabled',
    true,
  );

  const isInvoiceTaxEnabled = get(
    documentPermissionsData,
    'attributes.isInvoiceTaxEnabled',
    true,
  );

  const isInvoiceTipEnabled = get(
    documentPermissionsData,
    'attributes.isInvoiceTipEnabled',
    true,
  );

  const isPurchaseCreationDateEnabled = get(
    documentPermissionsData,
    'attributes.isPurchaseCreationDateEnabled',
    true,
  );

  const isPurchaseDiscountEnabled = get(
    documentPermissionsData,
    'attributes.isPurchaseDiscountEnabled',
    true,
  );

  const isPurchaseTaxEnabled = get(
    documentPermissionsData,
    'attributes.isPurchaseTaxEnabled',
    true,
  );

  const isPurchaseTipEnabled = get(
    documentPermissionsData,
    'attributes.isPurchaseTipEnabled',
    true,
  );

  const isInternalRepairTicketPriceEnabled = get(
    documentPermissionsData,
    'attributes.isInternalRepairTicketPriceEnabled',
    true,
  );

  const isExternalRepairTicketPriceEnabled = get(
    documentPermissionsData,
    'attributes.isExternalRepairTicketPriceEnabled',
    true,
  );

  const isShowOrderNoteAtInternalRepairTicker = get(
    documentPermissionsData,
    'attributes.isShowOrderNoteAtInternalRepairTicker',
    true,
  );

  const isShowOrderNoteAtExternalRepairTicker = get(
    documentPermissionsData,
    'attributes.isShowOrderNoteAtExternalRepairTicker',
    true,
  );

  const isOnboardingMessage = get(
    documentPermissionsData,
    'attributes.isOnboardingMessage',
    true,
  );

  const estimateTerms = get(
    documentPermissionsData,
    'attributes.estimateTerms',
    '',
  );

  const estimateClientMessage = get(
    documentPermissionsData,
    'attributes.estimateClientMessage',
    '',
  );

  const isEstimateTermsEnabled = get(
    documentPermissionsData,
    'attributes.isEstimateTermsEnabled',
    false,
  );

  const isEstimateClientMessageEnabled = get(
    documentPermissionsData,
    'attributes.isEstimateClientMessageEnabled',
    false,
  );

  const isEstimateCreationDateEnabled = get(
    documentPermissionsData,
    'attributes.isEstimateCreationDateEnabled',
    true,
  );

  const isEstimateDiscountEnabled = get(
    documentPermissionsData,
    'attributes.isEstimateDiscountEnabled',
    true,
  );

  const isEstimateTaxEnabled = get(
    documentPermissionsData,
    'attributes.isEstimateTaxEnabled',
    true,
  );

  const isEstimateTipEnabled = get(
    documentPermissionsData,
    'attributes.isEstimateTipEnabled',
    true,
  );

  const estimateSignature = get(
    documentPermissionsData,
    'attributes.estimateSignature',
    false,
  );

  const isPriceOnLabelEnabled = get(
    documentPermissionsData,
    'attributes.isPriceOnLabelEnabled',
    false,
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    isOnboardingMessage,
    invoiceTerms,
    appraisalTerms,
    purchaseTerms,
    taskContent,
    invoiceClientMessage,
    isShowOrderItemsImages,
    isInvoiceTermsEnabled,
    isInvoiceClientMessageEnabled,
    isAppraisalTermsEnabled,
    isPurchaseTermsEnabled,
    documentPermissionsId,
    isInvoiceCreationDateEnabled,
    isInvoiceDiscountEnabled,
    isInvoiceTaxEnabled,
    isInvoiceTipEnabled,
    isPurchaseCreationDateEnabled,
    isPurchaseDiscountEnabled,
    isPurchaseTaxEnabled,
    isPurchaseTipEnabled,
    invoiceSignature,
    isInternalRepairTicketPriceEnabled,
    isExternalRepairTicketPriceEnabled,
    isShowOrderNoteAtInternalRepairTicker,
    isShowOrderNoteAtExternalRepairTicker,
    estimateTerms,
    estimateClientMessage,
    isEstimateTermsEnabled,
    isEstimateClientMessageEnabled,
    isEstimateCreationDateEnabled,
    isEstimateDiscountEnabled,
    isEstimateTaxEnabled,
    isEstimateTipEnabled,
    estimateSignature,
    isPriceOnLabelEnabled,
  };
}
