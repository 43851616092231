import { handlerRangeRequest } from '@filters/filterFactories/inventoryAuditDetailsFilters';
import { getCustomAttributeOptionsArray } from '@filters/helpers/customAttribute/getCustomAttributeOptionsArray';
import { inventoryItemReportFilterKeys } from '@filters/helpers/customAttribute/static';
import {
  FilterFunction,
  ProductInventoryItemFilterInput,
} from '@filters/types';

export const createProductInventoryItemReportFilter: FilterFunction<
  ProductInventoryItemFilterInput
> = ({
  productId,
  conditionType,
  genderType,
  process,
  metalFinishType,
  platting,
  metalType,
  materialGradeType,
  engravingType,
  timePeriod,
  productType,
  specificType,
  size,
  shankStyle,
  designStyle,
  backing,
  strand,
  strandsLength,
  knotStyle,
  linkStyle,
  linkType,
  brand,
  country,
  boxPaper,
  pieces,
  locationIds,
  sublocation,
  age,
  allFilters,
} = {}): InvtItmRecordFiltersInput => {
  const { customAttributeOptionFilter, isCustomAttributeOptionSelected } =
    getCustomAttributeOptionsArray(allFilters, inventoryItemReportFilterKeys);

  const isProductFilters =
    productId ||
    conditionType ||
    genderType ||
    process ||
    metalFinishType ||
    platting ||
    metalType ||
    engravingType ||
    timePeriod ||
    productType ||
    isCustomAttributeOptionSelected;

  const productFilter = isProductFilters
    ? {
        product: {
          id: {
            eq: productId,
          },
          conditionType: {
            id: {
              eq: conditionType,
            },
          },
          genderType: {
            id: {
              eq: genderType,
            },
          },
          process: {
            id: {
              eq: process,
            },
          },
          metalFinishType: {
            id: {
              eq: metalFinishType,
            },
          },
          platting: {
            id: {
              eq: platting,
            },
          },
          metalType: {
            id: {
              eq: metalType,
            },
          },
          materialGradeType: {
            id: {
              eq: materialGradeType,
            },
          },
          engravingType: {
            id: {
              eq: engravingType,
            },
          },
          timePeriod: {
            id: {
              eq: timePeriod,
            },
          },
          productType: {
            id: {
              eq: productType,
            },
          },
          specificType: {
            id: {
              eq: specificType,
            },
          },
          size: {
            id: {
              eq: size,
            },
          },
          shankStyle: {
            id: {
              eq: shankStyle,
            },
          },
          designStyle: {
            id: {
              eq: designStyle,
            },
          },
          backing: {
            id: {
              eq: backing,
            },
          },
          strand: {
            id: {
              eq: strand,
            },
          },
          strandsLength: {
            id: {
              eq: strandsLength,
            },
          },
          knotStyle: {
            id: {
              eq: knotStyle,
            },
          },
          linkStyle: {
            id: {
              eq: linkStyle,
            },
          },
          linkType: {
            id: {
              eq: linkType,
            },
          },
          brand: {
            id: {
              eq: brand,
            },
          },
          country: {
            id: {
              eq: country,
            },
          },
          boxPaper: {
            id: {
              eq: boxPaper,
            },
          },
          pieces: {
            id: {
              eq: pieces,
            },
          },
          and: customAttributeOptionFilter,
        },
      }
    : {};

  const businessLocationFilter =
    locationIds?.length !== 0 || sublocation
      ? {
          businessLocation: {
            id: {
              in: locationIds,
            },
          },
          sublocation: {
            id: {
              eq: sublocation,
            },
          },
        }
      : {};

  let ageFilter = {};

  if (age) {
    const [minAge, maxAge] = age.split('-').map(Number);
    ageFilter = {
      ...handlerRangeRequest({
        key: 'age',
        minValue: minAge,
        maxValue: maxAge,
      }),
    };
  }

  return {
    productInventoryItem: { ...productFilter, ...businessLocationFilter },
    ...ageFilter,
  };
};
