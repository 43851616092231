import { FC } from 'react';

import { CustomSpace } from '@ui/space';
import Card, { CardProps } from 'antd/lib/card/Card';

interface Props extends Omit<CardProps, 'title'> {
  spaceSize?: number;
}

export const CustomCard: FC<Props> = ({ children, spaceSize, ...props }) => (
  <Card {...props}>
    <CustomSpace direction={'vertical'} size={spaceSize ?? 18} block>
      {children}
    </CustomSpace>
  </Card>
);
