import { RepairTicketItem } from '@components/contracts/repairTickets/RepairTicketItems/RepairTicketItem/RepairTicketItem';
import { Row } from 'antd';
import { FC } from 'react';

type Props = {
  orderProductsBuy: ProductOrderItemEntity[];
  orderCompositeProducts: CompositeProductOrderItemEntity[];
  orderServices: ServiceOrderItemEntity[];
  orderMemberships: MembershipOrderItemEntity[];
  orderClasses: ClassOrderItemEntity[];
  isPriceVisible?: boolean;
  currency?: string;
};

export const RepairTicketItems: FC<Props> = ({
  orderProductsBuy,
  orderCompositeProducts,
  orderServices,
  orderMemberships,
  orderClasses,
  isPriceVisible,
  currency,
}) => {
  return (
    <Row gutter={[0, 10]}>
      {orderProductsBuy?.map((orderProduct) => (
        <RepairTicketItem
          key={orderProduct?.attributes?.product?.data?.attributes?.uuid}
          itemName={
            orderProduct?.attributes?.product?.data?.attributes?.product?.data
              ?.attributes?.name
          }
          itemPrice={orderProduct?.attributes?.price}
          itemNotes={orderProduct?.attributes?.note}
          isPriceVisible={isPriceVisible}
          currency={currency}
        />
      ))}
      {orderCompositeProducts?.map((orderCompositeProduct) => (
        <RepairTicketItem
          key={
            orderCompositeProduct?.attributes?.compositeProduct?.data
              ?.attributes?.compositeProduct?.data?.attributes?.uuid
          }
          itemName={
            orderCompositeProduct?.attributes?.compositeProduct?.data
              ?.attributes?.compositeProduct?.data?.attributes?.name
          }
          itemPrice={orderCompositeProduct?.attributes?.price}
          itemNotes={orderCompositeProduct?.attributes?.note}
          isPriceVisible={isPriceVisible}
          currency={currency}
        />
      ))}
      {orderServices?.map((orderService) => (
        <RepairTicketItem
          key={orderService?.attributes?.service?.data?.attributes?.uuid}
          itemName={
            orderService?.attributes?.service?.data?.attributes
              ?.serviceLocationInfo?.data?.attributes?.service?.data?.attributes
              ?.name
          }
          itemPrice={orderService?.attributes?.price}
          itemNotes={orderService?.attributes?.note}
          isPriceVisible={isPriceVisible}
          currency={currency}
        />
      ))}
      {orderMemberships?.map((orderMembership) => (
        <RepairTicketItem
          key={orderMembership?.attributes?.membership?.data?.attributes?.uuid}
          itemName={
            orderMembership?.attributes?.membership?.data?.attributes?.name
          }
          itemPrice={orderMembership?.attributes?.price}
          itemNotes={orderMembership?.attributes?.note}
          isPriceVisible={isPriceVisible}
          currency={currency}
        />
      ))}
      {orderClasses?.map((orderClass) => (
        <RepairTicketItem
          key={orderClass?.attributes?.class?.data?.attributes?.uuid}
          itemName={
            orderClass?.attributes?.class?.data?.attributes?.classLocationInfo
              ?.data?.attributes?.class?.data?.attributes?.name
          }
          itemPrice={orderClass?.attributes?.price}
          itemNotes={orderClass?.attributes?.note}
          isPriceVisible={isPriceVisible}
          currency={currency}
        />
      ))}
    </Row>
  );
};
