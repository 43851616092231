export const googleMapsKey =
  import.meta.env.WEBSITE_GOOGLE_MAPS_API_KEY || 'google-maps';

export const tinyMCEKey = import.meta.env.WEBSITE_TINYMCE_API_KEY || 'tiny-mce';

export const unlayerKey =
  import.meta.env.WEBSITE_UNLAYER_API_KEY || 'unlayer-key';

export const unlayerProjectId =
  import.meta.env.WEBSITE_UNLAYER_PROJECT_ID || 'unlayer-id';

export const backendUrl =
  import.meta.env.WEBSITE_BACKEND_URL || 'http://localhost:1337';

export const frontendUrl =
  import.meta.env.WEBSITE_FRONTEND_URL || 'http://localhost:8080';

export const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY || '';
export const cdnUrl = import.meta.env.WEBSITE_CDN_URL;

export const clearentCdnUrl = import.meta.env.VITE_CLEARENT_CDN_URL || '';
