import { ExclamationCircleFilled } from '@ant-design/icons';
import { useInventoryGeneralContext } from '@app/InventoryGeneralContext/InventoryGeneralProvider';
import { Modal } from 'antd';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

const { confirm } = Modal;

export const CHECK_ROUTES = new Set([
  'products',
  'product-groups',
  'composite-products',
  'services',
  'classes',
  'resources',
  'memberships',
  'discounts',
]);

export const showConfirm = (link: string, navigate: NavigateFunction) => {
  confirm({
    title:
      'You have unsaved changes on this page. Do you want to leave this page?',
    icon: <ExclamationCircleFilled />,
    okText: 'Yes',
    cancelText: 'No',
    onOk() {
      navigate(link);
    },
    onCancel() {
      return;
    },
  });
};

export const useConfirmationBeforeBackRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasChangedProductForm } = useInventoryGeneralContext();

  const handleCheckPaths = (link: string) => {
    const pathParts = location.pathname.split('/');
    const hasMatchingRoute = pathParts.some((part) => CHECK_ROUTES.has(part));

    if (hasMatchingRoute && hasChangedProductForm) {
      showConfirm(link, navigate);
    } else {
      navigate(link);
    }
  };

  return { handleCheckPaths };
};
