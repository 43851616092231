export const salesItemReportFilterKeys = [
  'orderId',
  'type',
  'productId',
  'conditionType',
  'genderType',
  'process',
  'metalFinishType',
  'platting',
  'metalType',
  'materialGradeType',
  'engravingType',
  'timePeriod',
  'productType',
  'specificType',
  'size',
  'shankStyle',
  'designStyle',
  'backing',
  'strand',
  'strandsLength',
  'knotStyle',
  'linkStyle',
  'linkType',
  'brand',
  'country',
  'boxPaper',
  'pieces',
  'compositeProductId',
  'serviceId',
  'membershipId',
  'classId',
  'locationIds',
  'sublocation',
  'sales',
  'dueDate',
  'columns',
];

export const inventoryReportFilterKeys = [
  'productId',
  'SKU',
  'conditionType',
  'genderType',
  'process',
  'metalFinishType',
  'platting',
  'metalType',
  'materialGradeType',
  'engravingType',
  'timePeriod',
  'productType',
  'specificType',
  'size',
  'shankStyle',
  'designStyle',
  'backing',
  'strand',
  'strandsLength',
  'knotStyle',
  'linkStyle',
  'linkType',
  'brand',
  'country',
  'boxPaper',
  'pieces',
  'createdAt',
  'locationIds',
  'sublocation',
  'columns',
];

export const inventoryItemReportFilterKeys = [
  'productId',
  'conditionType',
  'genderType',
  'process',
  'metalFinishType',
  'platting',
  'metalType',
  'materialGradeType',
  'engravingType',
  'timePeriod',
  'productType',
  'specificType',
  'size',
  'shankStyle',
  'designStyle',
  'backing',
  'strand',
  'strandsLength',
  'knotStyle',
  'linkStyle',
  'linkType',
  'brand',
  'country',
  'boxPaper',
  'pieces',
  'locationIds',
  'sublocation',
  'age',
  'columns',
];
