import { useCallback, useMemo } from 'react';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

import {
  useInvoiceByUuidQuery,
  useUpdateInvoiceMutation,
  useUpdateInvoiceShippingContactMutation,
} from '@/graphql';
import { InvoiceValues } from '@components/contracts/invoices/types';

import { transformInvoiceData } from '@components/contracts/invoices/helpers/transformInvoiceData';

export function useUpdateInvoice(uuid: string): {
  handleUpdateInvoice: (values: InvoiceValues) => Promise<void>;
  initialValues: InvoiceValues;
  loadings: boolean;
  refetch: () => void;
} {
  const message = useStatusMessage();

  const { data, loading, refetch } = useInvoiceByUuidQuery({
    variables: {
      uuid: uuid,
    },
  });

  const initialValues = useMemo(
    () => transformInvoiceData(data?.invoices?.data?.[0]),
    [data?.invoices?.data],
  );

  const [updateInvoice, { loading: updateLoading }] = useUpdateInvoiceMutation({
    onCompleted: () => {
      message?.open('success', 'Invoice updated successfully!');
    },
    onError: () => {
      message?.open('error');
    },
    refetchQueries: ['invoice'],
  });

  const [updateContact, { loading: updateContactLoading }] =
    useUpdateInvoiceShippingContactMutation({
      onError: () => {
        message?.open('error');
      },
    });

  const handleUpdateInvoice = useCallback(
    async (values: InvoiceValues) => {
      const {
        comment,
        clientMessage,
        shippingContact,
        terms,
        isShowOrderItemsImages,
      } = values;
      const { id, ...otherData } = shippingContact;

      await updateContact({
        variables: {
          id: id,
          input: {
            ...otherData,
          },
        },
      });

      await updateInvoice({
        variables: {
          id: data?.invoices?.data?.[0].id as string,
          input: {
            comment,
            clientMessage,
            terms,
            isShowOrderItemsImages,
          },
        },
      });
    },
    [updateInvoice, updateContact, data?.invoices?.data],
  );

  return {
    handleUpdateInvoice,
    initialValues,
    loadings: loading || updateContactLoading || updateLoading,
    refetch,
  };
}
