import {
  useMeQuery,
  useStartRecordCallMutation,
  useUpdateRecordCallMutation,
} from '@/graphql';
import { useSocket } from '@/socket/useSocket';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { Device } from '@twilio/voice-sdk';
import axios from 'axios';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CallComponent from './CallComponent';

interface CallContextProps {
  isCallActive: boolean;
  isIncomingCall: boolean;
  duration: string;
  isMuted: boolean;
  toggleMute: () => void;
  handleDecline: () => void;
  handleAccept: () => void;
  makeOutgoingCall: (phoneNumber: string, contactName: string) => void;
}
const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/[()\s-]/g, '');
};

const CallContext = createContext<CallContextProps | undefined>(undefined);

export const CallProvider: FC<PropsWithChildren> = ({ children }) => {
  const message = useStatusMessage();
  const [isCallActive, setIsCallActive] = useState(false);
  const [isRecordActive, setIsRecordActive] = useState(false);
  const [showTransferButton, setShowTransferButton] = useState(false);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [callSid, setCallSid] = useState<string | null>(null);
  const [recordingSid, setRecordingSid] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState('00:00');
  const [device, setDevice] = useState<Device | null>(null);
  const [startRecord] = useStartRecordCallMutation();
  const [updateRecord] = useUpdateRecordCallMutation();
  const [call, setCall] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactName, setContactName] = useState('');
  const { data: me, loading } = useMeQuery();

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const socket = useSocket();

  const startTimer = () => {
    let totalSeconds = 0;
    timerRef.current = setInterval(() => {
      totalSeconds += 1;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      setDuration(
        `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
          2,
          '0',
        )}`,
      );
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const cleanupCallData = useCallback(() => {
    setIsRecordActive(false);
    setIsCallActive(false);
    setIsIncomingCall(false);
    setRecordingSid(null);
    setCallSid(null);
    stopTimer();
    setDuration('00:00');
    setPhoneNumber('');
    setContactName('');
    setShowTransferButton(false);
  }, []);

  const getMicrophoneAccess = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (err) {
      message.open('error', 'Failed to get microphone access');
    }
  }, [message]);

  useEffect(() => {
    if (loading || !me) return;

    const initTwilioDevice = async () => {
      const isLocalhost = window.location.hostname === 'localhost';

      if (isLocalhost) {
        console.log('Twilio Device initialization is disabled on localhost.');
        return;
      }
      try {
        if (!me?.me?.id) return;
        const id = btoa(me?.me?.id);
        const twilioConnectionId =
          me?.me?.attributes?.tenant?.data?.attributes?.twilioConnection?.data
            ?.id;
        if (!id) return;
        if (!twilioConnectionId) return;

        const tokenResponse = await fetch(
          `/api/twilio/token?userId=${encodeURIComponent(id)}`,
        );
        const { token } = await tokenResponse.json();

        const twilioDevice = new Device(token, { logLevel: 5 });

        twilioDevice.on('registered', () => {
          console.log('Twilio Device is ready to make calls.');
        });

        twilioDevice.on('incoming', (call) => {
          getMicrophoneAccess();
          setCall(call);
          setPhoneNumber(call.parameters.From);
          setIsIncomingCall(true);
        });

        twilioDevice.on('error', (error) => {
          console.error('Twilio Device Error:', error.message);
        });

        await twilioDevice.register();
        setDevice(twilioDevice);
      } catch (err) {
        console.error('Error initializing Twilio Device:', err);
      }
    };

    initTwilioDevice();
    return () => {
      stopTimer();
    };
  }, [me, loading, getMicrophoneAccess]);

  const makeOutgoingCall = async (phone: string, name: string) => {
    if (!device) {
      message.open('error', 'Twilio Device not initialized');
      return;
    }

    await getMicrophoneAccess();

    setPhoneNumber(phone);
    setContactName(name);

    try {
      const params = { To: phone, From: me?.me?.attributes?.fullName ?? '' };
      const call = await device.connect({ params });
      setCall(call);
      setCallSid(call.parameters.Sid);

      call.on('accept', (args) => {
        setCallSid(args.parameters.CallSid);
        setIsCallActive(true);
        startTimer();
      });

      call.on('disconnect', () => {
        cleanupCallData();
      });

      call.on('closed', () => {
        cleanupCallData();
      });

      call.on('error', (error) => {
        console.error('Call error:', error.message);
      });
    } catch (err) {
      console.error('Error initiating outgoing call:', err);
    }
  };
  const handleRecordCall = async () => {
    if (!callSid) {
      message.open('error', 'Something went wrong. Please try again.');
      return;
    }

    try {
      if (!recordingSid) {
        const response = await startRecord({
          variables: {
            callSid,
          },
        });

        const newRecordingSid =
          response.data?.startRecordCall?.recordingSid ?? null;
        if (newRecordingSid) {
          setRecordingSid(newRecordingSid);
          setIsRecordActive(true);
        } else {
          console.error(
            'Failed to start recording: recordingSid is null or undefined.',
          );
        }
      } else {
        const status = isRecordActive ? 'paused' : 'in-progress';
        await updateRecord({
          variables: {
            callSid,
            recordSid: recordingSid,
            status: status,
          },
        });
        setIsRecordActive(status === 'in-progress');
      }
    } catch (err) {
      console.error('Error handling recording:', err);
    }
  };

  const handleDecline = () => {
    if (call) {
      if (isCallActive) {
        call.disconnect();
        cleanupCallData();
      } else {
        call.reject();
        cleanupCallData();
      }
    }
  };

  const handleAccept = () => {
    if (call) {
      call.accept();
      setIsIncomingCall(false);
      setIsCallActive(true);
      startTimer();
      setShowTransferButton(true);
    }
  };

  const toggleMute = () => {
    if (call) {
      if (isMuted) {
        call.mute(false);
      } else {
        call.mute(true);
      }
      setIsMuted((prevMuted) => !prevMuted);
    }
  };

  const handleTransfer = async (employeePhone: string, tenant: string) => {
    try {
      await axios.post('/api/twilio/transfer', {
        CallSid: callSid,
        clientId: formatPhoneNumber(employeePhone),
        tenant,
      });
      message.open('success', 'Call transferred successfully');
    } catch (error) {
      message.open('error', 'Failed to transfer call');
    }
  };

  useEffect(() => {
    socket.on('callStatus', (data) => {
      if (data.callSid) {
        setCallSid(data.callSid);
      }
      if (data.status === 'initiated') {
        setIsCallActive(true);
        startTimer();
      } else if (
        data.status === 'completed' ||
        data.status === 'disconnected' ||
        data.status === 'canceled' ||
        data.status === 'busy' ||
        data.status === 'no-answer'
      ) {
        cleanupCallData();
      } else if (data.status === 'error') {
        console.error('Error:', data.message);
      }
    });

    socket.on('incomingCall', (data) => {
      if (data.callSid) {
        setCallSid(data.callSid);
      }
    });

    return () => {
      socket.off('callStatus');
    };
  }, [cleanupCallData, socket]);

  return (
    <CallContext.Provider
      value={{
        isCallActive,
        isIncomingCall,
        duration,
        isMuted,
        toggleMute,
        handleDecline,
        handleAccept,
        makeOutgoingCall,
      }}
    >
      {children}
      <CallComponent
        showTransferButton={showTransferButton}
        handleTransfer={handleTransfer}
        handleStartRecording={handleRecordCall}
        phoneNumber={phoneNumber}
        contactName={contactName}
        isCallActive={isCallActive}
        isRecordActive={isRecordActive}
        isIncomingCall={isIncomingCall}
        duration={duration}
        isMuted={isMuted}
        toggleMute={toggleMute}
        handleDecline={handleDecline}
        handleAccept={handleAccept}
      />
    </CallContext.Provider>
  );
};

export const useCall = () => {
  const context = useContext(CallContext);
  if (context === undefined) {
    throw new Error('useCall must be used within a CallProvider');
  }
  return context;
};
