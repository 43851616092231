import { Col, Row, Typography } from 'antd';
import { FC } from 'react';

interface InvoiceFooterSectionProps {
  terms?: string;
  email: string;
  phoneNumber: string;
  url: string;
}

export const InvoiceFooterSection: FC<InvoiceFooterSectionProps> = ({
  terms,
  email,
  phoneNumber,
  url,
}) => {
  return (
    <Row
      style={{
        width: '100%',
        margin: 0,
        padding: '20px 0',
        borderTop: '1px #E7E7E7 solid',
      }}
      justify={'center'}
      gutter={[0, 24]}
    >
      {terms && (
        <Col span={24}>
          <Typography.Text
            style={{
              fontSize: 10,
              color: '#959595',
              whiteSpace: 'break-spaces',
            }}
          >
            {terms}
          </Typography.Text>
        </Col>
      )}
      <Col span={24}>
        <Row justify={'center'} gutter={[24, 12]}>
          <Col>
            <Typography.Text style={{ fontSize: 12, color: '#626670' }}>
              {phoneNumber}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text style={{ fontSize: 12, color: '#626670' }}>
              {email}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text style={{ fontSize: 12, color: '#626670' }}>
              {url}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
