import { useEffect, useMemo, useState } from 'react';

import { useCompaniesLazyQuery } from '@/graphql';

import { isNotEmpty } from '@helpers/types';

interface Props {
  pagination?: PaginationArg;
  initialFilters?: Omit<UsersPermissionsUserFiltersInput, 'role'>;
  sort?: string | string[];
}

export const useSelectCompaniesQuery = ({
  pagination,
  initialFilters,
  sort,
}: Props) => {
  const [filters, setFilters] = useState<
    Omit<UsersPermissionsUserFiltersInput, 'role'>
  >({});

  const [fetch, { data, loading }] = useCompaniesLazyQuery({
    variables: {
      filters: {
        ...initialFilters,
        ...filters,
      },
      pagination,
      sort,
    },
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  const companies = useMemo(() => {
    return data?.companies?.data.filter(isNotEmpty) || [];
  }, [data?.companies?.data]);

  return {
    data: companies,
    meta: data?.companies?.meta,
    loading: loading,
    changeFilters: setFilters,
  };
};
