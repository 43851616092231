import { Input, Space } from 'antd';
import { get } from 'lodash';
import { ChangeEvent, FC, useMemo, useState } from 'react';

import { Icon, IconSize } from '@assets/icon';
import { CustomButton } from '@ui/button/Button';

import { useOrderRequests } from './hooks/useOrderRequests';

interface Props {
  orderId: string;
}

export const OrderPurchaseInvoiceInput: FC<Props> = ({ orderId }) => {
  const [invoiceNum, setInvoiceNum] = useState<string | null>();
  const [openSubmitBtn, setOpenSubmitBtn] = useState(false);

  const { orderData, loadingOrder, updateOrderLoading, handleUpdateOrder } =
    useOrderRequests(orderId);
  const defaultValue = useMemo(
    () => get(orderData, 'orders.data[0].attributes.inputInvoiceNum', ''),
    [orderData],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvoiceNum(e.target.value);
    setOpenSubmitBtn(Boolean(e.target.value));
  };
  const handleSubmit = () => {
    if (invoiceNum) handleUpdateOrder(invoiceNum);
    setOpenSubmitBtn(false);
  };

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input
        onChange={handleChange}
        style={{ width: '80%' }}
        addonBefore={'Invoice'}
        allowClear
        defaultValue={defaultValue}
      />
      {openSubmitBtn && (
        <CustomButton
          type={'text'}
          loading={updateOrderLoading}
          style={{ backgroundColor: 'transparent' }}
          onClick={handleSubmit}
          icon={
            <Icon type={'check'} exactSize={IconSize.Large} color={'#0146C5'} />
          }
        />
      )}
    </Space.Compact>
  );
};
