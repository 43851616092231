import { useNavigate } from 'react-router';

import { useLoginMutation } from '@/graphql';

import { usePlatformRoutes } from '@router/routes';

import { checkPasswordRules } from '@/utils/checkPasswordRules';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

export const useLogin = (): ReturnType<typeof useLoginMutation> => {
  const message = useStatusMessage();
  const navigate = useNavigate();

  const routes = usePlatformRoutes();

  return useLoginMutation({
    onCompleted: (data, clientOptions) => {
      if (data?.login?.user) {
        const isStrongPassword = !checkPasswordRules(
          clientOptions?.variables?.input.password,
        ).length;

        navigate(routes.onboarding, {
          state: {
            isStrongPassword,
          },
        });

        if (!isStrongPassword) {
          message.open('warning', 'Please consider using a stronger password');
        }
      }
    },
    onError: (error, clientOptions) => {
      if (error.message.includes('Your account email is not confirmed')) {
        message.open('error', 'Your account email is not confirmed');

        navigate(routes.auth.notConfirmed, {
          state: {
            email: clientOptions?.variables?.input?.identifier,
          },
        });
      } else {
        if (
          error.message.includes(
            'Your account has been blocked by an administrator',
          )
        ) {
          message.open(
            'error',
            'Your account has been blocked by an administrator',
          );
        } else {
          message.open('error', 'Invalid email or password, please try again');
        }
      }
    },
  });
};
