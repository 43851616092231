import { ContactInfoFields } from '@components/contracts/invoices/view/ContactInfoFields';
import { useOrderData } from '@hooks/pos/useOrderData';
import { FC } from 'react';

type Props = {
  orderId?: Maybe<string> | null;
  title: string;
  clientName?: string;
  clientAddress?: string;
  clientPhone?: string;
  clientEmail?: string;
};
export const ContactInfoOrder: FC<Props> = ({
  orderId,
  title,
  clientName,
  clientAddress,
  clientPhone,
  clientEmail,
}) => {
  const { orderContact, orderCompany } = useOrderData(orderId);

  return (
    <>
      <ContactInfoFields
        dataContact={orderContact}
        dataCompany={orderCompany}
        title={title}
        clientName={clientName}
        clientAddress={clientAddress}
        clientPhone={clientPhone}
        clientEmail={clientEmail}
      />
    </>
  );
};
