import { Dayjs } from 'dayjs';

import {
  CrmFilters,
  CrmSingleEntityFiltersInput,
  FilterFunction,
} from '@filters/types';
import { transformToDateFilterInputForDate } from '@helpers/filters';
import { formatMonthAndDayToDatesArray } from '@helpers/formatMonthAndDayToDatesArray';

export const createContactFilter: FilterFunction<string> = (
  contact?: string,
): ContactFiltersInput => {
  return contact
    ? {
        id: {
          eq: contact,
        },
      }
    : {};
};

export const createLeadSourceFilter: FilterFunction<string[]> = (
  leadSource?: string[],
): CrmSingleEntityFiltersInput => {
  return leadSource?.length !== 0
    ? {
        leadSource: {
          in: leadSource,
        },
      }
    : {};
};

export const createLeadStageFilter: FilterFunction<string[]> = (
  leadStage?: string[],
): CrmSingleEntityFiltersInput => {
  return leadStage?.length !== 0
    ? {
        leadStage: {
          in: leadStage,
        },
      }
    : {};
};

export const createLeadOwnerFilter: FilterFunction<string[]> = (
  leadOwner?: string[],
): CrmFilters => {
  return leadOwner?.length !== 0
    ? {
        leadOwner: {
          id: {
            in: leadOwner,
          },
        },
      }
    : {};
};

export const createCompanyFilter: FilterFunction<string[]> = (
  company?: string[],
): ContactFiltersInput => {
  return company?.length !== 0
    ? {
        company: {
          id: {
            in: company,
          },
        },
      }
    : {};
};

export const createContactBirthdayDateFilter = (
  birthdayDateRange?: [Dayjs | null, Dayjs | null] | null,
): ContactFiltersInput => {
  return birthdayDateRange
    ? {
        ...(birthdayDateRange && {
          birthdayDate: transformToDateFilterInputForDate(birthdayDateRange),
        }),
      }
    : {};
};

export const createContactBirthdayDateByMonthAndDayFilter = (
  birthdayDateMonth?: Dayjs | null,
  birthdayDateDay?: Dayjs | null,
): ContactFiltersInput => {
  return !birthdayDateMonth && !birthdayDateDay
    ? {}
    : {
        birthdayDate: {
          in: formatMonthAndDayToDatesArray(birthdayDateMonth, birthdayDateDay),
        },
      };
};

export const createContactAnniversaryDateFilter = (
  dateAnniversaryRange?: [Dayjs | null, Dayjs | null] | null,
): ContactFiltersInput => {
  return dateAnniversaryRange
    ? {
        ...(dateAnniversaryRange && {
          dateAnniversary:
            transformToDateFilterInputForDate(dateAnniversaryRange),
        }),
      }
    : {};
};

export const createContactAnniversaryDateByMonthAndDayFilter = (
  dateAnniversaryMonth?: Dayjs | null,
  dateAnniversaryDay?: Dayjs | null,
): ContactFiltersInput => {
  return !dateAnniversaryMonth && !dateAnniversaryDay
    ? {}
    : {
        dateAnniversary: {
          in: formatMonthAndDayToDatesArray(
            dateAnniversaryMonth,
            dateAnniversaryDay,
          ),
        },
      };
};

export const createActivityTypeFilter: FilterFunction<string[]> = (
  activityType?: string[],
): ActivityFiltersInput => {
  return activityType?.length !== 0
    ? {
        type: {
          in: activityType,
        },
      }
    : {};
};

export const createActivityDueDateFilter = (
  dueDate?: [Dayjs | null, Dayjs | null] | null,
): ActivityFiltersInput => {
  if (!dueDate || (dueDate[0] === null && dueDate[1] === null)) {
    return {};
  }

  const startDate = dueDate[0]?.startOf('day').toDate();
  const endDate = dueDate[1]?.endOf('day').toDate();

  return dueDate
    ? {
        and: [
          {
            due_date: {
              gte: startDate,
              lte: endDate,
            },
          },
        ],
      }
    : {};
};

export const createActivityOwnerFilter: FilterFunction<string> = (
  owner?: string,
): ActivityFiltersInput => {
  return owner
    ? {
        and: [
          {
            owner: {
              id: {
                eq: owner,
              },
            },
          },
        ],
      }
    : {};
};
