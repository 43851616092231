import {
  useCreateDownloadRecordMutation,
  useDeleteDownloadRecordMutation,
} from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

interface ReturnType {
  createRecord: (userId: string, fileItem: string) => Promise<void>;
  removeRecord: (fileId: string) => Promise<void>;
}

export function useDownloadRecord(): ReturnType {
  const message = useStatusMessage();
  const [createDownloadedRecord] = useCreateDownloadRecordMutation({
    onError: () => {
      message.open('error');
    },
  });
  const [removeDownloadRecord] = useDeleteDownloadRecordMutation({
    onError: () => {
      message.open('error');
    },
  });

  const createRecord = async (userId: string, fileItem: string) => {
    await createDownloadedRecord({
      variables: {
        input: {
          downloadedBy: userId,
          fileItem: fileItem,
        },
      },
    });
  };

  const removeRecord = async (fileId: string) => {
    await removeDownloadRecord({
      variables: {
        id: fileId,
      },
    });
  };

  return {
    removeRecord,
    createRecord,
  };
}
