import {
  AudioMutedOutlined,
  AudioOutlined,
  KeyOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { Icon } from '@assets/icon';
import { useEmployeesQuery } from '@hooks/users/useEmployeesQuery';
import {
  Avatar,
  Button,
  Divider,
  Modal,
  Select,
  Space,
  Typography,
} from 'antd';
import { FC, useState } from 'react';

const { Text } = Typography;

interface CallComponentProps {
  phoneNumber: string;
  contactName: string;
  avatar?: string;
  isCallActive: boolean;
  isIncomingCall: boolean;
  duration: string;
  isMuted: boolean;
  toggleMute: () => void;
  handleDecline: () => void;
  handleAccept: () => void;
  isRecordActive: boolean;
  handleStartRecording: () => void;
  handleTransfer: (employeePhone: string, tenant: string) => void;
  showTransferButton?: boolean;
}

const CallComponent: FC<CallComponentProps> = ({
  phoneNumber,
  contactName,
  avatar,
  isCallActive,
  isIncomingCall,
  duration,
  isMuted,
  toggleMute,
  handleDecline,
  handleAccept,
  handleStartRecording,
  isRecordActive,
  handleTransfer,
  showTransferButton,
}) => {
  const { data: employees, loading } = useEmployeesQuery({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<string | undefined>(
    undefined,
  );

  const { meData } = useUserSettingsContext();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedEmployee) {
      handleTransfer(
        selectedEmployee,
        meData?.me?.attributes?.tenant?.data?.id ?? '',
      );
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div
      style={{
        transition: 'transform 0.6s ease-in-out, bottom 0.6 ease-in-out',
        backgroundColor: isCallActive || isIncomingCall ? '#fff' : '#f0f2f5',
        boxShadow: '0px 4px 12px 0px #00000014',
        bottom: '0',
        justifyContent: 'space-between',
        position: 'fixed',
        padding: '16px 32px',
        alignItems: 'center',
        display: isCallActive || isIncomingCall ? 'flex' : 'none',
        zIndex: 60,
        right: 0,
        left: 0,
      }}
    >
      <Space>
        {isIncomingCall && (
          <>
            <Text
              style={{
                marginRight: 10,
                fontWeight: 500,
                color: '#000',
                fontSize: 16,
              }}
            >
              Incoming call from
            </Text>
          </>
        )}

        <Avatar src={avatar ?? undefined} size={24} icon={<UserOutlined />} />
        <Space direction={'horizontal'} size={0}>
          <Text
            style={{
              color: '#4876D5',
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            {contactName}
          </Text>
          <Divider
            type={'vertical'}
            style={{
              margin: '0 15px',
              height: 17,
            }}
          />
          <Text style={{ color: '#000', fontSize: 16 }}>{phoneNumber}</Text>
          <Divider
            type={'vertical'}
            style={{
              margin: '0 15px',
              height: 17,
            }}
          />
          {isCallActive && (
            <Text style={{ color: '#000', fontSize: 16 }}>{duration}</Text>
          )}
        </Space>
      </Space>

      <Space size={'large'}>
        {isIncomingCall ? (
          <>
            <Button
              icon={<PhoneOutlined style={{ color: '#0146C5' }} />}
              style={{ background: '#E7ECF8' }}
              size={'large'}
              type={'primary'}
              shape={'circle'}
              onClick={handleAccept}
            />
            <Button
              style={{ transform: 'rotate(225deg)' }}
              icon={<PhoneOutlined />}
              size={'large'}
              type={'primary'}
              shape={'circle'}
              danger
              onClick={handleDecline}
            />
          </>
        ) : (
          <>
            <Button
              icon={
                !isMuted ? (
                  <AudioMutedOutlined style={{ color: '#fff' }} />
                ) : (
                  <AudioOutlined style={{ color: '#0146C5' }} />
                )
              }
              style={{ background: isMuted ? '#E7ECF8' : '#0146C5' }}
              onClick={toggleMute}
              size={'large'}
              type={'primary'}
              shape={'circle'}
            />
            <Button
              icon={<KeyOutlined style={{ color: '#0146C5' }} />}
              style={{ background: '#E7ECF8' }}
              size={'large'}
              type={'primary'}
              shape={'circle'}
            />
            <Button
              style={{ transform: 'rotate(225deg)' }}
              icon={<PhoneOutlined />}
              size={'large'}
              type={'primary'}
              shape={'circle'}
              danger
              onClick={handleDecline}
            />
            <Button
              style={{
                transform: 'rotate(225deg)',
                background: 'rgb(231, 236, 248)',
                border: 'none',
              }}
              icon={
                <Icon
                  type={'record'}
                  color={!isRecordActive ? '#1F2933' : 'red'}
                />
              }
              size={'large'}
              type={'primary'}
              shape={'circle'}
              ghost
              onClick={handleStartRecording}
            />
            {showTransferButton ? (
              <>
                <Button
                  icon={<Icon type={'phone-transfer'} />}
                  style={{ background: '#0146C5' }}
                  size={'large'}
                  type={'primary'}
                  shape={'circle'}
                  onClick={showModal}
                />
                <Modal
                  title={'Transfer Call'}
                  open={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <p>Select the employee to transfer the call to:</p>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={'Select an employee'}
                    onChange={(value) => setSelectedEmployee(value)}
                    options={
                      employees
                        ?.filter((user) => user?.id !== meData?.me?.id)
                        .map((employee) => ({
                          label: employee.attributes?.fullName,
                          value: employee.attributes?.email,
                        })) || []
                    }
                    value={selectedEmployee}
                    loading={loading}
                  />
                </Modal>
              </>
            ) : null}
          </>
        )}
      </Space>
    </div>
  );
};

export default CallComponent;
