import { useCreateRateMutation } from '@/graphql';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { CustomForm } from '@form';

import { CustomFormItem } from '@/components/form/item/FormItem';
import { ConfigProvider, Flex, Form, Modal, Rate } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';

import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { handleApplicationError } from '@helpers/errors';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { CustomButton } from '../button/Button';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (arg: boolean) => void;
}

const SatisfactionModal: FC<Props> = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = useForm();
  const [isLoved, setIsLoved] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const { meData } = useUserSettingsContext();
  const [createRate] = useCreateRateMutation();
  const message = useStatusMessage();

  const rateForm = Form.useWatch('evaluation', form);

  const getStarIcon = useMemo(
    () =>
      (count: number): React.ReactNode => {
        return count < rateForm + 1 ? (
          <StarFilled style={{ color: '#4876D5', fontSize: 38 }} />
        ) : (
          <StarOutlined style={{ color: '#4876D5', fontSize: 38 }} />
        );
      },
    [rateForm],
  );

  const customIcons = useMemo(
    () => [1, 2, 3, 4, 5].map((count) => getStarIcon(count)),
    [getStarIcon], // Include any other dependencies here
  );

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const rateCharacter = useCallback(
    ({ index = 0 }) => customIcons[index],
    [customIcons],
  );

  const handleOnOk = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue();
      form.resetFields();
      createRate({
        variables: {
          input: {
            ...values,
            isLoved: isLoved,
            isRecommended: isRecommended,
            user: meData?.me?.id,
          },
        },
        onCompleted: () => {
          message.open('success', 'Thank you for your Rating');
        },
        onError: (error) => handleApplicationError(error, message),
      });

      setIsModalOpen(false);
    } catch (error) {
      // Handle validation errors or any other errors if needed
      handleApplicationError(error, message);
    }
  };

  const handleLoveButtonClick = () => {
    setIsLoved((prevIsLoved) => !prevIsLoved);
  };

  const handleRecommendedButtonClick = () => {
    setIsRecommended((prevIsRecommended) => !prevIsRecommended);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            paddingLG: 40,
          },
          Rate: {
            margin: 10,
          },
        },
      }}
    >
      <Modal
        centered
        forceRender
        onOk={handleOnOk}
        okText={'Send'}
        okButtonProps={{ style: { height: 40, width: 80, fontSize: 12 } }}
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
        title={
          <Flex
            align={'center'}
            justify={'center'}
            style={{
              paddingTop: 40,
              fontSize: 24,
            }}
          >
            How satisfied are you with CaratIQ?
          </Flex>
        }
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <CustomForm
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          form={form}
        >
          <CustomFormItem
            name={'evaluation'}
            style={{ marginTop: 20 }}
            rules={[
              {
                required: true,
                message: 'Please input your Rate',
              },
            ]}
          >
            <Rate character={rateCharacter} />
          </CustomFormItem>
          <Flex gap={8}>
            <CustomButton
              onClick={handleLoveButtonClick}
              type={isLoved ? 'primary' : 'default'}
            >
              I love it!
            </CustomButton>
            <CustomButton
              onClick={handleRecommendedButtonClick}
              type={isRecommended ? 'primary' : 'default'}
            >
              I’ll recommend this to my friends
            </CustomButton>
          </Flex>
          <CustomFormItem
            style={{ marginTop: 20, width: '100%' }}
            name={'suggestions'}
          >
            <TextArea
              placeholder={'Any Suggestions?'}
              style={{ padding: '12px 16px', height: 100 }}
            />
          </CustomFormItem>
        </CustomForm>
      </Modal>
    </ConfigProvider>
  );
};

export default SatisfactionModal;
