import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { Col, ConfigProvider, QRCode, Row, Typography } from 'antd';
import { FC } from 'react';

type Props = {
  orderCustomerCode: string;
  orderCustomerName: string;
  orderCustomerPhone: string;
  orderCustomerEmail: string;
  orderEmployeeName: string;
  orderCreationDate: Maybe<Date>;
  orderDueDate: Maybe<Date>;
  orderId: Maybe<string>;
  isCustomerInfoVisible?: boolean;
};

export const RepairTicketOrderInstructions: FC<Props> = ({
  orderCustomerCode,
  orderCustomerName,
  orderCustomerPhone,
  orderCustomerEmail,
  orderEmployeeName,
  orderCreationDate,
  orderDueDate,
  orderId,
  isCustomerInfoVisible = true,
}) => {
  const { formatDateAndTime } = useUserSettingsContext();
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
        },
      }}
    >
      <Row justify={'space-between'} style={{ minHeight: 60 }} gutter={[0, 2]}>
        <Col span={24}>
          <Row>
            <Col span={17}>
              <Row gutter={[0, 2]}>
                <Col span={24}>
                  <Typography.Title level={2}>
                    {orderCustomerCode} {formatDateAndTime(orderDueDate)}
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Typography.Text>
                    Taken in on {formatDateAndTime(orderCreationDate)}{' '}
                    {orderEmployeeName && `by ${orderEmployeeName}`}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>

            <Col
              span={7}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Row style={{ display: 'block' }}>
                <Col
                  span={24}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <QRCode value={orderId || '-'} size={36} bordered={false} />
                </Col>
                <Col span={24} style={{ marginTop: 2, textAlign: 'center' }}>
                  <Typography.Text>{orderId}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {orderCustomerName && isCustomerInfoVisible && (
          <Col span={24}>
            <Row gutter={8}>
              <Col>
                <Typography.Text>{orderCustomerName}:</Typography.Text>
              </Col>
              {orderCustomerPhone && (
                <Col>
                  <Typography.Text>{orderCustomerPhone}</Typography.Text>
                </Col>
              )}
              {orderCustomerEmail && (
                <Col>
                  <Typography.Text>{orderCustomerEmail}</Typography.Text>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </ConfigProvider>
  );
};
