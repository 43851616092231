import { Card, Row } from 'antd';
import { CardProps } from 'antd/lib/card/Card';
import { ReactNode, forwardRef } from 'react';

interface RepairTicketCardProps extends CardProps {
  children?: ReactNode;
}

export type Ref = HTMLDivElement | null;
export const RepairTicketCard = forwardRef<Ref, RepairTicketCardProps>(
  ({ children, ...props }, ref) => {
    return (
      <Card
        style={{ padding: '0' }}
        styles={{
          body: {
            padding: 0,
          },
        }}
        ref={ref}
        {...props}
      >
        <Row
          style={{
            width: '100%',
            padding: 0,
            margin: 0,
          }}
        >
          {children}
        </Row>
      </Card>
    );
  },
);
