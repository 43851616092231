import { Dayjs } from 'dayjs';

const yearsRange = 101; //This is a value for selecting the last X amount of years from the current year eg: 2025-1925
const monthAmount = 12;
const monthWithMostDays = ['01', '03', '05', '07', '08', '10', '12'];
const monthWithLeastDays = ['02'];
const leastDays = 29;
const averageDays = 30;
const mostDays = 31;

export const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const formatMonthAndDayToDatesArray = (
  dateMonth?: Dayjs | null,
  dateDay?: Dayjs | null,
): string[] | null => {
  if (!dateMonth && !dateDay) return null;

  const currentYear = new Date().getFullYear();

  if (!dateMonth && dateDay) {
    const day = dateDay.format('DD');

    if (Number(day) > mostDays) return null;

    if (Number(day) === mostDays) {
      const monthDayArray = monthWithMostDays.map((month) => `${month}-${day}`);

      return Array.from({ length: yearsRange }, (_, index) =>
        monthDayArray.map((monthDay) => `${currentYear - index}-${monthDay}`),
      ).flat();
    } else if (Number(day) === averageDays) {
      const monthDayArray = Array.from({ length: monthAmount }, (_, index) => {
        const month = (index + 1).toString().padStart(2, '0');
        return monthWithLeastDays.includes(month) ? null : `${month}-${day}`;
      }).filter(Boolean);

      return Array.from({ length: yearsRange }, (_, index) =>
        monthDayArray.map((monthDay) => `${currentYear - index}-${monthDay}`),
      ).flat();
    } else if (Number(day) === leastDays) {
      const monthDayArray = Array.from({ length: monthAmount }, (_, index) => {
        const month = (index + 1).toString().padStart(2, '0');
        return month === '02'
          ? `${month}-${day}`
          : month !== '02'
          ? `${month}-${day}`
          : null;
      }).filter(Boolean);

      return Array.from({ length: yearsRange }, (_, index) => {
        const year = currentYear - index;
        const monthDayString = monthDayArray.map(
          (monthDay) => `${year}-${monthDay}`,
        );
        return isLeapYear(year)
          ? monthDayString
          : monthDayArray
              .filter((m) => !m?.includes('02-'))
              .map((m) => `${year}-${m}`);
      }).flat();
    } else {
      const monthDayArray = Array.from(
        { length: monthAmount },
        (_, index) => `${(index + 1).toString().padStart(2, '0')}-${day}`,
      );

      return Array.from({ length: yearsRange }, (_, index) =>
        monthDayArray.map((monthDay) => `${currentYear - index}-${monthDay}`),
      ).flat();
    }
  } else if (dateMonth && !dateDay) {
    const month = dateMonth.format('MM');
    const isMonthWithMostDays = monthWithMostDays?.includes(month);
    const isMonthWithLeastDays = monthWithLeastDays?.includes(month);

    const monthDayArray = Array.from(
      {
        length: isMonthWithMostDays
          ? mostDays
          : isMonthWithLeastDays
          ? leastDays
          : averageDays,
      },
      (_, index) => `${month}-${(index + 1).toString().padStart(2, '0')}`,
    );

    return Array.from({ length: yearsRange }, (_, index) => {
      const year = currentYear - index;
      const monthDayString = monthDayArray.map(
        (monthDay) => `${year}-${monthDay}`,
      );
      return isLeapYear(year)
        ? monthDayString
        : monthDayArray
            .filter((m) => !m?.includes('-29'))
            .map((m) => `${year}-${m}`);
    }).flat();
  } else if (dateMonth && dateDay) {
    const month = dateMonth.format('MM');
    const day = dateDay.format('DD');
    const isMonthWithAverageDays = !monthWithMostDays?.includes(month);
    const isMonthWithLeastDays = monthWithLeastDays?.includes(month);

    if (
      (isMonthWithLeastDays && Number(day) > leastDays) ||
      (isMonthWithAverageDays && Number(day) > averageDays) ||
      Number(month) > monthAmount ||
      Number(day) > mostDays
    )
      return null;

    return Array.from({ length: yearsRange }, (_, index) => {
      const year = currentYear - index;
      const monthDayString = `${year}-${month}-${day}`;

      return !isLeapYear(year) && monthDayString.includes('02-29')
        ? null
        : monthDayString;
    })
      .filter((item): item is string => item !== null && item !== undefined)
      .flat();
  }

  return null;
};
