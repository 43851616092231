import { useContactsQuery } from '@/graphql';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { CustomOption } from '@form/inputs/userSelect/UserSelect';
import { SelectProps } from 'antd';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface Props extends SelectProps {
  onChange?: (value: string | string[]) => void;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  defaultValue?: string | string[];
  initFilters?: ContactFiltersInput;
  limit?: number;
  setSelectedContactData?: Dispatch<SetStateAction<Maybe<ContactFragment>>>;
}

const ContactsSelect: FC<Props> = ({
  onChange,
  mode,
  placeholder,
  defaultValue,
  initFilters = {},
  limit = 10,
  setSelectedContactData,
  ...props
}) => {
  const [filters, setFilters] = useState<ContactFiltersInput>();
  const queryParams = useMemo(
    () => ({
      pagination: {
        limit: limit,
      },
      filters: {
        ...initFilters,
        ...filters,
      },
    }),
    [limit, filters, initFilters],
  );
  const { data, loading, refetch } = useContactsQuery({
    variables: {
      ...queryParams,
    },
  });

  const contactsData = useMemo(() => data?.contacts?.data || [], [data]);

  const handleChange = useCallback(
    (value: string | string[]) => {
      onChange?.(value);
      setSelectedContactData?.(
        contactsData.find((contact) => contact.id === value),
      );
    },
    [onChange, contactsData, setSelectedContactData],
  );

  useEffect(() => {
    refetch({ ...queryParams });
  }, [filters, refetch, queryParams]);

  useEffect(() => {
    if (props.value) {
      setSelectedContactData?.(
        contactsData.find((contact) => contact.id === props.value),
      );
    }
  }, [props.value, contactsData, setSelectedContactData]);

  const contacts = useMemo(
    () =>
      contactsData.map((contact) => ({
        title: contact?.attributes?.fullName,
        value: contact?.id,
        label: contact?.attributes?.fullName,
      })) as CustomOption[],
    [contactsData],
  );

  const handleSearch = useCallback(
    (value: string) => {
      setFilters(
        value
          ? {
              or: [
                { fullName: { containsi: value } },
                { email: { containsi: value } },
                { phoneNumber: { containsi: value } },
              ],
            }
          : {},
      );
    },
    [setFilters],
  );

  return (
    <SearchSelect<string | string[], CustomOption>
      placeholder={placeholder ?? 'Select Contact'}
      options={contacts}
      optionLabelProp={'title'}
      onChange={handleChange}
      onSearch={handleSearch}
      allowClear
      loading={loading}
      mode={mode}
      defaultValue={defaultValue}
      disabled={props.disabled}
    />
  );
};

export default ContactsSelect;
