import { OrderSummaryItem } from '@components/selling/pos/orders-list/OrderSummaryItem';
import { OrderText } from '@components/selling/pos/orders-list/OrderText';
import { formatToCurrency } from '@helpers/formatter';
import { getCurrency } from '@helpers/getCurrency';
import { useOrderData } from '@hooks/pos/useOrderData';
import { CustomSpace } from '@ui/space';
import { Row, Typography } from 'antd';
import { FC } from 'react';

type Props = {
  orderId?: Maybe<string> | null;
  isDiscountEnabled?: boolean;
  isTaxEnabled?: boolean;
  isTipEnabled?: boolean;
  invoiceSignature?: boolean;
};
export const CountInfo: FC<Props> = ({
  orderId,
  isDiscountEnabled,
  isTaxEnabled,
  isTipEnabled,
  invoiceSignature,
}) => {
  const { order, orderType } = useOrderData(orderId);

  const currency = getCurrency(orderType);

  return (
    <Row
      gutter={24}
      justify={invoiceSignature ? 'space-between' : 'end'}
      style={{
        padding: '20px 0',
        margin: 0,
        width: '100%',
      }}
    >
      {invoiceSignature && (
        <Row align={'bottom'}>
          <Typography.Text style={{ color: '#626670' }}>
            Signature
          </Typography.Text>
          <Typography.Text
            style={{
              marginLeft: 13,
              color: '#CCCCCC',
            }}
          >
            ____________________________
          </Typography.Text>
        </Row>
      )}
      <Row align={'stretch'} style={{ gap: 12, width: '55%' }}>
        <OrderSummaryItem
          name={'Sub Total'}
          amount={order?.attributes?.subTotal}
          currency={currency}
        />
        {isDiscountEnabled && (
          <OrderSummaryItem
            name={'Discount'}
            amount={order?.attributes?.discount}
            currency={currency}
          />
        )}
        {isTaxEnabled && (
          <OrderSummaryItem
            name={'Sales Tax'}
            amount={order?.attributes?.tax}
            currency={currency}
          />
        )}
        {isTipEnabled && (
          <>
            <OrderSummaryItem
              name={'Pre Tip Total'}
              amount={
                Number(order?.attributes?.total) -
                Number(order?.attributes?.tip)
              }
              currency={currency}
            />
            <OrderSummaryItem
              name={'Tip'}
              amount={order?.attributes?.tip}
              currency={currency}
            />
          </>
        )}
        <CustomSpace block style={{ justifyContent: 'space-between' }}>
          <OrderText
            text={'Total'}
            orderTextStyle={{ fontSize: 14, fontWeight: 500 }}
          />
          <OrderText
            text={formatToCurrency(order?.attributes?.total, currency)}
            orderTextStyle={{
              maxWidth: '100px',
              color: '#0146C5',
              fontWeight: 700,
            }}
          />
        </CustomSpace>
      </Row>
    </Row>
  );
};
