import { useStatusMessage } from '@/components/app/StatusMessageContext/statusMessageContext';
import { useUpdateTenant } from '@/components/onboarding/hooks/useUpdateTenant';
import ModuleTitle from '@/components/ui/module-title';
import { CustomSpace } from '@/components/ui/space';
import {
  useCreateClearentOnboardingMutation,
  useDeleteClearentOnboardingMutation,
  useMeQuery,
  useUpdateClearentOnboardingMutation,
} from '@/graphql';
import { useRouteTenant } from '@/hooks/useRouteTenant';
import { CustomButton } from '@ui/button/Button';
import { Col, Flex, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useEffect, useState } from 'react';

interface Props {
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  stripeOnboardingData?: GetStripeOnboardingDetailsQuery;
}
export const OnBoardingClearentModel: FC<Props> = ({
  isEdit,
  setIsEdit,
  stripeOnboardingData,
}) => {
  const { data: me, refetch, loading } = useMeQuery();
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const message = useStatusMessage();
  const [deleteClearentOnboarding] = useDeleteClearentOnboardingMutation();
  const tenantSlug = useRouteTenant();
  const { handleUpdateTenant } = useUpdateTenant(tenantSlug as string);

  const existingData =
    me?.me?.attributes?.tenant?.data?.attributes?.clearent_onboarding?.data
      ?.attributes;
  const clearentId =
    me?.me?.attributes?.tenant?.data?.attributes?.clearent_onboarding?.data?.id;
  const ClearentData =
    me?.me?.attributes?.tenant?.data?.attributes?.clearent_onboarding?.data;

  useEffect(() => {
    form.resetFields();
    if (isEdit && existingData) {
      form.setFieldsValue({
        hppKey: existingData.hppKey,
        merchantId: existingData.merchantId,
        terminalId: existingData.terminalId,
      }),
        refetch();
    }
  }, [isEdit, existingData, form, refetch]);

  const handleModalClose = () => {
    form.resetFields();
    setOpen(false);
    setIsEdit(false);
  };

  const [addOnboarding] = useCreateClearentOnboardingMutation({
    onCompleted: (data) => {
      if (data) {
        message.open('success', 'Onboarding details saved successfully!');
        handleModalClose();
        if (!stripeOnboardingData?.getStripeOnboardingDetails?.accountStatus) {
          handleUpdateTenant({ paymentGatewayType: 'clearent' });
        }
        refetch();
      } else {
        message.open('error', 'Failed to save onboarding details.');
      }
    },
    onError: () => {
      message.open(
        'error',
        'An error occurred while saving onboarding details.',
      );
    },
  });

  const [updateOnboarding] = useUpdateClearentOnboardingMutation({
    onCompleted: (updateData: any) => {
      if (updateData) {
        message.open('success', 'Onboarding details updated successfully!');
        if (!stripeOnboardingData?.getStripeOnboardingDetails?.accountStatus) {
          handleUpdateTenant({ paymentGatewayType: 'clearent' });
        }
        handleModalClose();
      } else {
        message.open('error', 'Failed to save onboarding details.');
      }
    },
    onError: () => {
      message.open(
        'error',
        'An error occurred while saving onboarding details.',
      );
    },
  });

  const handleAddCredits = () => {
    setOpen(true);
  };

  const onFinish = (values: any) => {
    {
      isEdit
        ? updateOnboarding({
            variables: {
              input: {
                hppKey: values.hppKey,
                merchantId: values.merchantId,
                terminalId: values.terminalId,
              },
              id: clearentId as string,
            },
          })
        : addOnboarding({
            variables: {
              input: {
                hppKey: values.hppKey,
                merchantId: values.merchantId,
                terminalId: values.terminalId,
              },
            },
          });
    }
  };

  const clearentDisconnectHandler = async () => {
    const updatePaymentGateway = stripeOnboardingData
      ?.getStripeOnboardingDetails?.accountStatus
      ? 'stripe'
      : null;
    handleUpdateTenant({ paymentGatewayType: updatePaymentGateway });
    deleteClearentOnboarding({
      variables: {
        id: clearentId as string,
      },
    });
  };

  return (
    <>
      {!ClearentData ? (
        <CustomButton
          type={'default'}
          size={'large'}
          style={{ width: '100%' }}
          onClick={handleAddCredits}
        >
          Onboarding
        </CustomButton>
      ) : (
        <CustomButton
          type={'default'}
          size={'large'}
          style={{ width: '100%' }}
          onClick={clearentDisconnectHandler}
        >
          Disconnect
        </CustomButton>
      )}

      <Modal
        open={open || isEdit}
        onCancel={handleModalClose}
        footer={null}
        closeIcon
      >
        <ModuleTitle
          titleLevel={2}
          title={` ${isEdit ? 'Edit' : 'Add'} Your Key`}
        />
        {loading ? (
          <></>
        ) : (
          <Col span={24}>
            <Form
              form={form}
              layout={'vertical'}
              autoComplete={'off'}
              onFinish={onFinish}
            >
              <Row gutter={12}>
                <Col span={24} md={24}>
                  <Form.Item
                    required
                    label={'HPP API Key'}
                    name={'hppKey'}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your HPP API Key!',
                      },
                    ]}
                  >
                    <Input placeholder={'HPP API Key'} />
                  </Form.Item>
                  <Form.Item
                    required
                    label={'Merchant ID (MID)'}
                    name={'merchantId'}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Merchant ID!',
                      },
                    ]}
                  >
                    <Input placeholder={'Merchant ID (MID)'} />
                  </Form.Item>
                  <Form.Item
                    required
                    label={'Terminal ID (TID)'}
                    name={'terminalId'}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Terminal ID!',
                      },
                    ]}
                  >
                    <Input placeholder={'Terminal ID (TID)'} />
                  </Form.Item>
                </Col>
              </Row>

              <Flex vertical gap={10}>
                <CustomSpace block style={{ justifyContent: 'flex-end' }}>
                  <>
                    <CustomButton
                      onClick={handleModalClose}
                      size={'large'}
                      style={{ width: 100 }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      htmlType={'submit'}
                      size={'large'}
                      type={'primary'}
                    >
                      Submit
                    </CustomButton>
                  </>
                </CustomSpace>
              </Flex>
            </Form>
          </Col>
        )}
      </Modal>
    </>
  );
};
