import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { Loader } from '@components/layout/MainLayout';

import { ROUTES } from '@router/routes';

const paymentPage = lazy<FC>(() => import('@/pages/payment/pay'));
const paymentSuccess = lazy<FC>(() => import('@/pages/payment/paymentSuccess'));
const paymentError = lazy<FC>(() => import('@/pages/payment/paymentError'));
const paymentPending = lazy<FC>(() => import('@/pages/payment/paymentPending'));
const stripeSubscription = lazy<FC>(
  () => import('@/pages/payment/stripeSubscription'),
);

const { payment } = ROUTES.global;

const paymentRoutes: RouteObject = {
  path: payment.index,
  children: [
    {
      loader: () => <Loader spinning />,
      children: [
        {
          Component: paymentPage,
          path: 'pay',
          index: true,
        },
        {
          Component: paymentSuccess,
          path: 'success',
        },
        {
          Component: paymentError,
          path: 'error',
        },
        {
          Component: paymentPending,
          path: 'pending',
        },
        {
          Component: stripeSubscription,
          path: 'stripe-subscription',
        },
      ],
    },
  ],
};

export default paymentRoutes;
