import { Col, ConfigProvider, Row, Typography } from 'antd';
import { FC } from 'react';

type Props = {
  locationName: string;
  locationAddress: string;
  locationPhone: string;
  locationEmail: string;
  companyLogo?: Maybe<string>;
};

export const RepairTicketBusinessContacts: FC<Props> = ({
  locationName,
  locationAddress,
  locationPhone,
  locationEmail,
  companyLogo,
}) => {
  return (
    <Row justify={'space-between'} style={{ minHeight: 60 }}>
      <Col span={18}>
        <ConfigProvider
          theme={{
            token: {
              fontSize: 12,
            },
          }}
        >
          <Row>
            <Col span={24}>
              <Typography.Text>{locationName}</Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>{locationAddress}</Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>{locationPhone}</Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>{locationEmail}</Typography.Text>
            </Col>
          </Row>
        </ConfigProvider>
      </Col>
      <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {companyLogo && (
          <img
            src={companyLogo}
            alt={'Logo'}
            width={36}
            height={36}
            style={{ objectFit: 'contain' }}
          />
        )}
      </Col>
    </Row>
  );
};
