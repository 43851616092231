import { useAllOrderItemsQuery, useOrdersQuery } from '@/graphql';
import { useEffect, useMemo } from 'react';

const filterByVisibility = (item: any, useItemVisibility: boolean) => {
  return useItemVisibility ? item?.attributes?.isVisibleInDocs !== false : true;
};

export function useOrderData(id: Maybe<string>, useItemVisibility = false) {
  const { data, loading: orderLoading } = useOrdersQuery({
    variables: {
      filters: {
        id: { eq: String(id) },
      },
    },
  });

  const orderFilter = useMemo(
    () => ({
      order: { id: { eq: String(id) } },
    }),
    [id],
  );

  const {
    data: allItemsData,
    loading: loadingAllItemsData,
    refetch: refetchAllItemsData,
  } = useAllOrderItemsQuery({
    variables: {
      productFilters: orderFilter,
      serviceFilters: orderFilter,
      compositeProductFilters: orderFilter,
      classFilters: orderFilter,
      membershipFilters: orderFilter,
      pagination: { limit: -1 },
    },
  });

  useEffect(() => {
    if (String(id)) {
      refetchAllItemsData();
    }
  }, [id, orderFilter, refetchAllItemsData]);

  const order = data?.orders?.data?.[0];

  const orderUuid = order?.attributes?.orderId;
  const orderContact = order?.attributes?.contact?.data as ContactMinFragment;
  const orderCompany = order?.attributes?.company?.data as CompanyMinFragment;
  const orderEmployee = order?.attributes?.sales?.data as UserFragment;
  const orderFiles = order?.attributes?.files?.data;
  const orderCreationDate =
    order?.attributes?.getCreateDate ?? order?.attributes?.createdAt;
  const orderDueDate = order?.attributes?.dueDate;
  const orderBusinessLocation = order?.attributes?.businessLocation
    ?.data as BusinessLocationFragment;
  const orderType = order?.attributes?.type as EnumOrderType;
  const orderNote = order?.attributes?.note;

  const orderProductsBuy = useMemo(
    () =>
      allItemsData?.productOrderItems?.data?.filter(
        (item) =>
          item?.attributes?.purchaseType === 'buy' &&
          filterByVisibility(item, useItemVisibility),
      ) ?? [],
    [allItemsData, useItemVisibility],
  );

  const orderProductsRent = useMemo(
    () =>
      allItemsData?.serviceOrderItems?.data?.filter(
        (item) =>
          item?.attributes?.purchaseType === 'rent' &&
          filterByVisibility(item, useItemVisibility),
      ) ?? [],
    [allItemsData, useItemVisibility],
  );

  const orderCompositeProducts = useMemo(
    () =>
      allItemsData?.compositeProductOrderItems?.data?.filter((item) =>
        filterByVisibility(item, useItemVisibility),
      ) ?? [],
    [allItemsData, useItemVisibility],
  );

  const orderClasses = useMemo(
    () =>
      allItemsData?.classOrderItems?.data?.filter((item) =>
        filterByVisibility(item, useItemVisibility),
      ) ?? [],
    [allItemsData, useItemVisibility],
  );

  const orderServices = useMemo(
    () =>
      allItemsData?.serviceOrderItems?.data?.filter((item) =>
        filterByVisibility(item, useItemVisibility),
      ) ?? [],
    [allItemsData, useItemVisibility],
  );

  const orderMemberships = useMemo(
    () =>
      allItemsData?.membershipOrderItems?.data?.filter((item) =>
        filterByVisibility(item, useItemVisibility),
      ) ?? [],
    [allItemsData, useItemVisibility],
  );

  const hasOrderItemsBought = useMemo(
    () =>
      [
        orderProductsBuy,
        orderCompositeProducts,
        orderServices,
        orderClasses,
        orderMemberships,
      ].some((items) => items.length > 0),
    [
      orderProductsBuy,
      orderCompositeProducts,
      orderServices,
      orderClasses,
      orderMemberships,
    ],
  );

  return {
    order,
    orderUuid,
    orderContact,
    orderCompany,
    orderProductsBuy,
    orderClasses,
    orderProductsRent,
    orderServices,
    orderMemberships,
    orderCompositeProducts,
    orderLoading: orderLoading || loadingAllItemsData,
    hasOrderItemsBought,
    orderType,
    orderBusinessLocation,
    orderCreationDate,
    orderDueDate,
    orderEmployee,
    orderFiles,
    orderNote,
  };
}
