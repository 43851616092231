import { useStatusMessage } from '@/components/app/StatusMessageContext/statusMessageContext';
import { useUpdateTenant } from '@/components/onboarding/hooks/useUpdateTenant';
import { CustomButton } from '@/components/ui/button/Button';
import { CustomCard } from '@/components/ui/card/CustomCard';
import Checkbox from '@/components/ui/checkbox';
import ModuleTitle from '@/components/ui/module-title';
import {
  useGetStripeOnboardingDetailsQuery,
  useMeQuery,
  useStripeOnboardingLinkMutation,
} from '@/graphql';
import { useRouteTenant } from '@/hooks/useRouteTenant';
import { Col, Divider, Flex, Layout, Row } from 'antd';

import { Icon } from '@/assets/icon';
import { FC, useState } from 'react';
import './index.scss';
import { OnBoardingClearentModel } from './onBoardingClearentModel';

const PaymentGatewayIntegration: FC = () => {
  const { data: me } = useMeQuery();
  const message = useStatusMessage();
  const [isEdit, setIsEdit] = useState(false);

  const [createOnboardingLink] = useStripeOnboardingLinkMutation();

  const tenantSlug = useRouteTenant();
  const clearentHppKey =
    me?.me?.attributes?.tenant?.data?.attributes?.clearent_onboarding?.data
      ?.attributes?.hppKey;

  const { loading, initialValues, handleUpdateTenant } = useUpdateTenant(
    tenantSlug as string,
  );

  const { data: stripeOnboardingData } = useGetStripeOnboardingDetailsQuery({
    variables: {
      input: {
        tenantId: me?.me?.attributes?.tenant?.data?.id,
      },
    },
  });

  const stripeOnboardinghandler = () => {
    createOnboardingLink({
      variables: {
        input: {
          tenantId: me?.me?.attributes?.tenant?.data?.id,
        },
      },
      onCompleted: (data) => {
        const stripeOnboardingLink = data?.stripeOnboardingLink?.link;
        if (stripeOnboardingLink) {
          window.open(stripeOnboardingLink, '_blank', 'noopener,noreferrer'); // Open link in new tab
        }
      },
      onError: (error) => {
        message.open('error', error.message);
      },
    });
  };

  const updatePaymentGatewayHandler = async (
    gatewayType: 'stripe' | 'clearent',
  ) => {
    const values = {
      paymentGatewayType: gatewayType,
    };
    await handleUpdateTenant(values);
  };

  const editHandler = () => {
    setIsEdit(true);
  };

  const ClearentData =
    me?.me?.attributes?.tenant?.data?.attributes?.clearent_onboarding?.data;

  return (
    <Layout style={{ padding: '8px 0 0' }}>
      <Flex gap={24} vertical>
        <Flex gap={24} vertical>
          <Row gutter={[18, 18]}>
            <Col span={8}>
              <CustomCard hoverable>
                <Flex justify={'space-between'}>
                  <ModuleTitle titleLevel={3} title={'Stripe'} />
                  {stripeOnboardingData?.getStripeOnboardingDetails
                    ?.accountStatus && (
                    <label className={'circularCheckbox'}>
                      <Checkbox
                        checked={
                          me?.me?.attributes?.tenant?.data?.attributes
                            ?.paymentGatewayType === 'stripe'
                        }
                        onClick={() => updatePaymentGatewayHandler('stripe')}
                      />
                    </label>
                  )}
                </Flex>
                <Divider />

                <Flex gap={8}>
                  <CustomButton
                    type={'primary'}
                    size={'large'}
                    block
                    onClick={stripeOnboardinghandler}
                    disabled={
                      stripeOnboardingData?.getStripeOnboardingDetails
                        ?.accountStatus
                        ? true
                        : false
                    }
                  >
                    Onboarding
                  </CustomButton>
                </Flex>
              </CustomCard>
            </Col>
            <Col span={8}>
              <CustomCard hoverable>
                <Flex justify={'space-between'}>
                  <ModuleTitle titleLevel={3} title={'Clearent'} />
                  <Flex justify={'space-evenly'}>
                    <CustomButton
                      type={'text'}
                      icon={
                        ClearentData !== null ? (
                          <Icon type={'edit'} />
                        ) : undefined
                      }
                      onClick={editHandler}
                      size={'small'}
                      style={{ marginTop: '2px' }}
                    />

                    <label className={'circularCheckbox'}>
                      {ClearentData === null ? (
                        ''
                      ) : (
                        <Checkbox
                          checked={
                            me?.me?.attributes?.tenant?.data?.attributes
                              ?.paymentGatewayType === 'clearent'
                          }
                          onClick={() =>
                            updatePaymentGatewayHandler('clearent')
                          }
                        />
                      )}
                    </label>
                  </Flex>
                </Flex>

                <Divider />

                <Flex gap={8}>
                  <OnBoardingClearentModel
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    stripeOnboardingData={stripeOnboardingData}
                  />
                </Flex>
              </CustomCard>
            </Col>
          </Row>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default PaymentGatewayIntegration;
