import { useState } from 'react';

export const useCustomDrawerToggle = () => {
  const [isOpen, setIsOpen] = useState(false);

  const showDrawer = () => setIsOpen(true);
  const closeDrawer = () => setIsOpen(false);

  return { isOpen, showDrawer, closeDrawer };
};
