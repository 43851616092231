import { AnyObject } from '@helpers/types';

type CustomAttributeOption = {
  productAttributeOptions: {
    id: {
      eq: string;
    };
  };
};

type CustomAttributeOptionsResult = {
  customAttributeOptionFilter: CustomAttributeOption[];
  isCustomAttributeOptionSelected: boolean;
};

const emptyCustomAttributeOptions = {
  customAttributeOptionFilter: [],
  isCustomAttributeOptionSelected: false,
};

export const getCustomAttributeOptionsArray = (
  allFilters: AnyObject | undefined,
  keysToRemove: string[],
): CustomAttributeOptionsResult => {
  if (!allFilters || Object.keys(allFilters).length === 0) {
    return emptyCustomAttributeOptions;
  }

  const customAttributeFilters = Object.fromEntries(
    Object.entries(allFilters).filter(([key]) => !keysToRemove.includes(key)),
  );

  const customAttributeOptionsIds = Object.values(customAttributeFilters)
    .filter((value) => value !== undefined)
    .map((value) => String(value));

  if (!customAttributeOptionsIds || customAttributeOptionsIds.length === 0) {
    return emptyCustomAttributeOptions;
  }

  const customAttributeOptionFilter = customAttributeOptionsIds.map(
    (customAttributeOptionId) => ({
      productAttributeOptions: {
        id: {
          eq: customAttributeOptionId,
        },
      },
    }),
  );

  const isCustomAttributeOptionSelected =
    customAttributeOptionFilter && customAttributeOptionFilter?.length > 0;

  return { customAttributeOptionFilter, isCustomAttributeOptionSelected };
};
