import { IconSize } from '@assets/icon';
import ActionMenuDropdown from '@ui/dropdown/ActionMenuDropdown';
import ActionMenuButton from '@ui/dropdown/ActionMenuDropdown/ActionMenuButton';
import { MenuProps } from 'antd';
import { FC } from 'react';

interface Props {
  onDownload?: () => void;
  onSend?: () => void;
}

export const InvoiceActionDropdownMenuView: FC<Props> = ({
  onDownload,
  onSend,
}) => {
  const items: MenuProps['items'] = [
    {
      key: 'Send Email',
      label: (
        <ActionMenuButton onClick={onSend}>Email PDF copy</ActionMenuButton>
      ),
    },
    {
      key: 'Download',
      label: <ActionMenuButton onClick={onDownload}>Download</ActionMenuButton>,
    },
  ];

  return (
    <>
      <ActionMenuDropdown
        key={'id'}
        iconSize={IconSize.Large}
        type={'options-horizontal'}
        items={items}
      />
    </>
  );
};
