import {
  useCreateFileItemMutation,
  useMeQuery,
  useSendReviewRequestMutation,
  useUpdateInvoiceMutation,
} from '@/graphql';

import { ApolloError } from '@apollo/client';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useSendInvoice } from '@components/contracts/invoices/hooks/useSendInvoice';
import { useUploadFile } from '@components/uploadFile/hooks/useUploadFile';
import { handleApplicationError } from '@helpers/errors';
import { useDeleteFileAndFileItem } from '@hooks/files/useDeleteFileAndFileItem';
import { useDownloadRecord } from '@hooks/files/useDownloadRecord';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import React, { MutableRefObject } from 'react';
import generatePDF from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';

type InvoiceActionsReturnType = {
  handlePrint: (
    event?: unknown,
    content?: () => React.ReactInstance | null,
  ) => void;
  handleDownload: (
    fileId: string,
    fileItemId: string,
    customerName?: string,
  ) => Promise<void>;
  handleUploadInvoiceFile: (objectProps: {
    fileId: string;
    fileItemId: string;
    subjectEmail: string;
    contactId: string;
    customerName?: string;
    subjectPhone?: string;
    customSmsContactContent?: string;
    customSmsSubjectContent?: string;
    sendBySms?: boolean;
    sendByEmail?: boolean;
  }) => Promise<void>;
  handleSendReviewRequest: (objectProps: {
    subjectEmail: string;
    contactId: string;
    customerName?: string;
    subjectPhone?: string;
    customSmsContactContent?: string;
    customSmsSubjectContent?: string;
    sendBySms?: boolean;
    sendByEmail?: boolean;
  }) => Promise<void>;
};

export function useInvoiceActions(
  ref: MutableRefObject<null | HTMLElement>,
  id: string,
  fileType = 'invoice',
): InvoiceActionsReturnType {
  const getFileName = (prefix?: string) => {
    return `${capitalize(fileType)}_${prefix ? `${prefix}_` : ''}${dayjs(
      new Date(),
    ).format('DD_MM_YY')}.pdf`;
  };
  const message = useStatusMessage();
  const { data } = useMeQuery();
  const { handleDeleteFileAndFileItem } = useDeleteFileAndFileItem();
  const { createRecord, removeRecord } = useDownloadRecord();
  const { handleSendInvoice } = useSendInvoice();

  const [sendReview] = useSendReviewRequestMutation({
    onCompleted: (data: SendReviewRequestMutation): void => {
      if (data?.sendReviewRequest?.errors?.length) {
        data.sendReviewRequest.errors.forEach((error) => {
          error && message.open('error', error);
        });
        message.open('success', 'Review request completed with some errors');
      } else if (
        data?.sendReviewRequest?.success &&
        data?.sendReviewRequest?.errors?.length === 0
      ) {
        message.open('success', 'Review request sent successfully');
      }
    },
    onError: (error: ApolloError) => {
      handleApplicationError(error, message);
    },
  });

  const [updateInvoice] = useUpdateInvoiceMutation();

  const [createFile] = useCreateFileItemMutation({
    refetchQueries: ['fileItems'],
  });

  const { handleUpload } = useUploadFile();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleDownload = async (
    fileId: string,
    fileItemId: string,
    customerName?: string,
  ) => {
    await generatePDF(ref, { filename: getFileName(), method: 'save' });

    if (fileId || fileItemId) {
      await handleDeleteFileAndFileItem(fileId, fileItemId);
      await removeRecord(fileId);
    }

    const fileName = getFileName(customerName);
    const jsPdfFile = await generatePDF(ref, {
      method: 'build',
    });

    const fileBlob = jsPdfFile.output('blob');

    const file = new File([fileBlob], fileName, {
      type: 'application/pdf',
    });

    await createFile({
      variables: {
        input: {
          name: file.name,
          type: 'generated',
          uploadedBy: data?.me?.id,
          isFavourite: false,
        },
      },
      onCompleted: async (resultItem) => {
        if (resultItem?.createFileItem?.data?.id) {
          await handleUpload(file, {
            ref: 'api::file-item.file-item',
            refId: resultItem?.createFileItem?.data?.id,
            field: 'attachedFile',
          });

          await updateInvoice({
            variables: {
              id: id,
              input: {
                fileItem: resultItem?.createFileItem?.data?.id,
              },
            },
          });

          await createRecord(
            data?.me?.id as string,
            resultItem?.createFileItem?.data?.id,
          );
        }
      },
    });
  };

  const handleSendReviewRequest = async (objectProps: {
    subjectEmail: string;
    contactId: string;
    customerName?: string;
    subjectPhone?: string;
    customSmsContactContent?: string;
    customSmsSubjectContent?: string;
    sendBySms?: boolean;
    sendByEmail?: boolean;
  }) => {
    const {
      subjectEmail,
      contactId,
      customSmsSubjectContent,
      customSmsContactContent,
      sendBySms,
      sendByEmail,
      subjectPhone,
    } = objectProps;

    await sendReview({
      variables: {
        orderId: id,
        email: subjectEmail,
        phone: subjectPhone,
        contactId,
        customSmsSubjectContent,
        customSmsContactContent,
        sendBySms,
        sendByEmail,
      },
    });
  };

  const handleUploadInvoiceFile = async (objectProps: {
    fileId: string;
    fileItemId: string;
    subjectEmail: string;
    contactId: string;
    customerName?: string;
    subjectPhone?: string;
    customSmsContactContent?: string;
    customSmsSubjectContent?: string;
    sendBySms?: boolean;
    sendByEmail?: boolean;
  }) => {
    const {
      subjectEmail,
      fileItemId,
      fileId,
      customerName,
      contactId,
      customSmsSubjectContent,
      customSmsContactContent,
      sendBySms,
      sendByEmail,
      subjectPhone,
    } = objectProps;

    if (fileId || fileItemId) {
      await handleDeleteFileAndFileItem(fileId, fileItemId);
    }

    const fileName = getFileName(customerName);
    const jsPdfFile = await generatePDF(ref, {
      method: 'build',
    });

    const fileBlob = jsPdfFile.output('blob');

    const file = new File([fileBlob], fileName, {
      type: 'application/pdf',
    });

    await createFile({
      variables: {
        input: {
          name: file.name,
          type: 'generated',
          uploadedBy: data?.me?.id,
          isFavourite: false,
        },
      },
      onCompleted: async (resultItem) => {
        if (resultItem?.createFileItem?.data?.id) {
          await handleUpload(file, {
            ref: 'api::file-item.file-item',
            refId: resultItem?.createFileItem?.data?.id,
            field: 'attachedFile',
          });

          await updateInvoice({
            variables: {
              id: id,
              input: {
                fileItem: resultItem?.createFileItem?.data?.id,
              },
            },
          });

          await handleSendInvoice({
            id,
            subjectEmail,
            contactId,
            fileType,
            subjectPhone,
            sendByEmail,
            sendBySms,
            customSmsContactContent,
            customSmsSubjectContent,
          });
        }
      },
    });
  };

  return {
    handlePrint,
    handleDownload,
    handleUploadInvoiceFile,
    handleSendReviewRequest,
  };
}
