export const getPurchaseRequestInfo = (data: OrderByIdQuery | undefined) => {
  const order = data?.orders?.data?.[0];
  const purchaseRequest = order?.attributes?.purchaseRequest;
  const hasPurchaseRequest = !!purchaseRequest?.data?.attributes?.requestId;
  const attachedPurchaseFileId =
    purchaseRequest?.data?.attributes?.fileItem?.data?.attributes?.attachedFile
      ?.data?.id;
  const filePurchaseItemId =
    purchaseRequest?.data?.attributes?.fileItem?.data?.id;

  return {
    order,
    purchaseRequest,
    hasPurchaseRequest,
    attachedPurchaseFileId,
    filePurchaseItemId,
  };
};
