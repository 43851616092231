import { FC, useCallback, useRef } from 'react';

import { Card, Flex, Space, Typography } from 'antd';

import { UniqueIdentifier } from '@dnd-kit/core';

import { useOrderByIdQuery } from '@/graphql';

import { UploadFilesComponent } from '@components/selling/order-management/OrderForm/UploadFile';
import { OrderSummaryItem } from '@components/selling/pos/orders-list/OrderSummaryItem';
import { transformGqlFileToAntd } from '@components/uploadFile/helpers';

import { Icon } from '@assets/icon';
import { Loader } from '@components/layout/MainLayout';
import { OrderNote } from '@components/selling/components/OrderNote';
import { useCreateInvoice } from '@components/selling/hooks/useCreateInvoice';
import OrderFormInfoCollapsed from '@components/selling/order-management/OrderForm/OrderFormInfoCollapsed';
import { OrderFormItem } from '@components/selling/order-management/OrderForm/OrderFormItem';
import { OrderFilesPrintComponent } from '@components/selling/order-management/OrderForm/UploadFile/OrderFilesPrintComponent';
import { useDrawer } from '@drawer/drawerContext';
import { getCurrency } from '@helpers/getCurrency';
import { useToken } from '@hooks/useToken';
import { usePlatformRoutes } from '@router/routes';
import AvatarInfo from '@ui/avatar/AvatarInfo';
import { CustomButton } from '@ui/button/Button';
import { ComponentMask } from '@ui/componentMask/ComponentMask';
import { CustomDivider } from '@ui/divider/Divider';
import { get } from 'lodash';
import { useNavigate } from 'react-router';
import { useReactToPrint } from 'react-to-print';

interface Props {
  orderId: UniqueIdentifier;
  orderUuid: string;
}

export const OrderForm: FC<Props> = ({ orderId, orderUuid }) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const navigate = useNavigate();
  const {
    contracts: { purchase, invoices },
  } = usePlatformRoutes();
  const { closeDrawer } = useDrawer();
  const { token } = useToken();
  const { data, loading: loadingOrder } = useOrderByIdQuery({
    variables: { orderId: orderUuid as string },
  });
  const { create: createInvoice, loading } = useCreateInvoice(
    orderId as string,
  );

  const order = data?.orders?.data?.[0];
  const orderType = data?.orders?.data?.[0]?.attributes?.type;
  const hasDocument =
    order?.attributes?.invoice?.data?.attributes?.invoiceId ||
    order?.attributes?.purchaseRequest?.data?.attributes?.requestId;
  const orderFiles = get(order, 'attributes.files.data', []);

  const handleGoToDocument = useCallback(() => {
    orderType === 'purchase'
      ? navigate(
          `${purchase.preview}/${order?.attributes?.purchaseRequest?.data?.attributes?.uuid}`,
        )
      : navigate(
          `${invoices.index}/${order?.attributes?.invoice?.data?.attributes?.uuid}`,
        );
    closeDrawer();
  }, [
    navigate,
    closeDrawer,
    order?.attributes?.invoice?.data?.attributes?.uuid,
    order?.attributes?.purchaseRequest?.data?.attributes?.uuid,
    invoices.index,
    purchase.preview,
    orderType,
  ]);

  const currency = getCurrency(orderType);

  return (
    <div
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: '100%',
        paddingBottom: '20px',
        paddingRight: '20px',
        padding: '32px',
      }}
    >
      <Card
        bordered={false}
        style={{ boxShadow: 'none' }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {loadingOrder ? (
          <Loader />
        ) : (
          <>
            <OrderFormInfoCollapsed data={order} />
            <CustomDivider margin={0} />
            <Flex vertical gap={0}>
              <OrderFormItem
                title={
                  order?.attributes?.businessLocation?.data?.attributes?.name ??
                  'Location'
                }
              >
                <Typography.Text style={{ color: token.colorTextHeading }}>
                  <Space size={5}>
                    <Icon type={'map'} />
                    {
                      order?.attributes?.businessLocation?.data?.attributes
                        ?.location?.data?.attributes?.address
                    }
                  </Space>
                </Typography.Text>
              </OrderFormItem>
              <OrderFormItem title={'Note'}>
                <OrderNote orderData={order} />
              </OrderFormItem>
              <OrderFormItem title={'Staff Member'}>
                <AvatarInfo
                  title={
                    order?.attributes?.sales?.data?.attributes?.fullName ?? ''
                  }
                  description={
                    order?.attributes?.sales?.data?.attributes?.jobTitle
                  }
                  src={
                    order?.attributes?.sales?.data?.attributes?.avatar?.data
                      ?.attributes?.url
                  }
                />
              </OrderFormItem>
              <OrderFormItem title={'Attached files'}>
                <ComponentMask>
                  <OrderFilesPrintComponent
                    ref={componentRef}
                    files={orderFiles.map(transformGqlFileToAntd) || undefined}
                  />
                </ComponentMask>
                <CustomButton
                  type={'primary'}
                  style={{ position: 'absolute', right: 0 }}
                  onClick={handlePrint}
                  disabled={!orderFiles?.length}
                >
                  Print Attached Files
                </CustomButton>
                <UploadFilesComponent
                  files={orderFiles.map(transformGqlFileToAntd) || undefined}
                  orderId={orderId as string}
                />
              </OrderFormItem>
              <Flex gap={16} vertical style={{ margin: '16px 0 48px' }}>
                <OrderSummaryItem
                  name={'Sub Total'}
                  amount={order?.attributes?.subTotal}
                  currency={currency}
                />
                <OrderSummaryItem
                  name={'Discount'}
                  amount={order?.attributes?.discount}
                  currency={currency}
                />
                <OrderSummaryItem
                  name={'Tax'}
                  amount={order?.attributes?.tax}
                  currency={currency}
                />
                <OrderSummaryItem
                  name={'Pre Tip Total'}
                  amount={
                    Number(order?.attributes?.total) -
                    Number(order?.attributes?.tip)
                  }
                  currency={currency}
                />
                <OrderSummaryItem
                  name={'Tip'}
                  amount={order?.attributes?.tip}
                  currency={currency}
                />
                <OrderSummaryItem
                  name={'Total'}
                  amount={order?.attributes?.total}
                  isTotal={true}
                  currency={currency}
                />
              </Flex>
              {!(orderType === 'purchase' && !hasDocument) && (
                <CustomButton
                  type={'primary'}
                  onClick={hasDocument ? handleGoToDocument : createInvoice}
                  size={'large'}
                  loading={loading}
                  block
                >
                  {loading
                    ? 'Creating'
                    : hasDocument
                    ? `Go To ${
                        orderType === 'purchase'
                          ? 'Purchase Request'
                          : 'Invoice'
                      }`
                    : 'Create Invoice'}
                </CustomButton>
              )}
            </Flex>
          </>
        )}
      </Card>
    </div>
  );
};
