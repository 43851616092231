import {
  useDeleteFileItemMutation,
  useDeleteUploadFileMutation,
} from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

export function useDeleteFileAndFileItem(): {
  handleDeleteFileAndFileItem: (
    fileId: string,
    fileItemId: string,
  ) => Promise<void>;
} {
  const message = useStatusMessage();
  const [deleteFile] = useDeleteFileItemMutation({
    onError: () => {
      message.open('error');
    },
  });
  const [deleteUploadFile] = useDeleteUploadFileMutation({
    onError: () => {
      message.open('error');
    },
  });

  const handleDeleteFileAndFileItem = async (
    fileId: string,
    fileItemId: string,
  ) => {
    await deleteUploadFile({
      variables: {
        id: fileId as string,
      },
    });
    await deleteFile({
      variables: {
        id: fileItemId as string,
      },
    });
  };

  return {
    handleDeleteFileAndFileItem,
  };
}
