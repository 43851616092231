import {
  getFirstNameCode,
  getLastNameCode,
} from '@components/contracts/purchaseRequests/helpers/name';

export const getCustomerRepairTicketData = (
  orderContact: ContactMinFragment | undefined,
  orderCompany: CompanyMinFragment | undefined,
) => {
  const orderCustomerName =
    orderContact?.attributes?.fullName ?? orderCompany?.attributes?.name ?? '';

  const orderCustomerCode = orderContact?.attributes?.fullName
    ? getLastNameCode(orderContact.attributes.fullName)
    : orderCompany?.attributes?.name
    ? getFirstNameCode(orderCompany.attributes.name)
    : '';

  const orderCustomerPhone =
    orderContact?.attributes?.phoneNumber ??
    orderCompany?.attributes?.phoneNumber ??
    '';

  const orderCustomerEmail =
    orderContact?.attributes?.email ?? orderCompany?.attributes?.email ?? '';

  return {
    orderCustomerName,
    orderCustomerCode,
    orderCustomerPhone,
    orderCustomerEmail,
  };
};
