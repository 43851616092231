export const getEntityName = (
  entityType: 'request' | 'invoice' | 'repair ticket' | 'estimate',
): string | null => {
  if (!entityType) return null;

  switch (entityType) {
    case 'request':
      return 'Purchase Request';
    case 'estimate':
      return 'Estimate';
    case 'repair ticket':
      return 'Repair Ticket';
    case 'invoice':
      return 'Invoice';
    default:
      return '';
  }
};
