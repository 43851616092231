import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import { Dayjs } from 'dayjs';

export const createProductCreatedAtFilter = (
  createdAt?: [null | Dayjs, null | Dayjs] | null,
): FormFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};

export const createStoneAndProductFilter: FilterFunction<
  StoneFiltersInput &
    StoneColorFiltersInput &
    WeightFiltersInput &
    ProductFiltersInput
> = ({
  conditionType,
  genderType,
  process,
  metalFinishType,
  platting,
  metalType,
  materialGradeType,
  engravingType,
  timePeriod,
  productType,
  SKU,
  specificType,
  size,
  shankStyle,
  designStyle,
  backing,
  strand,
  strandsLength,
  knotStyle,
  linkStyle,
  linkType,
  brand,
  country,
  boxPaper,
  pieces,
  type,
  stoneType,
  transparency,
  intensity,
  geographic,
  stoneShape,
  origin,
  tone,
  fluorescenceLong,
  fluorescenceShort,
  fluorescenceLongHue,
  fluorescenceShortHue,
  clarity,
  clarityType,
  cut,
  girdleThickness,
  girdleFinish,
  treatment,
  polish,
  gradingReportBy,
  colorEnhancement,
  clarityEnhancement,
  cutStyleCrown,
  cutStylePavilion,
  weight,
  color,
  symmetry,
} = {}) => {
  const isStoneFilter = Boolean(
    cut || type || polish || stoneShape || symmetry || weight || color,
  );

  const isProductFilters = Boolean(
    SKU ||
      conditionType ||
      genderType ||
      process ||
      metalFinishType ||
      platting ||
      metalType ||
      engravingType ||
      timePeriod ||
      productType,
  );

  const weightFilter = {
    ...(weight?.value && {
      weight: {
        value: {
          between: weight.value,
        },
        unit: {
          eq: weight.unit,
        },
      },
    }),
  };

  const colorFilter = {
    ...(color && {
      color: {
        ...(color.diamondColor && {
          diamondColor: {
            in: color.diamondColor,
          },
        }),
        ...(color.colorIntensity && {
          colorIntensity: {
            id: {
              in: color.colorIntensity,
            },
          },
        }),
        ...(color.colorIntensityTo && {
          colorIntensityTo: {
            id: {
              in: color.colorIntensityTo,
            },
          },
        }),
        ...(color.colorSecondary && {
          colorSecondary: {
            id: {
              in: color.colorSecondary,
            },
          },
        }),
        ...(color.colorSecondaryTo && {
          colorSecondaryTo: {
            id: {
              in: color.colorSecondaryTo,
            },
          },
        }),
        ...(color.colorDominant && {
          colorDominant: {
            id: {
              in: color.colorDominant,
            },
          },
        }),
        ...(color.colorDominantTo && {
          colorDominantTo: {
            id: {
              in: color.colorDominantTo,
            },
          },
        }),
      },
    }),
  };

  const stoneFilter = isStoneFilter
    ? {
        ...(cut && {
          cut: {
            id: {
              in: cut,
            },
          },
        }),
        ...colorFilter,
        ...weightFilter,
        ...(symmetry && {
          symmetry: {
            id: {
              in: symmetry,
            },
          },
        }),
        ...(clarity && {
          clarity: {
            id: {
              in: clarity,
            },
          },
        }),
        ...(cutStylePavilion && {
          cutStylePavilion: {
            id: {
              in: cutStylePavilion,
            },
          },
        }),
        ...(cutStyleCrown && {
          cutStyleCrown: {
            id: {
              in: cutStyleCrown,
            },
          },
        }),
        ...(clarityEnhancement && {
          clarityEnhancement: {
            id: {
              in: clarityEnhancement,
            },
          },
        }),
        ...(colorEnhancement && {
          colorEnhancement: {
            id: {
              in: colorEnhancement,
            },
          },
        }),
        ...(gradingReportBy && {
          gradingReportBy: {
            id: {
              in: gradingReportBy,
            },
          },
        }),
        ...(treatment && {
          treatment: {
            id: {
              in: treatment,
            },
          },
        }),
        ...(girdleFinish && {
          girdleFinish: {
            id: {
              in: girdleFinish,
            },
          },
        }),
        ...(girdleThickness && {
          girdleThickness: {
            id: {
              in: girdleThickness,
            },
          },
        }),
        ...(clarityType && {
          clarityType: {
            id: {
              in: clarityType,
            },
          },
        }),
        ...(tone && {
          tone: {
            id: {
              in: tone,
            },
          },
        }),
        ...(fluorescenceLongHue && {
          fluorescenceLongHue: {
            id: {
              in: fluorescenceLongHue,
            },
          },
        }),
        ...(fluorescenceShortHue && {
          fluorescenceShortHue: {
            id: {
              in: fluorescenceShortHue,
            },
          },
        }),
        ...(stoneShape && {
          stoneShape: {
            id: {
              in: stoneShape,
            },
          },
        }),
        ...(transparency && {
          transparency: {
            id: {
              in: transparency,
            },
          },
        }),
        ...(geographic && {
          geographic: {
            id: {
              in: geographic,
            },
          },
        }),
        ...(origin && {
          origin: {
            id: {
              in: origin,
            },
          },
        }),
        ...(polish && {
          polish: {
            id: {
              in: polish,
            },
          },
        }),
        ...(intensity && {
          intensity: {
            id: {
              in: intensity,
            },
          },
        }),
        ...(fluorescenceLong && {
          fluorescenceLong: {
            id: {
              in: fluorescenceLong,
            },
          },
        }),
        ...(fluorescenceShort && {
          fluorescenceShort: {
            id: {
              in: fluorescenceShort,
            },
          },
        }),
        ...(type && {
          type: {
            eq: type,
          },
        }),
        ...(stoneType && {
          stoneType: {
            id: {
              in: stoneType,
            },
          },
        }),
      }
    : {};

  const productFilter =
    isProductFilters || isStoneFilter
      ? {
          ...(conditionType && {
            conditionType: {
              id: {
                in: conditionType,
              },
            },
          }),
          ...(genderType && {
            genderType: {
              id: {
                in: genderType,
              },
            },
          }),
          ...(SKU && {
            SKU: {
              containsi: SKU,
            },
          }),
          ...(process && {
            process: {
              id: {
                in: process,
              },
            },
          }),
          ...(metalFinishType && {
            metalFinishType: {
              id: {
                in: metalFinishType,
              },
            },
          }),
          ...(platting && {
            platting: {
              id: {
                in: platting,
              },
            },
          }),
          ...(metalType && {
            metalType: {
              id: {
                eq: metalType,
              },
            },
          }),
          ...(materialGradeType && {
            materialGradeType: {
              id: {
                in: materialGradeType,
              },
            },
          }),
          ...(engravingType && {
            engravingType: {
              id: {
                in: engravingType,
              },
            },
          }),
          ...(timePeriod && {
            timePeriod: {
              id: {
                in: timePeriod,
              },
            },
          }),
          ...(productType && {
            productType: {
              id: {
                eq: productType,
              },
            },
          }),
          ...(specificType && {
            specificType: {
              id: {
                in: specificType,
              },
            },
          }),
          ...(size && {
            size: {
              id: {
                in: size,
              },
            },
          }),
          ...(shankStyle && {
            shankStyle: {
              id: {
                in: shankStyle,
              },
            },
          }),
          ...(designStyle && {
            designStyle: {
              id: {
                in: designStyle,
              },
            },
          }),
          ...(backing && {
            backing: {
              id: {
                in: backing,
              },
            },
          }),
          ...(strand && {
            strand: {
              id: {
                in: strand,
              },
            },
          }),
          ...(strandsLength && {
            strandsLength: {
              id: {
                in: strandsLength,
              },
            },
          }),
          ...(knotStyle && {
            knotStyle: {
              id: {
                in: knotStyle,
              },
            },
          }),
          ...(linkStyle && {
            linkStyle: {
              id: {
                in: linkStyle,
              },
            },
          }),
          ...(linkType && {
            linkType: {
              id: {
                in: linkType,
              },
            },
          }),
          ...(brand && {
            brand: {
              id: {
                in: brand,
              },
            },
          }),
          ...(country && {
            country: {
              id: {
                in: country,
              },
            },
          }),
          ...(boxPaper && {
            boxPaper: {
              id: {
                in: boxPaper,
              },
            },
          }),
          ...(pieces && {
            pieces: {
              id: {
                in: pieces,
              },
            },
          }),
          stones: {
            ...stoneFilter,
          },
        }
      : {};

  return {
    ...productFilter,
  };
};
