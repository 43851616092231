import { FC, useCallback, useMemo } from 'react';

import { Flex, MenuProps, Modal, ModalFuncProps } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  useCreateActivityMutation,
  useDeleteDealTransactionMutation,
  useGetStripeOnboardingDetailsQuery,
} from '@/graphql';

import ActionMenuDropdown from '@ui/dropdown/ActionMenuDropdown';
import ActionMenuButton from '@ui/dropdown/ActionMenuDropdown/ActionMenuButton';

import { useDrawer } from '@drawer/drawerContext';

import { useStatusMessage } from '@/components/app/StatusMessageContext/statusMessageContext';
import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { useTransactionModal } from '@components/accounting/transactions/DealTransactionDropdown/TransactionRefundModalContext';
import { formatToCurrency } from '@helpers/formatter';
import { useTenantRoutes } from '@router/routes';
import { CustomButton } from '@ui/button/Button';
import { useNavigate } from 'react-router';
import { PartialRefundModal } from 'src/components/accounting/transactions/TransactionTable/PartialRefundModal';
import EditDealTransactionForm from '../EditDealTransactionForm/EditDealTransactionForm';

interface Props {
  entityId: string;
  status: string;
  dealTransactionId: string;
  customerInfo: {
    name: string;
    points: number;
    sellingOrder: string;
  };
  paid: number;
  customerIds: {
    contactId?: string;
    companyId?: string;
  };
  account: string;
  orderType: Maybe<EnumOrderType>;
}

const { confirm, destroyAll } = Modal;

const noRefundAccount = ['Expenses', 'Cost of Goods Sold'];
const noRefundStatus = ['Open', 'Cancelled', 'Refunded', 'Running'];
const payAccount = ['Revenue', 'Cost of Goods Sold'];
const payStatus = ['Open'];

export const DealTransactionDropdown: FC<Props> = ({
  entityId,
  status,
  customerInfo: { name, points, sellingOrder },
  customerIds: { contactId, companyId },
  account,
  dealTransactionId,
  orderType,
}) => {
  const message = useStatusMessage();
  const { openDrawer, closeDrawer } = useDrawer();
  const [deleteTransaction, { loading }] = useDeleteDealTransactionMutation({
    variables: { id: entityId },
    refetchQueries: ['dealTransactions', 'cardTotals'],
  });
  const { isOpen, openRefundModal, openPointsRefundModal } =
    useTransactionModal();

  const navigate = useNavigate();
  const {
    selling: { management, purchase, layaway },
  } = useTenantRoutes();

  const [createRefundActivity, { loading: activityLoading }] =
    useCreateActivityMutation({
      refetchQueries: ['contacts', 'companies', 'activity'],
      onError: (error) => message.open('error', error.message),
    });

  const handlePointsRefund = useCallback(
    async (refundValue: number, isPointsRefund: boolean) => {
      if (contactId || companyId) {
        await createRefundActivity({
          variables: {
            input: {
              title: `${
                isPointsRefund
                  ? `Refund points +${refundValue}`
                  : `Refund +${formatToCurrency(refundValue)}`
              }`,
              contact_id: contactId ?? undefined,
              company_id: companyId ?? undefined,
              amount: isPointsRefund ? points + (refundValue ?? 0) : undefined,
              due_date: new Date(),
              completed: true,
              description: `Transaction ${dealTransactionId} was refunded by ${
                isPointsRefund
                  ? `${refundValue} points, its value is now ${
                      points + (refundValue ?? 0)
                    }`
                  : formatToCurrency(refundValue)
              }`,
              type: 'points',
            },
          },
        });
        return;
      }
      message.open('error', 'Transaction should be connected to the customer');
      destroyAll();
    },
    [
      contactId,
      companyId,
      message,
      createRefundActivity,
      points,
      dealTransactionId,
    ],
  );

  const { meData } = useUserSettingsContext();
  const { data: stripeOnboardingData } = useGetStripeOnboardingDetailsQuery({
    variables: {
      input: { tenantId: meData?.me?.attributes?.tenant?.data?.id },
    },
  });

  const handleDeleteEntity = useCallback(async () => {
    await deleteTransaction();
  }, [deleteTransaction]);

  const confirmDeleteProps = useMemo(
    (): ModalFuncProps => ({
      title: 'Do You Want to Delete This Transaction?',
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      centered: true,
      okText: loading ? 'Deleting...' : 'Confirm',
      okType: 'danger',
      onOk: async () => {
        await handleDeleteEntity();
      },
    }),
    [handleDeleteEntity, loading],
  );

  const handlePointsModalOpen = useCallback(() => {
    destroyAll();
    openPointsRefundModal();
  }, [openPointsRefundModal]);

  const confirmRefundProps = useMemo(
    (): ModalFuncProps => ({
      title: `Do You Want to Refund This Transaction?`,
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      centered: true,
      closable: true,
      okText: 'Confirm',
      okType: 'primary',
      onOk: async () => {
        confirm({
          title: `Do you prefer to process the refund using points or money? Currently, ${name} has ${points} points available.`,
          icon: <ExclamationCircleOutlined />,
          maskClosable: true,
          centered: true,
          okText: 'Money',
          okType: 'primary',
          onOk: async () => {
            openRefundModal();
          },
          footer: (_, { OkBtn, CancelBtn }) => {
            return (
              <Flex justify={'end'}>
                <CancelBtn />
                <CustomButton type={'primary'} onClick={handlePointsModalOpen}>
                  Points
                </CustomButton>
                <OkBtn />
              </Flex>
            );
          },
        });
      },
    }),
    [name, openRefundModal, handlePointsModalOpen, points],
  );

  const handleEditAction = useCallback(() => {
    openDrawer(
      <EditDealTransactionForm onCancel={closeDrawer} id={entityId} />,
      'Edit transaction',
    );
  }, [closeDrawer, entityId, openDrawer]);

  const ordersPaymentRoute = useMemo(
    () =>
      account === 'Cost of Goods Sold'
        ? purchase
        : orderType === 'layaway'
        ? layaway
        : management,
    [account, orderType, management, purchase, layaway],
  );

  const handlePayAction = useCallback(() => {
    navigate(ordersPaymentRoute);
  }, [ordersPaymentRoute, navigate]);

  const items: MenuProps['items'] = useMemo(() => {
    const isNoRefundAccount =
      noRefundAccount.includes(account) || noRefundStatus.includes(status);
    const isPayAccount =
      payAccount.includes(account) && payStatus.includes(status);

    const noRefundItems: MenuProps['items'] = [
      {
        key: 'Edit',
        label: (
          <ActionMenuButton iconType={'edit'} onClick={handleEditAction}>
            Edit
          </ActionMenuButton>
        ),
      },
      { key: `divider-1 ${entityId}`, type: 'divider' },
      {
        key: 'Delete',
        label: (
          <ActionMenuButton
            iconType={'delete'}
            actionType={'delete'}
            confirmProps={confirmDeleteProps}
          >
            Delete
          </ActionMenuButton>
        ),
      },
    ];

    const payItems: MenuProps['items'] = [
      {
        key: 'Edit',
        label: (
          <ActionMenuButton iconType={'edit'} onClick={handleEditAction}>
            Edit
          </ActionMenuButton>
        ),
      },
      { key: `divider-1 ${entityId}`, type: 'divider' as const },
      {
        key: 'Pay',
        label: (
          <ActionMenuButton iconType={'card'} onClick={handlePayAction}>
            Pay
          </ActionMenuButton>
        ),
      },
      { key: `divider-2 ${entityId}`, type: 'divider' as const },
      {
        key: 'Delete',
        label: (
          <ActionMenuButton
            iconType={'delete'}
            actionType={'delete'}
            confirmProps={confirmDeleteProps}
          >
            Delete
          </ActionMenuButton>
        ),
      },
    ];

    const defaultItems: MenuProps['items'] = [
      {
        key: 'Edit',
        label: (
          <ActionMenuButton iconType={'edit'} onClick={handleEditAction}>
            Edit
          </ActionMenuButton>
        ),
      },
      { key: `divider-1 ${entityId}`, type: 'divider' as const },
      ...(status !== 'Refunded'
        ? [
            {
              key: 'Refund',
              label: (
                <ActionMenuButton
                  iconType={'card'}
                  confirmProps={confirmRefundProps}
                >
                  Refund
                </ActionMenuButton>
              ),
            },
            { key: `divider-2 ${entityId}`, type: 'divider' as const },
          ]
        : []),
      {
        key: 'Delete',
        label: (
          <ActionMenuButton
            iconType={'delete'}
            actionType={'delete'}
            confirmProps={confirmDeleteProps}
          >
            Delete
          </ActionMenuButton>
        ),
      },
    ];

    return isNoRefundAccount && !isPayAccount
      ? noRefundItems
      : isPayAccount
      ? payItems
      : defaultItems;
  }, [
    confirmDeleteProps,
    confirmRefundProps,
    entityId,
    handleEditAction,
    handlePayAction,
    account,
    status,
  ]);

  return (
    <>
      <ActionMenuDropdown items={items} />
      {isOpen ? (
        <PartialRefundModal
          handlePointsRefund={handlePointsRefund}
          activityLoading={activityLoading}
          entityId={entityId}
          sellingOrder={sellingOrder}
          accountId={
            stripeOnboardingData?.getStripeOnboardingDetails?.accountId
          }
        />
      ) : (
        ''
      )}
    </>
  );
};
