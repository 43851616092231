import {
  useCreatePurchaseRequestMutation,
  useCreateShippingInfoMutation,
  useOrderByIdQuery,
} from '@/graphql';
import { getEntityIdWithRegex } from '@/utils/randomBytes';
import { StarFilled } from '@ant-design/icons';
import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { Icon, IconSize } from '@assets/icon';
import { SendFormType } from '@components/contracts/forms/SendLinkForm';
import { SendLinkByEmailForm } from '@components/contracts/invoices/forms/SendLinkByEmailForm';
import { useInvoiceActions } from '@components/contracts/invoices/hooks/useInvoiceActions';
import { InvoiceCard } from '@components/contracts/invoices/view/InvoiceCard';
import { InvoiceViewFields } from '@components/contracts/invoices/view/InvoiceViewFields';
import { InvoiceViewFieldsUi } from '@components/contracts/invoices/view/InvoiceViewFieldsUi';
import { usePurchaseRequestActions } from '@components/contracts/purchaseRequests/hooks/usePurchaseRequestActions';
import { ExternalRepairTicketViewFields } from '@components/contracts/repairTickets/ExternalRepairTicketViewFields';
import { RepairTicketCard } from '@components/contracts/repairTickets/RepairTicketCard';
import { RepairTicketViewFields } from '@components/contracts/repairTickets/RepairTicketViewFields';
import { useCreateInvoice } from '@components/selling/hooks/useCreateInvoice';
import { getInvoiceInfo } from '@components/selling/pos/orders-list/OrderInvoiceActions/helpers/getInvoiceInfo';
import { getPurchaseRequestInfo } from '@components/selling/pos/orders-list/OrderInvoiceActions/helpers/getPurchaseRequestInfo';
import {
  defaultConfirmData,
  iconColor,
} from '@components/selling/pos/orders-list/OrderInvoiceActions/static/static';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useDrawer } from '@drawer/drawerContext';
import { useDocumentPermissionInfo } from '@hooks/documentPermissions/useDocumentPermissionInfo';
import { useCustomDrawerToggle } from '@hooks/useCustomDrawerToggle';
import { CustomButton } from '@ui/button/Button';
import { ComponentMask } from '@ui/componentMask/ComponentMask';
import CustomDrawer from '@ui/drawer';
import { Col, Modal, Row } from 'antd';
import { CardProps } from 'antd/lib/card/Card';
import { get } from 'lodash';
import { FC, useCallback, useRef, useState } from 'react';
const { confirm } = Modal;

const timeout = 3000;

export interface OrderCardProps extends CardProps {
  orderId: UniqueIdentifier;
  orderUuid: string;
  customerName: string;
}

// TODO: Valentyn V. - refactor this component in the future
export const OrderInvoiceActions: FC<OrderCardProps> = ({
  orderId,
  orderUuid,
  customerName,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [isSendLoading, setIsSendLoading] = useState(false);

  const { data } = useOrderByIdQuery({
    variables: { orderId: orderUuid as string },
  });

  const orderType = get(data, 'orders.data[0].attributes.type', '').toString();
  const isPurchaseOrder = orderType === 'purchase';

  const { create: createInvoice } = useCreateInvoice(orderId as string, false);
  const { meData } = useUserSettingsContext();
  const [createPurchaseRequest] = useCreatePurchaseRequestMutation();
  const [createShippingInfo] = useCreateShippingInfoMutation();

  const handleGeneratePurchaseRequest = useCallback(async () => {
    await createShippingInfo({
      variables: {
        input: {
          name: meData?.me?.attributes?.tenant?.data?.attributes?.companyName,
          address: 'address',
          email: 'email@email.com',
          phoneNumber: '+11111111111',
        },
      },
      onCompleted: async (requestData) => {
        if (requestData?.createPurchaseRequestShippingInfo?.data?.id) {
          await createPurchaseRequest({
            variables: {
              input: {
                requestId: getEntityIdWithRegex(),
                orderId: orderId as string,
                shippingInfo:
                  requestData?.createPurchaseRequestShippingInfo?.data?.id,
              },
            },
          });
        }
      },
    });
  }, [orderId, createShippingInfo, createPurchaseRequest, meData]);

  const { invoice, hasInvoice, attachedFileId, fileItemId } =
    getInvoiceInfo(data);
  const {
    purchaseRequest,
    hasPurchaseRequest,
    attachedPurchaseFileId,
    filePurchaseItemId,
  } = getPurchaseRequestInfo(data);
  const {
    isShowOrderItemsImages,
    isPurchaseDiscountEnabled,
    isPurchaseTaxEnabled,
    isPurchaseTipEnabled,
  } = useDocumentPermissionInfo();

  const customerInvoiceRef = useRef(null);
  const repairTicketRef = useRef(null);
  const externalRepairTicketRef = useRef(null);
  const purchaseOrderRef = useRef(null);
  const reviewRef = useRef(null);
  const reviewDrawer = useCustomDrawerToggle();
  const invoiceDrawer = useCustomDrawerToggle();
  const repairDrawer = useCustomDrawerToggle();
  const externalRepairDrawer = useCustomDrawerToggle();
  const purchaseDrawer = useCustomDrawerToggle();
  const { closeDrawer } = useDrawer();

  const { handleSendReviewRequest: sendReview } = useInvoiceActions(
    reviewRef,
    data?.orders?.data?.[0]?.id as string,
    'review',
  );

  const {
    handlePrint: customerInvoicePrint,
    handleUploadInvoiceFile: handleUploadCustomerInvoice,
    handleDownload: customerInvoiceDownload,
  } = useInvoiceActions(customerInvoiceRef, invoice?.data?.id as string);

  const {
    handlePrint: repairTicketPrint,
    handleUploadInvoiceFile: handleUploadRepairTicket,
    handleDownload: repairTicketDownload,
  } = useInvoiceActions(
    repairTicketRef,
    invoice?.data?.id as string,
    'repair_ticket',
  );

  const {
    handlePrint: externalRepairTicketPrint,
    handleUploadInvoiceFile: handleUploadExternalRepairTicket,
    handleDownload: externalRepairTicketDownload,
  } = useInvoiceActions(
    externalRepairTicketRef,
    invoice?.data?.id as string,
    'repair_ticket',
  );

  const {
    handlePrint: purchaseRequestPrint,
    handleUploadPurchaseRequestFile: handleUploadPurchaseRequest,
    handleDownload: purchaseRequestDownload,
  } = usePurchaseRequestActions(
    purchaseOrderRef,
    purchaseRequest?.data?.id as string,
  );

  const printConfirmProps = {
    ...defaultConfirmData,
    title: 'What would you like to print?',
    width: 580,
    footer: () => (
      <Row justify={'end'}>
        <CustomButton
          onClick={async () => {
            externalRepairTicketPrint();
            Modal.destroyAll();
            closeDrawer();
          }}
        >
          External Repair Ticket
        </CustomButton>
        <CustomButton
          onClick={async () => {
            repairTicketPrint();
            Modal.destroyAll();
            closeDrawer();
          }}
        >
          Internal Repair Ticket
        </CustomButton>
        <CustomButton
          type={'primary'}
          onClick={async () => {
            customerInvoicePrint();
            Modal.destroyAll();
            closeDrawer();
          }}
        >
          Customer Invoice
        </CustomButton>
      </Row>
    ),
  };
  const handlePrintClick = async () => {
    setIsDisabled(true);
    setIsPrintLoading(true);

    if (!hasInvoice) {
      await createInvoice();
    }

    setTimeout(() => {
      setIsDisabled(false);
      setIsPrintLoading(false);

      confirm(printConfirmProps);
    }, timeout);
  };

  const printRequestConfirmProps = {
    ...defaultConfirmData,
    cancelText: 'Cancel',
    okText: 'Print',
    title: 'Would you like to print purchase request?',
    onOk: async () => {
      purchaseRequestPrint();
      closeDrawer();
    },
    onCancel: async () => {
      closeDrawer();
    },
  };

  const handleRequestPrintClick = async () => {
    setIsDisabled(true);
    setIsPrintLoading(true);

    if (!hasInvoice) {
      await handleGeneratePurchaseRequest();
    }

    setTimeout(() => {
      setIsDisabled(false);
      setIsPrintLoading(false);

      confirm(printRequestConfirmProps);
    }, timeout);
  };

  const handleSendCustomerInvoice = useCallback(
    async (values: SendFormType) => {
      const functionProps = {
        fileId: attachedFileId as string,
        fileItemId: fileItemId as string,
        subjectEmail: values.subjectEmail as string,
        contactId: values.contact as string,
        customerName: customerName,
        subjectPhone: values.subjectPhone as string,
        sendByEmail: values.sendByEmail,
        sendBySms: values.sendBySms,
        customSmsContactContent: values.customSmsContactContent,
        customSmsSubjectContent: values.customSmsSubjectContent,
      };

      await handleUploadCustomerInvoice({ ...functionProps });
      closeDrawer();
      invoiceDrawer.closeDrawer();
    },
    [
      handleUploadCustomerInvoice,
      attachedFileId,
      fileItemId,
      customerName,
      closeDrawer,
      invoiceDrawer,
    ],
  );

  const handleSendRepairTicket = useCallback(
    async (values: SendFormType) => {
      const functionProps = {
        fileId: attachedFileId as string,
        fileItemId: fileItemId as string,
        subjectEmail: values.subjectEmail as string,
        contactId: values.contact as string,
        customerName: customerName,
        subjectPhone: values.subjectPhone as string,
        sendByEmail: values.sendByEmail,
        sendBySms: values.sendBySms,
        customSmsContactContent: values.customSmsContactContent,
        customSmsSubjectContent: values.customSmsSubjectContent,
      };
      await handleUploadRepairTicket({ ...functionProps });
      closeDrawer();
      repairDrawer.closeDrawer();
    },
    [
      handleUploadRepairTicket,
      attachedFileId,
      fileItemId,
      customerName,
      closeDrawer,
      repairDrawer,
    ],
  );

  const handleSendExternalRepairTicket = useCallback(
    async (values: SendFormType) => {
      const functionProps = {
        fileId: attachedFileId as string,
        fileItemId: fileItemId as string,
        subjectEmail: values.subjectEmail as string,
        subjectPhone: values.subjectPhone as string,
        contactId: values.contact as string,
        customerName: customerName,
        sendByEmail: values.sendByEmail,
        sendBySms: values.sendBySms,
        customSmsContactContent: values.customSmsContactContent,
        customSmsSubjectContent: values.customSmsSubjectContent,
      };
      await handleUploadExternalRepairTicket({ ...functionProps });
      closeDrawer();
      externalRepairDrawer.closeDrawer();
    },
    [
      handleUploadExternalRepairTicket,
      attachedFileId,
      fileItemId,
      customerName,
      closeDrawer,
      externalRepairDrawer,
    ],
  );

  const emailConfirmProps = {
    ...defaultConfirmData,
    title: 'What would you like to send?',
    width: 580,
    footer: () => (
      <Row justify={'end'}>
        <CustomButton
          onClick={async () => {
            externalRepairDrawer.showDrawer();
            Modal.destroyAll();
          }}
        >
          External Repair Ticket
        </CustomButton>
        <CustomButton
          onClick={async () => {
            repairDrawer.showDrawer();
            Modal.destroyAll();
          }}
        >
          Internal Repair Ticket
        </CustomButton>
        <CustomButton
          type={'primary'}
          onClick={async () => {
            invoiceDrawer.showDrawer();
            Modal.destroyAll();
          }}
        >
          Customer Invoice
        </CustomButton>
      </Row>
    ),
  };

  const handleEmailClick = async () => {
    setIsDisabled(true);
    setIsSendLoading(true);

    if (!hasInvoice) {
      await createInvoice();
    }

    setTimeout(() => {
      setIsDisabled(false);
      setIsSendLoading(false);

      confirm(emailConfirmProps);
    }, timeout);
  };

  const handleSendReviewRequest = useCallback(
    async (values: SendFormType) => {
      await sendReview({
        subjectEmail: values.subjectEmail as string,
        contactId: values.contact as string,
        subjectPhone: values.subjectPhone as string,
        customSmsContactContent: values.customSmsContactContent,
        customSmsSubjectContent: values.customSmsSubjectContent,
        sendBySms: values.sendBySms,
        sendByEmail: values.sendByEmail,
      });
      closeDrawer();
      reviewDrawer.closeDrawer();
    },
    [closeDrawer, reviewDrawer, sendReview],
  );

  const handleSendPurchaseRequest = useCallback(
    async (values: SendFormType) => {
      await handleUploadPurchaseRequest(
        attachedPurchaseFileId as string,
        filePurchaseItemId as string,
        '',
        values.subjectEmail as string,
        values.contact as string,
        values.subjectPhone as string,
        values.sendBySms,
        values.sendByEmail,
        values.customSmsSubjectContent,
        values.customSmsContactContent,
      );
      closeDrawer();
      purchaseDrawer.closeDrawer();
    },
    [
      handleUploadPurchaseRequest,
      attachedPurchaseFileId,
      filePurchaseItemId,
      closeDrawer,
      purchaseDrawer,
    ],
  );

  const emailRequestConfirmProps = {
    ...defaultConfirmData,
    title: 'Would you like to send purchase request?',
    cancelText: 'Cancel',
    okText: 'Send',
    onOk: async () => {
      purchaseDrawer.showDrawer();
    },
    onCancel: async () => {
      closeDrawer();
    },
  };

  const handleRequestEmailClick = async () => {
    setIsDisabled(true);
    setIsSendLoading(true);

    if (!hasInvoice) {
      await handleGeneratePurchaseRequest();
    }

    setTimeout(() => {
      setIsDisabled(false);
      setIsSendLoading(false);

      confirm(emailRequestConfirmProps);
    }, timeout);
  };

  const handleDownloadCustomerInvoice = useCallback(async () => {
    await customerInvoiceDownload(
      attachedFileId as string,
      fileItemId as string,
      customerName,
    );
    closeDrawer();
    invoiceDrawer.closeDrawer();
  }, [
    customerInvoiceDownload,
    attachedFileId,
    fileItemId,
    customerName,
    closeDrawer,
    invoiceDrawer,
  ]);

  const handleDownloadRepairTicket = useCallback(async () => {
    await repairTicketDownload(
      attachedFileId as string,
      fileItemId as string,
      customerName,
    );
    closeDrawer();
    invoiceDrawer.closeDrawer();
  }, [
    repairTicketDownload,
    attachedFileId,
    fileItemId,
    customerName,
    closeDrawer,
    invoiceDrawer,
  ]);

  const handleDownloadExternalRepairTicket = useCallback(async () => {
    await externalRepairTicketDownload(
      attachedFileId as string,
      fileItemId as string,
      customerName,
    );
    closeDrawer();
    invoiceDrawer.closeDrawer();
  }, [
    externalRepairTicketDownload,
    attachedFileId,
    fileItemId,
    customerName,
    closeDrawer,
    invoiceDrawer,
  ]);

  const downloadConfirmProps = {
    ...defaultConfirmData,
    title: 'What would you like to download?',
    width: 580,
    footer: () => (
      <Row justify={'end'}>
        <CustomButton
          onClick={async () => {
            await handleDownloadExternalRepairTicket();
            Modal.destroyAll();
            closeDrawer();
          }}
        >
          External Repair Ticket
        </CustomButton>
        <CustomButton
          onClick={async () => {
            await handleDownloadRepairTicket();
            Modal.destroyAll();
            closeDrawer();
          }}
        >
          Internal Repair Ticket
        </CustomButton>
        <CustomButton
          type={'primary'}
          onClick={async () => {
            await handleDownloadCustomerInvoice();
            Modal.destroyAll();
            closeDrawer();
          }}
        >
          Customer Invoice
        </CustomButton>
      </Row>
    ),
  };
  const handleDownloadClick = async () => {
    setIsDisabled(true);
    setIsDownloadLoading(true);

    if (!hasInvoice) {
      await createInvoice();
    }

    setTimeout(() => {
      setIsDisabled(false);
      setIsDownloadLoading(false);

      confirm(downloadConfirmProps);
    }, timeout);
  };

  const handleDownloadPurchaseRequest = useCallback(async () => {
    await purchaseRequestDownload(
      attachedPurchaseFileId as string,
      filePurchaseItemId as string,
      customerName,
    );
    closeDrawer();
    purchaseDrawer.closeDrawer();
  }, [
    purchaseRequestDownload,
    attachedPurchaseFileId,
    filePurchaseItemId,
    customerName,
    closeDrawer,
    purchaseDrawer,
  ]);

  const downloadRequestConfirmProps = {
    ...defaultConfirmData,
    title: 'Would you like to download the purchase request?',
    cancelText: 'Cancel',
    okText: 'Download',
    onOk: async () => {
      await handleDownloadPurchaseRequest();
      closeDrawer();
    },
    onCancel: async () => {
      closeDrawer();
    },
  };
  const handleRequestDownloadClick = async () => {
    setIsDisabled(true);
    setIsDownloadLoading(true);

    if (!hasPurchaseRequest) {
      await handleGeneratePurchaseRequest();
    }

    setTimeout(() => {
      setIsDisabled(false);
      setIsDownloadLoading(false);

      confirm(downloadRequestConfirmProps);
    }, timeout);
  };

  return (
    <Row gutter={6}>
      <Col>
        <CustomButton
          icon={
            <StarFilled
              style={{ color: iconColor, fontSize: `${IconSize.Large}px` }}
            />
          }
          type={'text'}
          size={'large'}
          onClick={() => reviewDrawer.showDrawer()}
        />
      </Col>
      <Col>
        <CustomButton
          icon={
            <Icon type={'download'} color={iconColor} size={IconSize.Large} />
          }
          type={'text'}
          size={'large'}
          onClick={
            isPurchaseOrder ? handleRequestDownloadClick : handleDownloadClick
          }
          disabled={isDisabled}
          loading={isDownloadLoading}
        />
      </Col>
      <Col>
        <CustomButton
          icon={<Icon type={'print'} color={iconColor} size={IconSize.Large} />}
          type={'text'}
          size={'large'}
          onClick={isPurchaseOrder ? handleRequestPrintClick : handlePrintClick}
          disabled={isDisabled}
          loading={isPrintLoading}
        />
      </Col>
      <Col>
        <CustomButton
          icon={<Icon type={'mail'} color={iconColor} size={IconSize.Large} />}
          type={'text'}
          size={'large'}
          onClick={isPurchaseOrder ? handleRequestEmailClick : handleEmailClick}
          disabled={isDisabled}
          loading={isSendLoading}
        />
      </Col>

      <ComponentMask>
        <InvoiceCard
          title={invoice?.data?.attributes?.invoiceId ?? ''}
          date={invoice?.data?.attributes?.createdAt ?? ''}
          orderId={orderUuid}
          ref={customerInvoiceRef}
          withActions={false}
          style={{ width: '794px', border: 'none' }}
        >
          <Row style={{ width: '100%', margin: 0, padding: 20 }}>
            <InvoiceViewFields
              uuid={invoice?.data?.attributes?.uuid ?? ''}
              useItemsVisibility={true}
            />
          </Row>
        </InvoiceCard>
      </ComponentMask>

      <ComponentMask>
        <RepairTicketCard
          ref={repairTicketRef}
          style={{ width: '794px', border: 'none' }}
        >
          <Row style={{ width: '100%', margin: 0 }}>
            <RepairTicketViewFields
              uuid={invoice?.data?.attributes?.uuid ?? ''}
              useItemsVisibility={true}
            />
          </Row>
        </RepairTicketCard>
      </ComponentMask>

      <ComponentMask>
        <RepairTicketCard
          ref={externalRepairTicketRef}
          style={{ width: '794px', border: 'none' }}
        >
          <Row style={{ width: '100%', margin: 0 }}>
            <ExternalRepairTicketViewFields
              uuid={invoice?.data?.attributes?.uuid ?? ''}
              useItemsVisibility={true}
            />
          </Row>
        </RepairTicketCard>
      </ComponentMask>

      <ComponentMask>
        <InvoiceCard
          title={purchaseRequest?.data?.attributes?.requestId ?? ''}
          date={purchaseRequest?.data?.attributes?.createdAt ?? ''}
          orderId={data?.orders?.data?.[0]?.attributes?.orderId}
          withActions={false}
          ref={purchaseOrderRef}
          copy={false}
          entityType={'request'}
          style={{ width: '794px', border: 'none' }}
        >
          <Row style={{ margin: 0, padding: 20 }}>
            <InvoiceViewFieldsUi
              entityType={'request'}
              orderId={data?.orders?.data?.[0]?.id as string}
              terms={purchaseRequest?.data?.attributes?.terms ?? ''}
              businessLocation={
                data?.orders?.data?.[0]?.attributes?.businessLocation?.data ??
                undefined
              }
              isShowOrderItemsImages={isShowOrderItemsImages}
              isPurchaseDiscountEnabled={isPurchaseDiscountEnabled}
              isPurchaseTaxEnabled={isPurchaseTaxEnabled}
              isPurchaseTipEnabled={isPurchaseTipEnabled}
            />
          </Row>
        </InvoiceCard>
      </ComponentMask>

      <CustomDrawer
        styles={{ body: { overflow: 'scroll' } }}
        title={'Send Review Request'}
        onClose={reviewDrawer.closeDrawer}
        open={reviewDrawer.isOpen}
      >
        <SendLinkByEmailForm handleSend={handleSendReviewRequest} />
      </CustomDrawer>

      <CustomDrawer
        styles={{ body: { overflow: 'scroll' } }}
        title={'Send Customer Invoice'}
        onClose={invoiceDrawer.closeDrawer}
        open={invoiceDrawer.isOpen}
      >
        <SendLinkByEmailForm handleSend={handleSendCustomerInvoice} />,
      </CustomDrawer>

      <CustomDrawer
        styles={{ body: { overflow: 'scroll' } }}
        title={'Send Internal Repair Ticket'}
        onClose={repairDrawer.closeDrawer}
        open={repairDrawer.isOpen}
      >
        <SendLinkByEmailForm handleSend={handleSendRepairTicket} />,
      </CustomDrawer>

      <CustomDrawer
        styles={{ body: { overflow: 'scroll' } }}
        title={'Send External Repair Ticket'}
        onClose={externalRepairDrawer.closeDrawer}
        open={externalRepairDrawer.isOpen}
      >
        <SendLinkByEmailForm handleSend={handleSendExternalRepairTicket} />,
      </CustomDrawer>

      <CustomDrawer
        styles={{ body: { overflow: 'scroll' } }}
        title={'Send Purchase Request'}
        onClose={purchaseDrawer.closeDrawer}
        open={purchaseDrawer.isOpen}
      >
        <SendLinkByEmailForm handleSend={handleSendPurchaseRequest} />,
      </CustomDrawer>
    </Row>
  );
};
