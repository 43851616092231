import { useUpdateProductOrderItemMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

type MemoOutReportReturnedDateVariables = {
  id: string;
  input: {
    returnedDate: Date;
  };
};

type MemoOutReportReturnedDateHandlers = {
  handleMemoOutReportReturnedDateChange: (value: Date) => Promise<void>;
  loading: boolean;
};

export function useUpdateMemoOutReportReturnedDate(
  itemId: Maybe<string>,
): MemoOutReportReturnedDateHandlers {
  const message = useStatusMessage();

  const [updateProductInventoryItemMemoOutReport, { loading }] =
    useUpdateProductOrderItemMutation({
      onCompleted: () => {
        message.open('success', 'The return date has been updated');
      },
      refetchQueries: ['productOrderItemsWithCustomer'],
    });

  const updateMemoOutReportReturnedDate = async (
    variables: MemoOutReportReturnedDateVariables,
  ) => {
    return updateProductInventoryItemMemoOutReport({
      variables,
    });
  };

  const handleMemoOutReportReturnedDateChange = async (value: Date) => {
    try {
      itemId &&
        (await updateMemoOutReportReturnedDate({
          id: itemId,
          input: {
            returnedDate: value,
          },
        }));
    } catch (error) {
      message.open('error', 'Failed to update the return date');
    }
  };

  return { handleMemoOutReportReturnedDateChange, loading };
}
