import { FC, ReactNode, useCallback } from 'react';

import { Col, Row } from 'antd';

import {
  ActiveButtonType,
  HeaderType,
} from '@components/layout/MainLayout/MainLayout';
import { NotificationsDropdown } from '@components/notifications/NotificationsDropdown';
import { StoresDropdown } from '@components/stores/dropdown/StoresDropdown';

import { Icon } from '@assets/icon';

import { CustomButton } from '@ui/button/Button';
import MenuBackButton from '@ui/navigation/menu/MenuBackButton';

import { ProfileDropdown } from '@/components/auth/profileDropdown/ProfileDropdown';
import { useConversationsLazyQuery, useMeQuery } from '@/graphql';
import { useTenantRoutes } from '@/router/routes';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { Loader } from '@components/layout/MainLayout/Loader';
import { ChatNotificationBadge } from '@components/messaging/ChatNotificationBadge';
import { useNavigate } from 'react-router';

import { useConfirmationBeforeBackRoute } from '@/components/inventory/inventoryManagement/hooks/useConfirmationBeforeBackRoute';

interface Props {
  type: HeaderType;
  backTitle?: string;
  link?: string;
  entityPlural?: string;
  emailOptions?: ReactNode;
  activeButton?: ActiveButtonType;
}

const Header: FC<Props> = ({
  type = 'default',
  backTitle,
  link,
  emailOptions,
  activeButton,
  entityPlural,
}) => {
  const navigate = useNavigate();
  const {
    dashboard: {
      settings: { index: settingsIndex },
    },
    messaging: { index },
  } = useTenantRoutes();
  const message = useStatusMessage();
  const { data: me, loading: loadingMe } = useMeQuery();
  const subscriptionStatus =
    me?.me?.attributes?.tenant?.data?.attributes?.subscriptions?.data[0]
      ?.attributes?.status;
  const [getConversations, { loading, error, data }] =
    useConversationsLazyQuery({
      variables: {
        filters: {
          user: {
            id: {
              eq: me?.me?.id,
            },
          },
        },
      },
    });

  const handleNavigateToMessaging = async () => {
    navigate('messaging');
    //TODO Sophia  - check working on staging without this navigation
    // getConversations({
    //   onCompleted: (data) => {
    //     if (data?.conversations?.data?.[0]?.attributes?.uuid) {
    //       navigate(
    //         `${index}/${data?.conversations?.data?.[0]?.attributes?.uuid}`,
    //       );
    //     } else {
    //       navigate(`${index}/no-chats`);
    //     }
    //   },
    //   onError: () =>
    //     message.open('error', `Could not get conversations from ${index}.`),
    // });
  };

  const { handleCheckPaths } = useConfirmationBeforeBackRoute();

  const getActiveButtonStyles = useCallback(
    (currentButton: ActiveButtonType) =>
      activeButton && currentButton && activeButton === currentButton
        ? { backgroundColor: '#E7ECF8', color: '#0146C5' }
        : undefined,
    [activeButton],
  );

  return loadingMe ? (
    <Loader />
  ) : (
    <header
      style={{
        padding: '16px 32px',
        borderBottom: '1px solid #E7E7E7',
        height: '68px',
      }}
    >
      <Row align={'middle'} justify={'space-between'}>
        {(type === 'with-back-button' || type === 'email-editor') && (
          <Col>
            <MenuBackButton
              onClick={() => handleCheckPaths(link ?? '/')}
              backTitle={backTitle ?? 'Go Back'}
            />
          </Col>
        )}
        {type === 'default' && (
          <>
            <Col flex={'100px'}>
              <StoresDropdown />
            </Col>
            <Col>
              <Row gutter={10} align={'middle'}>
                <Col>
                  <NotificationsDropdown />
                </Col>
                <Col>
                  <CustomButton
                    onClick={handleNavigateToMessaging}
                    type={'text'}
                    size={'large'}
                    style={getActiveButtonStyles('chat')}
                    disabled={!!subscriptionStatus}
                  >
                    {me?.me?.id && (
                      <ChatNotificationBadge userId={me?.me?.id} isDot={true}>
                        <Icon
                          type={'message'}
                          size={24}
                          styles={{ display: 'flex' }}
                        />
                      </ChatNotificationBadge>
                    )}
                  </CustomButton>
                </Col>
                <Col>
                  <CustomButton
                    href={settingsIndex}
                    type={'text'}
                    size={'large'}
                    style={getActiveButtonStyles('settings')}
                  >
                    <Icon type={'gear'} size={24} />
                  </CustomButton>
                </Col>
                <Col>
                  <ProfileDropdown />
                </Col>
              </Row>
            </Col>
          </>
        )}
        {type === 'email-editor' && <Col>{emailOptions}</Col>}
      </Row>
    </header>
  );
};

export { Header };
