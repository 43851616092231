import { FormProviderProps } from 'rc-field-form/lib/FormContext';

export const handleFormFinish: FormProviderProps['onFormFinish'] = (
  name,
  { values, forms },
) => {
  if (name === 'attachmentsForm') {
    const { basicForm, attachmentsForm } = forms;
    const files = attachmentsForm.getFieldValue('files') || [];
    basicForm.setFieldValue('files', files);
  }
};
