import { Space } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useDealTransactionQuery, usePlatformQuery } from '@/graphql';

import { usePlatformRoutes } from '@router/routes';

import styles from './Logo.module.scss';

interface LogoProps {
  minified?: boolean;
  hasLink?: boolean;
}

const Logo: FC<LogoProps> = ({ minified, hasLink }) => {
  const { data } = usePlatformQuery();

  const { attributes } = data?.platform?.data || {};
  const txnID = location?.href?.split('&')[1]?.split('=')[1];
  const logo = minified ? attributes?.minifiedLogo : attributes?.logo;

  const { url, alternativeText } = logo?.data?.attributes || {};

  const routes = usePlatformRoutes();
  const { data: dealTransaction } = useDealTransactionQuery({
    variables: { id: txnID ?? '' },
  });

  const logoUrl =
    dealTransaction?.dealTransaction &&
    dealTransaction?.dealTransaction.data?.attributes?.tenant?.data?.attributes
      ?.logo?.data?.attributes?.url;

  return (
    <Space direction={'horizontal'} align={'center'}>
      {hasLink ? (
        <Link to={routes.index}>
          <img
            src={(logoUrl || logo?.data?.attributes?.url) ?? ''}
            alt={alternativeText || 'Logo'}
            className={styles.logo}
          />
        </Link>
      ) : (
        <img src={url} alt={alternativeText || 'Logo'} />
      )}
    </Space>
  );
};

export { Logo };
