import { useOrderByIdQuery } from '@/graphql';

type ReturnType = {
  availableRefundedAmount: number;
  availableAmountToRefundLoading: boolean;
};

export function useAvailableRefundAmount(
  orderId: string,
  entityId: string,
): ReturnType {
  const { data: currentOrderData, loading: availableAmountToRefundLoading } =
    useOrderByIdQuery({
      variables: {
        orderId: orderId,
      },
    });

  const totalRefundedAmount =
    currentOrderData?.orders?.data?.[0]?.attributes?.dealTransactions?.data?.reduce(
      (sum, txn) =>
        txn?.attributes?.status === 'Refunded'
          ? sum + Number(txn?.attributes?.paid)
          : sum,
      0,
    );

  const paidTransaction =
    currentOrderData?.orders?.data?.[0]?.attributes?.dealTransactions?.data?.find(
      (item) => item?.id === entityId,
    );

  const availableRefundedAmount =
    Number(paidTransaction?.attributes?.paid) - Number(totalRefundedAmount);

  return {
    availableRefundedAmount,
    availableAmountToRefundLoading,
  };
}
