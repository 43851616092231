import CheckboxGroupItem from '@components/reports/CheckboxGroupItem';
import { ColumnItem } from '@filters/hooks/useDrawerFilter';
import { CustomSpace } from '@ui/space';
import { Checkbox, Input, Row } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

interface Props extends CheckboxGroupProps {
  additionalColumns?: ColumnItem[];
}

const CheckboxGroup: FC<Props> = ({
  onChange,
  additionalColumns = [],
  value = [],
}) => {
  const [filteredColumns, setFilteredColumns] =
    useState<ColumnItem[]>(additionalColumns);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setFilteredColumns(additionalColumns);
  }, [additionalColumns]);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value.toLowerCase();
      setInputValue(searchValue);

      setFilteredColumns(
        additionalColumns.filter((column) =>
          column.name.toLowerCase().includes(searchValue),
        ),
      );
    },
    [additionalColumns],
  );

  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleCheckboxesChange = useCallback(
    (selectedValues: string[]) => {
      if (!onChange) return;

      const updatedValues = selectedValues.filter((val) =>
        additionalColumns.some((column) => column.value === val),
      );

      onChange(updatedValues);
    },
    [onChange, additionalColumns],
  );

  const debouncedHandleCheckboxesChange = useCallback(
    (selectedValues: string[]) => {
      if (debounceRef.current) clearTimeout(debounceRef.current);

      debounceRef.current = setTimeout(() => {
        handleCheckboxesChange(selectedValues);
      }, 200);
    },
    [handleCheckboxesChange],
  );

  useEffect(() => {
    return () => {
      if (debounceRef.current) clearTimeout(debounceRef.current);
    };
  }, []);

  return (
    <>
      <Input
        placeholder={'Search'}
        onChange={handleSearchChange}
        value={inputValue}
      />
      <Row style={{ maxHeight: 500, overflow: 'auto', marginTop: 15 }}>
        <Checkbox.Group
          style={{ width: '100%' }}
          value={value}
          onChange={debouncedHandleCheckboxesChange}
        >
          <CustomSpace block direction={'vertical'} size={12}>
            {filteredColumns.map((item) => (
              <CheckboxGroupItem key={item.value} {...item} />
            ))}
          </CustomSpace>
        </Checkbox.Group>
      </Row>
    </>
  );
};

export default CheckboxGroup;
