import { FC, useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { DealTransactionFormValues } from 'src/components/accounting/transactions/types';

import {
  useDealTransactionQuery,
  useDealTransactionsQuery,
  useUpdateDealTransactionMutation,
} from '@/graphql';

import DealTransactionInputsList from '@components/accounting/transactions/DealTransactionInputsList';
import { Loader } from '@components/layout/MainLayout';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

import { useCustomForm } from '@form/hooks/useCustomForm';

import { handleFormFinish } from '@components/tasks/utils/utils';
import { transformGqlFileToAntd } from '@components/uploadFile/helpers';
import DrawerFormWrapper from '@crm/formWrapper';
import { DrawerFormPropsType } from '@crm/types';
import { useUpdateFiles } from '@inventory/inventoryManagement/hooks/useUpdateFiles';
import { Form } from 'antd';

interface ChartOption {
  value: string;
  label: string;
}

const EditDealTransactionForm: FC<DrawerFormPropsType> = ({ onCancel, id }) => {
  const [form, handleChange] = useCustomForm<DealTransactionFormValues>();
  const message = useStatusMessage();
  const [updateTransaction, { loading }] = useUpdateDealTransactionMutation({
    refetchQueries: ['dealTransactions', 'cardTotals'],
  });
  const { refetch: refetchTransaction } = useDealTransactionsQuery();
  const { data: dealTransaction, loading: loadingTransaction } =
    useDealTransactionQuery({
      variables: { id },
      fetchPolicy: 'network-only',
      onError: (e) => {
        console.log(e);
      },
    });
  const { handleUpdate: updateFiles } = useUpdateFiles({
    ref: 'api::deal-transaction.deal-transaction',
  });

  const dealTransactionAttributes =
    dealTransaction?.dealTransaction?.data?.attributes;

  const defaultOptions = useMemo(() => {
    return {
      dueDate: dayjs(dealTransactionAttributes?.dueDate),
      summary: dealTransactionAttributes?.summary,
      repetitive: dealTransactionAttributes?.repetitive,
      status: dealTransactionAttributes?.status,
      note: dealTransactionAttributes?.note,
      paid: dealTransactionAttributes?.paid,
      paymentMethod: dealTransactionAttributes?.paymentMethod?.data?.id,
      files: dealTransactionAttributes?.files?.data.map(transformGqlFileToAntd),
    };
  }, [dealTransactionAttributes]);

  const handleEditDealTransaction = useCallback(
    async (values: DealTransactionFormValues) => {
      const { files, ...rest } = values;

      await updateTransaction({
        variables: {
          id: id,
          input: {
            ...rest,
          },
        },
        onCompleted: async () => {
          if (files) await updateFiles(files, defaultOptions?.files, id);

          message?.open('success', 'Transaction updated successfully!');
          form.resetFields();
          onCancel();
        },
        onError: () => message?.open('error'),
      }).then(() => refetchTransaction());
    },
    [
      form,
      id,
      message,
      onCancel,
      refetchTransaction,
      updateTransaction,
      defaultOptions?.files,
      updateFiles,
    ],
  );

  return loadingTransaction ? (
    <Loader spinning />
  ) : (
    <Form.Provider onFormFinish={handleFormFinish}>
      <DrawerFormWrapper
        requiredMark={false}
        onFinish={handleEditDealTransaction}
        onCancel={onCancel}
        initialValues={defaultOptions}
        form={form}
        name={'basicForm'}
        primaryAction={loading ? 'Loading...' : 'Edit Transaction'}
      >
        <DealTransactionInputsList
          form={form}
          handleChange={handleChange}
          type={'update'}
          specialInitValues={{
            files: defaultOptions?.files,
          }}
        />
      </DrawerFormWrapper>
    </Form.Provider>
  );
};

export default EditDealTransactionForm;
