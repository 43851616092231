export const checkPasswordRules = (password: string): string[] => {
  const messages = [];
  if (password.length < 12) {
    messages.push('Password must be at least 12 characters long');
  }

  if (!/[a-z]/.test(password)) {
    messages.push('Password must contain at least one lowercase letter');
  }

  if (!/[A-Z]/.test(password)) {
    messages.push('Password must contain at least one uppercase letter');
  }

  if (!/[0-9]/.test(password)) {
    messages.push('Password must contain at least one number');
  }

  if (!/[!@#$%^&*]/.test(password)) {
    messages.push('Password must contain at least one special character');
  }

  return messages;
};
