import { PropsWithChildren, useCallback, useEffect } from 'react';

import { Form, Input } from 'antd';

import { ObjectValues } from '@helpers/types';

import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { FilterFieldType } from '@filters/types';

import CheckboxGroup from '@components/reports/CheckboxGroup';
import DrawerFormWrapper from '@crm/formWrapper';
import { ColumnItem } from '@filters/hooks/useDrawerFilter';

import { NamePath } from 'antd/es/form/interface';

type FormValues<T extends FilterFieldType = FilterFieldType> = ObjectValues<T>;

interface Props<T extends FilterFieldType = FilterFieldType>
  extends PropsWithChildren {
  handleSubmit: (values: T) => void;
  onCancel: () => void;
  withDefaultFilters?: boolean;
  initialValues?: Partial<T>;
  entityName?: string;
  entityTitle?: string;
  additionalColumns?: ColumnItem[];
}

export const FiltersForm = <T extends FilterFieldType = FilterFieldType>({
  onCancel,
  handleSubmit,
  initialValues,
  withDefaultFilters = true,
  additionalColumns,
  children,
  entityName,
  entityTitle,
}: Props<T>) => {
  const [form] = Form.useForm<T>();

  const dateCreated = Form.useWatch('createdAt', form);
  const name = Form.useWatch('name', form);
  const columns = Form.useWatch('columns', form);

  // TODO (Lesya): Use additional filters for this
  const namePlaceholder =
    entityName === 'employee'
      ? 'Search employee by first name, last name, or email'
      : `Search ${entityName} by full name`;

  const handleChange = useCallback(
    (fieldName: NamePath) => async (value: FormValues) => {
      form.setFieldValue(fieldName, value);
    },
    [form],
  );

  const handleFormSubmit = useCallback(
    async (values: T) => {
      handleSubmit(values);

      onCancel();
    },
    [handleSubmit, onCancel],
  );

  useEffect(() => {
    if (initialValues) {
      Object.entries(initialValues).forEach(([key, value]) => {
        if (!value) {
          form.setFieldValue(key as NamePath, value);
        }
      });
    }
  }, [form, initialValues]);

  return (
    <DrawerFormWrapper<T>
      onFinish={handleFormSubmit}
      onCancel={onCancel}
      form={form}
      primaryAction={'Apply'}
      initialValues={initialValues}
    >
      {withDefaultFilters &&
        entityName !== 'requests' &&
        entityName !== 'product' &&
        entityName !== 'contacts' && (
          <ExpandableFormItem
            name={'name'}
            label={entityTitle}
            defaultOpen={!!name}
          >
            <Input placeholder={namePlaceholder} />
          </ExpandableFormItem>
        )}
      {children}
      {withDefaultFilters && (
        <ExpandableFormItem
          label={'Date Created'}
          name={'createdAt'}
          defaultOpen={!!dateCreated}
          withDivider
        >
          <DateRangeInput
            onChange={handleChange('createdAt')}
            style={{ width: '100%' }}
            value={dateCreated}
          />
        </ExpandableFormItem>
      )}
      {additionalColumns && (
        <ExpandableFormItem
          label={'Additional Columns'}
          name={'columns'}
          defaultOpen={!!columns}
          withDivider
        >
          <CheckboxGroup
            onChange={
              handleChange('columns') as unknown as
                | ((checkedValue: any[]) => void)
                | undefined
            }
            additionalColumns={additionalColumns}
          />
        </ExpandableFormItem>
      )}
    </DrawerFormWrapper>
  );
};
