import React from 'react';

import { TransactionModalProvider } from '@components/accounting/transactions/DealTransactionDropdown/TransactionRefundModalContext';
import { DealTransactionDropdown } from '../../DealTransactionDropdown/DealTransactionDropdown';

interface DealTransactionRendererProps {
  value: string;
  attributes: DealTransactionFragment['attributes'];
}

const DealTransactionRenderer: React.FC<DealTransactionRendererProps> = ({
  value,
  attributes,
}) => {
  return +value ? (
    <TransactionModalProvider>
      <DealTransactionDropdown
        entityId={value}
        status={attributes?.status ?? 'Cancelled'}
        paid={attributes?.paid ?? 0}
        dealTransactionId={attributes?.dealTransactionId ?? ''}
        customerInfo={{
          name:
            attributes?.contact?.data?.attributes?.fullName ??
            attributes?.company?.data?.attributes?.name ??
            '',
          points:
            attributes?.contact?.data?.attributes?.points ??
            attributes?.company?.data?.attributes?.points ??
            0,
          sellingOrder:
            attributes?.sellingOrder?.data?.attributes?.orderId ?? '',
        }}
        customerIds={{
          companyId: attributes?.company?.data?.id ?? undefined,
          contactId: attributes?.contact?.data?.id ?? undefined,
        }}
        account={attributes?.chartAccount?.data?.attributes?.name ?? ''}
        orderType={
          attributes?.sellingOrder?.data?.attributes?.type ?? undefined
        }
      />
    </TransactionModalProvider>
  ) : (
    <div style={{ height: 30 }} />
  );
};

export default DealTransactionRenderer;
