import { ReactNode, createContext, useContext, useState } from 'react';

interface TransactionModalContextType {
  isOpen: boolean;
  isPointsRefundModalOpen: boolean;
  openRefundModal: () => void;
  openPointsRefundModal: () => void;
  closePointsRefundModal: () => void;
}

const TransactionRefundModalContext = createContext<
  TransactionModalContextType | undefined
>(undefined);

export const TransactionModalProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPointsRefundModalOpen, setIsPointsRefundModalOpen] = useState(false);

  const openRefundModal = () => setIsOpen(true);
  const openPointsRefundModal = () => {
    setIsPointsRefundModalOpen(true);
    setIsOpen(true);
  };
  const closePointsRefundModal = () => {
    setIsPointsRefundModalOpen(false);
    setIsOpen(false);
  };

  return (
    <TransactionRefundModalContext.Provider
      value={{
        isOpen,
        isPointsRefundModalOpen,
        openRefundModal,
        openPointsRefundModal,
        closePointsRefundModal,
      }}
    >
      {children}
    </TransactionRefundModalContext.Provider>
  );
};

export const useTransactionModal = () => {
  const context = useContext(TransactionRefundModalContext);
  if (!context) {
    throw new Error(
      'useTransactionModal must be used within a TransactionModalProvider',
    );
  }
  return context;
};
