import { AdditionalFieldProps } from '@filters/types';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import { OrderType } from '@helpers/enumTypes';
import { OrderTypeSelect } from '@ui/form';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import { FC } from 'react';
import EmployeeSelect from 'src/components/ui/form/EmployeeSelect';

export const SalesByItemCategoryAdditionalFields: FC<
  AdditionalFieldProps & { entityName?: string }
> = ({ values }) => {
  return (
    <>
      <ExpandableFormItem
        name={'locationIds'}
        label={'Business Location'}
        defaultOpen={!!values?.locationIds}
      >
        <BusinessLocationsSelect placeholder={'Choose location'} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'employee'}
        label={'Employee'}
        defaultOpen={!!values?.employee}
      >
        <EmployeeSelect shouldApplyDefaultValue={false} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'orderType'}
        label={'Order Type'}
        defaultOpen={!!values?.orderType}
      >
        <OrderTypeSelect
          customOrderTypes={[OrderType.SELL, OrderType.LAYAWAY]}
        />
      </ExpandableFormItem>
    </>
  );
};
