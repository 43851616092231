import { Icon } from '@assets/icon';
import { CustomButton } from '@ui/button/Button';

import { FC } from 'react';

interface IAddNoteBtn {
  onClick: (e?: any) => void;
  label?: string;
  expanded?: boolean;
}

export const AddNoteBtn: FC<IAddNoteBtn> = ({
  onClick,
  label = 'Add Note',
  expanded = false,
}) => {
  return (
    <CustomButton
      type={'link'}
      icon={
        expanded ? (
          <Icon type={'circle-remove'} />
        ) : (
          <Icon type={'plus-circle'} />
        )
      }
      onClick={onClick}
      paddingless
    >
      {label}
    </CustomButton>
  );
};
