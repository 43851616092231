import { InvoiceShippingContact } from '@components/contracts/invoices/types';
import { CustomSpace } from '@ui/space';
import TextInfoBlock from '@ui/text-info-block';
import { ConfigProvider } from 'antd';
import { FC } from 'react';

interface Props {
  dataCompany?: CompanyMinFragment;
  dataContact?: ContactMinFragment;
  shippingContact?: InvoiceShippingContact;
  title: string;
  clientName?: string;
  clientAddress?: string;
  clientPhone?: string;
  clientEmail?: string;
}

export const ContactInfoFields: FC<Props> = ({
  dataContact,
  dataCompany,
  shippingContact,
  title,
  clientName,
  clientAddress,
  clientPhone,
  clientEmail,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          paddingSM: 0,
          padding: 0,
        },
      }}
    >
      <CustomSpace
        block
        direction={'vertical'}
        size={12}
        style={{
          padding: 5,
          minHeight: '270px',
        }}
      >
        <CustomSpace block style={{ justifyContent: 'space-between' }}>
          <TextInfoBlock title={title} titleFontSize={16} />
        </CustomSpace>

        <CustomSpace block style={{ justifyContent: 'space-between' }}>
          <TextInfoBlock
            title={
              clientName ||
              dataContact?.attributes?.fullName ||
              dataCompany?.attributes?.name ||
              shippingContact?.name ||
              ''
            }
            titleFontSize={12}
          />
        </CustomSpace>
        <TextInfoBlock
          description={
            clientPhone ||
            dataContact?.attributes?.phoneNumber ||
            dataCompany?.attributes?.phoneNumber ||
            shippingContact?.phoneNumber ||
            ''
          }
          titleFontSize={12}
        />

        <TextInfoBlock
          description={
            clientAddress ||
            dataContact?.attributes?.address ||
            dataCompany?.attributes?.address ||
            shippingContact?.address ||
            ''
          }
          titleFontSize={12}
        />
        <TextInfoBlock
          description={
            clientEmail ||
            dataContact?.attributes?.email ||
            dataCompany?.attributes?.email ||
            shippingContact?.email ||
            ''
          }
          titleFontSize={12}
        />
      </CustomSpace>
    </ConfigProvider>
  );
};
