import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const DefaultTermsPage = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/defaultTermsPage'),
);
const Dashboard = lazy<FC>(() => import('@/pages/dashboard'));
const Stores = lazy<FC>(() => import('@/pages/dashboard/stores'));
const Settings = lazy<FC>(() => import('@/pages/dashboard/settings'));
const Contacts = lazy<FC>(
  () => import('@/pages/dashboard/settingsRoutes/contacts'),
);
const CompaniesImport = lazy<FC>(
  () =>
    import('@pages/dashboard/settingsRoutes/companiesPages/companiesImport'),
);
const ContactsImport = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/contactsPages/contactsImport'),
);
const RelationsImport = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/contactsPages/relationsImport'),
);
const WishlistImport = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/contactsPages/wishlistImport'),
);
const Companies = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/companies'),
);

const Reviews = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/reviews'),
);

const Inventory = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/inventory'),
);

const Orders = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/selling'),
);

const InventoryImport = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/inventoryPages/productsImport'),
);

const Pos = lazy<FC>(() => import('@/pages/dashboard/settingsRoutes/pos'));
const OrdersImport = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/sellingPages/ordersImport'),
);

const GeneralInfo = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/generalInfo'),
);

const ImportingHistory = lazy<FC>(
  () => import('@pages/dashboard/settingsRoutes/importingHistory'),
);

const {
  index,
  stores,
  settings: {
    index: settingsIndex,
    contactsSettings: {
      index: contactIndex,
      contactsImport,
      relationsImport,
      wishlistImport,
    },
    companiesSettings: { index: companiesIndex, companiesImport },
    reviewsSettings: { index: reviews },
    inventorySettings: { index: inventoryIndex, inventoryImport },
    sellingSettings: { index: orderIndex, purchaseOrdersImport },
    generalInfo: { index: generalIngo, terms, importingHistory },
  },
} = ROUTES.tenant.dashboard;

export const dashboardRoutes: RouteObject = {
  path: index,
  children: [
    {
      Component: Dashboard,
      index: true,
    },
    {
      Component: Stores,
      path: stores,
    },
    {
      Component: Settings,
      path: settingsIndex,
      children: [
        {
          index: true,
          element: <Navigate to={generalIngo} />,
        },
        {
          Component: GeneralInfo,
          path: generalIngo,
        },
        {
          Component: DefaultTermsPage,
          path: terms,
        },
        {
          Component: ImportingHistory,
          path: importingHistory,
        },
        {
          Component: Contacts,
          path: contactIndex,
          children: [
            {
              path: contactsImport,
              Component: ContactsImport,
            },

            {
              path: relationsImport,
              Component: RelationsImport,
            },
            {
              path: wishlistImport,
              Component: WishlistImport,
            },
          ],
        },
        {
          Component: Companies,
          path: companiesIndex,
          children: [
            {
              path: companiesImport,
              Component: CompaniesImport,
            },
          ],
        },
        {
          Component: Reviews,
          path: reviews,
        },
        {
          Component: Inventory,
          path: inventoryIndex,
          children: [
            {
              path: inventoryImport,
              Component: InventoryImport,
            },
          ],
        },
        {
          Component: Pos,
          path: 'pos',
        },
        {
          Component: Orders,
          path: orderIndex,
          children: [
            {
              path: purchaseOrdersImport,
              Component: OrdersImport,
            },
          ],
        },
      ],
    },
  ],
};
