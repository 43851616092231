import { useGenerateInvoiceMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useDrawer } from '@drawer/drawerContext';
import { useTenantRoutes } from '@router/routes';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

export const useCreateInvoice = (orderId: string, shouldRedirect = true) => {
  const message = useStatusMessage();
  const [createInvoice, { loading, error }] = useGenerateInvoiceMutation();
  const {
    contracts: { invoices },
  } = useTenantRoutes();
  const navigate = useNavigate();
  const { closeDrawer } = useDrawer();

  const handleCreateInvoice = useCallback(async () => {
    await createInvoice({
      variables: {
        input: {
          orderId: orderId,
        },
      },
      onCompleted: (data) => {
        if (data && shouldRedirect) {
          message.open('success', 'Invoice created successfully!');
          closeDrawer();
          navigate(`${invoices.index}/${data.generateInvoice?.uuid}`);
        }
      },
      onError: () => message.open('error'),
      refetchQueries: ['orderById'],
    });
  }, [
    createInvoice,
    orderId,
    message,
    closeDrawer,
    invoices.index,
    navigate,
    shouldRedirect,
  ]);

  return {
    create: handleCreateInvoice,
    loading,
    error,
  };
};
