import { FC } from 'react';

import { AssigneesSelect } from '@ui/form/AssigneesSelect/AssigneesSelect';
import { PrioritySelect } from '@ui/form/PrioritySelect';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { OrderIdSelect } from '@components/accounting/transactions/ui/selects/OrderIdSelect';
import { TaskLocationSelect } from '@components/tasks/TaskLocationSelect/TaskLocationSelect';
import { ApprovalMethodSelect } from '@components/tasks/TaskMethodSelect/ApprovalMethodSelect';
import { TaskStageSelect } from '@components/tasks/TaskStageSelect/TaskStageSelect';
import { TaskTypeSelect } from '@components/tasks/TaskTypeSelect/TaskTypeSelect';
import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { CompaniesSelect } from '@ui/form';
import { AllCustomersSelect } from '@ui/form/AllCustomersSelect';
import { CompanySelect } from '@ui/form/CompanySelect';

export const TasksAdditionalFields: FC<AdditionalFieldProps> = ({ values }) => {
  return (
    <>
      <ExpandableFormItem
        name={'taskStage'}
        label={'Stage'}
        defaultOpen={!!values?.taskStage}
        withDivider
      >
        <TaskStageSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'priority'}
        label={'Priority'}
        defaultOpen={!!values?.priority}
        withDivider
      >
        <PrioritySelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'assignees'}
        label={'Assignees'}
        defaultOpen={!!values?.assignees}
        withDivider
      >
        <AssigneesSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'orderId'}
        label={'Order'}
        defaultOpen={!!values?.orderId}
        withDivider
      >
        <OrderIdSelect isMultiple />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Due Date'}
        name={'dueDate'}
        defaultOpen={!!values?.dueDate}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'For Customer'}
        name={'customer'}
        defaultOpen={!!values?.customer}
        withDivider
      >
        <AllCustomersSelect initialValue={values?.customer} />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'taskType'}
        label={'Task Type'}
        defaultOpen={!!values?.taskType}
        withDivider
      >
        <TaskTypeSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'taskLocation'}
        label={'Place'}
        defaultOpen={!!values?.taskLocation}
        withDivider
      >
        <TaskLocationSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'approvalMethod'}
        label={'Approval Method'}
        defaultOpen={!!values?.approvalMethod}
        withDivider
      >
        <ApprovalMethodSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'forCompany'}
        label={'For Company'}
        defaultOpen={!!values?.forCompany}
        withDivider
      >
        <CompaniesSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'currentVendor'}
        label={'With Vendor'}
        defaultOpen={!!values?.currentVendor}
        withDivider
      >
        <CompanySelect isVendor />
      </ExpandableFormItem>
    </>
  );
};
