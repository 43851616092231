export const getFirstNameCode = (name: string) => {
  const firstName = name.split(' ')[0];
  return firstName.toUpperCase().slice(0, 4);
};

export const getLastNameCode = (name: string) => {
  const words = name.split(' ');
  const lastName = words.length > 1 ? words[1] : words[0];
  return lastName.toUpperCase().slice(0, 4);
};
