import { Icon, IconType } from '@assets/icon';
import { Loader } from '@components/layout/MainLayout';
import { AnyObject } from '@helpers/types';
import { ROUTES } from '@router/routes';
import styles from '@ui/navigation/menu/index.module.scss';
import { MenuProps } from 'antd';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { CHECK_ROUTES } from '@inventory/inventoryManagement/hooks/useConfirmationBeforeBackRoute';

import { MouseEvent } from 'react';

const { tenant } = ROUTES;

export const getMenuItems = (
  collapsed: boolean,
  routes: (typeof ROUTES)['tenant'],
  onClick: () => void,
  permissions: AnyObject | undefined,
  handleCheckPaths?: (link: string) => void,
  pathname?: string,
  memberStatusAvailable?: boolean | undefined | null,
): MenuProps['items'] => {
  const getIcon = (type: IconType) =>
    permissions?.loadingPermissions ? (
      <Loader size={'small'} />
    ) : (
      <Icon type={type} />
    );

  const getLabel = (name: string, route: string) => {
    const handleLinkClick = (event: MouseEvent) => {
      if ([...CHECK_ROUTES].some((route) => pathname?.includes(`/${route}/`))) {
        event.preventDefault();
        handleCheckPaths && handleCheckPaths(route);
      }
    };
    return permissions?.loadingPermissions ? (
      <Loader size={'small'} />
    ) : (
      <NavLink onClick={handleLinkClick} to={route}>
        {name}
      </NavLink>
    );
  };

  const getClassnames = (customPermission: boolean) =>
    classNames(styles.item, {
      [styles.hidden]: collapsed,
      [styles.disabled]: !customPermission && !permissions?.loadingPermissions,
      [styles.disabledInteractive]: memberStatusAvailable,
    });

  // TODO: Valentyn V. - return temporary hidden features/modules
  return [
    {
      key: tenant.dashboard.index,
      icon: getIcon('dashboard'),
      label: getLabel('Dashboard', routes.dashboard.index),
      className: getClassnames(!!permissions?.hasDashboardPermission),
    },
    {
      key: tenant.crm.index,
      icon: getIcon('crm'),
      label: getLabel('CRM', routes.crm.index),
      className: getClassnames(!!permissions?.hasCrmPermission),
    },
    {
      key: tenant.hr.index,
      icon: getIcon('menu-hr'),
      label: getLabel('HR', routes.hr.index),
      className: getClassnames(!!permissions?.hasHrPermission),
    },
    {
      key: tenant.tasks,
      icon: getIcon('task'),
      label: getLabel('Task Management', tenant.tasks),
      className: getClassnames(!!permissions?.hasTaskManagementPermission),
    },
    // {
    //   key: tenant.scheduling.index,
    //   icon: getIcon('scheduling'),
    //   label: getLabel('Employee Scheduling', tenant.scheduling.index),
    //   className: getClassnames(!!permissions?.hasSchedulingPermission),
    // },
    {
      key: tenant.reports.index,
      icon: getIcon('ticket'),
      label: getLabel('Reports', tenant.reports.index),
      className: getClassnames(!!permissions?.hasReportsPermission),
    },
    {
      key: tenant.selling.index,
      icon: getIcon('cart'),
      label: getLabel('Selling', routes.selling.index),
      className: getClassnames(!!permissions?.hasSellingPermission),
    },
    {
      key: tenant.inventory.index,
      icon: getIcon('inventory'),
      label: getLabel('Inventory', routes.inventory.index),
      className: getClassnames(!!permissions?.hasInventoryPermission),
    },
    {
      key: tenant.accounting.index,
      icon: getIcon('accounting'),
      label: getLabel('Accounting', routes.accounting.index),
      className: getClassnames(!!permissions?.hasAccountingPermission),
    },
    {
      key: tenant.contracts.index,
      icon: getIcon('contracts'),
      label: getLabel('Contracts', routes.contracts.index),
      className: getClassnames(!!permissions?.hasContractsPermission),
    },
    {
      key: tenant.marketing.index,
      icon: getIcon('speaker'),
      label: getLabel('Marketing', routes.marketing.index),
      className: getClassnames(!!permissions?.hasMarketingPermission),
    },
    // {
    //   key: 'websiteEditor',
    //   icon: getIcon('website'),
    //   label: getLabel('Website editor', routes.websiteEditor.index),
    //   className: getClassnames(!!permissions?.hasWebsiteEditorPermission),
    // },
    {
      key: tenant.location.index,
      icon: getIcon('map'),
      label: getLabel('Location', routes.location.index),
      className: getClassnames(!!permissions?.hasLocationPermission),
    },
    {
      key: '9',
      type: 'divider',
      className: classNames(styles.divider, {
        [styles.disabled]: !permissions?.hasIntegrationPermission,
      }),
    },
    {
      key: '10',
      icon: getIcon('integration'),
      label: getLabel('Integrations', routes.settings.integrations.index),
      className: getClassnames(!!permissions?.hasIntegrationPermission),
    },
  ];
};
