import { formatToCurrency } from '@helpers/formatter';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';

type Props = {
  itemName: Maybe<string>;
  itemPrice: Maybe<number>;
  itemNotes: Maybe<string>;
  isPriceVisible?: boolean;
  currency?: string;
};

export const RepairTicketItem: FC<Props> = ({
  itemName,
  itemPrice,
  itemNotes,
  isPriceVisible = true,
  currency,
}) => {
  return (
    <Col span={24}>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Typography.Title level={4}>{itemName}</Typography.Title>
            </Col>
            <Col
              span={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Row gutter={10}>
                <Col>
                  {isPriceVisible && (
                    <Typography.Title level={4}>
                      {formatToCurrency(itemPrice, currency)}
                    </Typography.Title>
                  )}
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'block',
                      width: 20,
                      height: 20,
                      border: '2px solid black',
                    }}
                  ></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Typography.Text
            style={{
              color: '#959595',
              fontSize: 12,
              whiteSpace: 'break-spaces',
            }}
          >
            {itemNotes}
          </Typography.Text>
        </Col>
      </Row>
    </Col>
  );
};
