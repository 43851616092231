import { SendFormType } from '@components/contracts/forms/SendLinkForm';
import styles from '@components/contracts/ui/index.module.scss';
import { CustomTextArea } from '@components/marketing/preview/StepForms/SmsStepForm/CustomTextArea';
import { CustomForm } from '@form';
import { useCustomForm } from '@form/hooks/useCustomForm';
import { CountryPhoneInput } from '@form/inputs/countryPhone/CountryPhoneInput';
import { CustomFormItem } from '@form/item/FormItem';
import { CustomButton } from '@ui/button/Button';
import CustomSquareCheckbox from '@ui/checkbox';
import { CustomDivider } from '@ui/divider/Divider';
import { ContactsSelect } from '@ui/form';
import { Flex, Form, Input } from 'antd';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { FC, useState } from 'react';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

interface Props {
  handleSend: (values: SendFormType) => Promise<void>;
  customTags?: { title: string; value: string }[];
}

export const SendLinkByEmailForm: FC<Props> = ({ handleSend, customTags }) => {
  const [form] = useCustomForm<SendFormType>();
  const [selectedContact, setSelectedContact] =
    useState<Maybe<ContactFragment>>(null);
  const [sendBySms, setSendBySms] = useState(false);
  const [sendToCustomEmail, setSendToSubjectEmail] = useState(false);
  const [sendToCustomPhone, setSendToSubjectPhone] = useState(false);
  const [useCustomSmsContactContent, setUseCustomSmsContactContent] =
    useState(false);
  const [useCustomSmsSubjectContent, setUseCustomSmsSubjectContent] =
    useState(false);

  const phone = Form.useWatch('subjectPhone', form);

  const handleSubmit = async (values: SendFormType) => {
    if (!isPhoneValid(phone as string)) {
      values.subjectPhone = undefined;
    }

    if (values.customSmsContactContent === '') {
      values.customSmsContactContent = undefined;
    }

    if (values.customSmsSubjectContent === '') {
      values.customSmsSubjectContent = undefined;
    }

    await handleSend(values);
    form.resetFields();
    setUseCustomSmsContactContent(false);
    setUseCustomSmsSubjectContent(false);
    setSelectedContact(null);
  };

  const defaultCustomTags = [{ title: 'Link', value: 'link' }];

  return (
    <CustomForm
      form={form}
      className={styles.createContractForm}
      onFinish={handleSubmit}
    >
      <Flex vertical align={'start'}>
        <CustomFormItem
          style={{ width: '100%' }}
          name={'contact'}
          label={'Choose contact'}
        >
          <ContactsSelect setSelectedContactData={setSelectedContact} />
        </CustomFormItem>

        {selectedContact && (
          <>
            <CustomFormItem
              style={{ width: '100%' }}
              name={'sendByEmail'}
              valuePropName={'checked'}
            >
              <CustomSquareCheckbox>
                Send to {selectedContact?.attributes?.email}
              </CustomSquareCheckbox>
            </CustomFormItem>

            <CustomFormItem
              style={{ width: '100%' }}
              name={'sendBySms'}
              valuePropName={'checked'}
            >
              <CustomSquareCheckbox
                onChange={(e) => setSendBySms(e.target.checked)}
              >
                Send SMS to {selectedContact?.attributes?.phoneNumber}
              </CustomSquareCheckbox>
            </CustomFormItem>

            {sendBySms && (
              <CustomFormItem
                style={{ width: '100%' }}
                name={'useCustomSmsContactContent'}
                valuePropName={'checked'}
              >
                <CustomSquareCheckbox
                  onChange={(e) =>
                    setUseCustomSmsContactContent(e.target.checked)
                  }
                >
                  Use Custom SMS Content
                </CustomSquareCheckbox>
              </CustomFormItem>
            )}
            {useCustomSmsContactContent && (
              <CustomFormItem
                style={{ width: '100%' }}
                name={'customSmsContactContent'}
                label={'Custom SMS Content'}
              >
                <CustomTextArea
                  customTags={[{ title: 'Link', value: 'link' }]}
                />
              </CustomFormItem>
            )}
          </>
        )}

        <CustomDivider style={{ marginTop: 0 }}></CustomDivider>
        <CustomFormItem
          style={{ width: '100%' }}
          name={'sendToSubjectEmail'}
          valuePropName={'checked'}
        >
          <CustomSquareCheckbox
            onChange={(e) => setSendToSubjectEmail(e.target.checked)}
          >
            Send to Custom Email
          </CustomSquareCheckbox>
        </CustomFormItem>
        {sendToCustomEmail && (
          <CustomFormItem
            style={{ width: '100%' }}
            name={'subjectEmail'}
            label={'Enter Custom Email'}
          >
            <Input type={'email'} />
          </CustomFormItem>
        )}

        <CustomFormItem
          style={{ width: '100%' }}
          name={'sendToSubjectPhone'}
          valuePropName={'checked'}
        >
          <CustomSquareCheckbox
            onChange={(e) => setSendToSubjectPhone(e.target.checked)}
          >
            Send to Custom Phone
          </CustomSquareCheckbox>
        </CustomFormItem>
        {sendToCustomPhone && (
          <>
            <CustomFormItem
              style={{ width: '100%' }}
              name={'subjectPhone'}
              label={'Enter Custom Phone Number'}
            >
              <CountryPhoneInput />
            </CustomFormItem>
            <CustomFormItem
              style={{ width: '100%' }}
              name={'useCustomSmsSubjectContent'}
              valuePropName={'checked'}
            >
              <CustomSquareCheckbox
                onChange={(e) =>
                  setUseCustomSmsSubjectContent(e.target.checked)
                }
              >
                Use Custom SMS Content
              </CustomSquareCheckbox>
            </CustomFormItem>
            {useCustomSmsSubjectContent && (
              <CustomFormItem
                style={{ width: '100%' }}
                name={'customSmsSubjectContent'}
                label={'Custom SMS Content'}
              >
                <CustomTextArea
                  customTags={customTags ? customTags : defaultCustomTags}
                />
              </CustomFormItem>
            )}
          </>
        )}
      </Flex>
      <CustomButton size={'large'} type={'primary'} block htmlType={'submit'}>
        Send
      </CustomButton>
    </CustomForm>
  );
};
