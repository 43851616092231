import { useOrderByIdQuery, useUpdateOrderMutation } from '@/graphql';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

import { get } from 'lodash';
import { useCallback } from 'react';

export const useOrderRequests = (
  orderId: string,
): {
  orderData: Maybe<OrderByIdQuery>;
  loadingOrder: boolean;
  updateOrderLoading: boolean;
  handleUpdateOrder: (values: string) => void;
} => {
  const message = useStatusMessage();
  const { data, loading: loadingOrder } = useOrderByIdQuery({
    variables: { orderId },
  });

  const [updateOrder, { loading: updateOrderLoading }] = useUpdateOrderMutation(
    {
      onCompleted: () => {
        message.open(
          'success',
          `Order ${get(
            data,
            'orders.data[0].attributes.orderUuid',
            '',
          )} was successfully updated!`,
        );
      },
      onError: () => {
        message.open(
          'error',
          `Order ${get(
            data,
            'orders.data[0].attributes.orderUuid',
            '',
          )} is not updated!`,
        );
      },
    },
  );

  const handleUpdateOrder = useCallback(
    async (value: string) => {
      await updateOrder({
        variables: {
          id: get(data, 'orders.data[0].id', ''),
          input: {
            inputInvoiceNum: value,
          },
        },
      });
    },
    [data, updateOrder],
  );

  return {
    orderData: data,
    loadingOrder,
    updateOrderLoading,
    handleUpdateOrder,
  };
};
