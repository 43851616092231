export const MERGE_TAGS_LIST = [
  { title: 'Email', value: 'email' },
  {
    title: 'First Name',
    value: 'firstName',
  },
  {
    title: 'Full Name',
    value: 'fullName',
  },
  {
    title: 'Last Name',
    value: 'lastName',
  },
  { title: 'Phone', value: 'phoneNumber' },
  { title: 'Birthday', value: 'birthdayDate' },
  { title: 'Points', value: 'points' },
  { title: 'Address', value: 'address' },
];
