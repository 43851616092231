import { useOrderByIdLazyQuery } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { OrderDrawerTitle } from '@components/selling/order-management/OrderCard/OrderDrawerTitle';
import { OrderForm } from '@components/selling/order-management/OrderForm';
import { OrderInvoiceActions } from '@components/selling/pos/orders-list/OrderInvoiceActions';
import { useDrawer } from '@drawer/drawerContext';
import { CustomButton } from '@ui/button/Button';
import useTableElementRoute from '@ui/table/hooks/useTableElementRoute';
import { Col, Row } from 'antd';
import { CSSProperties, FC, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router';

interface TableElementLinkProps {
  relationUuid?: string | null | undefined;
  eventType: string | undefined;
  relationId?: string;
  children?: ReactNode;
  customStyles?: CSSProperties;
}

export const TableElementLink: FC<TableElementLinkProps> = ({
  relationUuid,
  eventType,
  relationId,
  children,
  customStyles,
}) => {
  const navigate = useNavigate();
  const subPath = useTableElementRoute(eventType);
  const message = useStatusMessage();

  const { openDrawer, closeDrawer } = useDrawer();

  const [fetch, { data: orderData }] = useOrderByIdLazyQuery({
    variables: { orderId: String(relationUuid) },
    onError: () => message.open('error', 'Error fetching order'),
  });

  const customerName =
    orderData?.orders?.data?.[0]?.attributes?.contact?.data?.attributes
      ?.fullName ||
    orderData?.orders?.data?.[0]?.attributes?.company?.data?.attributes?.name ||
    '';

  const showOrderInfo = useCallback(async () => {
    if (eventType === 'sold' || eventType === 'receive') {
      await fetch();
      openDrawer(
        <OrderForm
          orderId={orderData?.orders?.data?.[0]?.id as string}
          orderUuid={relationUuid ?? ''}
        />,
        <OrderDrawerTitle
          orderId={relationUuid as string}
          createAt={
            orderData?.orders?.data?.[0]?.attributes?.customCreationDate
          }
          orderType={
            orderData?.orders?.data?.[0]?.attributes?.type as EnumOrderType
          }
        />,
        {
          drawerWidth: 720,
          extraComponent: (
            <Row align={'middle'} justify={'space-between'} gutter={12}>
              <Col>
                <OrderInvoiceActions
                  orderId={orderData?.orders?.data?.[0]?.id as string}
                  orderUuid={relationUuid ?? ''}
                  customerName={customerName}
                />
              </Col>
            </Row>
          ),
        },
      );
    } else {
      navigate(relationUuid ? `${subPath}/${relationUuid}` : `${subPath}`);
      closeDrawer();
    }
  }, [
    eventType,
    fetch,
    openDrawer,
    orderData?.orders?.data,
    relationUuid,
    customerName,
    navigate,
    subPath,
    closeDrawer,
  ]);

  return eventType === 'sold' && !relationId ? (
    <></>
  ) : (
    <CustomButton
      type={'link'}
      paddingless
      style={{
        fontSize: 12,
        width: '100%',
        justifyContent: 'left',
        height: 'unset',
        whiteSpace: 'normal',
        textAlign: 'left',
        ...customStyles,
      }}
      onClick={showOrderInfo}
    >
      {children ? children : 'View event'}
    </CustomButton>
  );
};
