export const getInvoiceInfo = (data: OrderByIdQuery | undefined) => {
  const order = data?.orders?.data?.[0];
  const invoice = order?.attributes?.invoice;
  const hasInvoice = !!invoice?.data?.attributes?.invoiceId;
  const attachedFileId =
    invoice?.data?.attributes?.fileItem?.data?.attributes?.attachedFile?.data
      ?.id;
  const fileItemId = invoice?.data?.attributes?.fileItem?.data?.id;

  return {
    order,
    invoice,
    hasInvoice,
    attachedFileId,
    fileItemId,
  };
};
