import { InvoiceTableCell } from '@components/contracts/invoices/view/InvoiceTableCell';

import RenderInventoryItem from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderInventoryItem';
import RenderShopItem from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderTableShopItem';
import { getCdnImage } from '@helpers/getCdnImage';
import { baseColumnFactory } from '@ui/table/column';
import { Flex, TableColumnsType, Typography } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';

type possibleInvoiceTableTypes =
  | ProductOrderItemFragment
  | ClassOrderItemFragment
  | ServiceOrderItemFragment
  | MembershipOrderItemFragment
  | CompositeProductOrderItemFragment;

export const invoiceTableColumns = (
  type: 'invoice' | 'other' = 'invoice',
  isImageShown?: boolean,
  currency?: string,
  isDiscountShown = true,
): TableColumnsType<possibleInvoiceTableTypes> => {
  const columns: TableColumnsType<possibleInvoiceTableTypes> = [
    baseColumnFactory<possibleInvoiceTableTypes>({
      title: 'DESCRIPTION',
      dataIndex: ['attributes'],
      render: (_, { attributes }) => {
        const getName = () => {
          switch (attributes?.__typename) {
            case 'ProductOrderItem': {
              return type === 'invoice' && !isImageShown ? (
                attributes?.product?.data?.attributes?.product?.data?.attributes
                  ?.name
              ) : (
                <RenderInventoryItem
                  avatarName={
                    attributes?.product?.data?.attributes?.product?.data
                      ?.attributes?.name ?? ''
                  }
                  avatarSrc={getCdnImage(
                    attributes?.product?.data?.attributes?.product?.data
                      ?.attributes?.files?.data[0]?.attributes?.url,
                  )}
                  itemType={'products'}
                  itemUuid={
                    attributes?.product?.data?.attributes?.product?.data
                      ?.attributes?.uuid ?? ''
                  }
                />
              );
            }
            case 'CompositeProductOrderItem': {
              const avatarSrc =
                get(
                  attributes,
                  'compositeProduct.data.attributes.compositeProduct.data.attributes.files.data[0].attributes.url',
                  '',
                ) ||
                get(
                  attributes,
                  'compositeProduct.data.attributes.compositeProduct.data.attributes.products.data[0].attributes.files.data[0].attributes.url',
                  '',
                ) ||
                get(
                  attributes,
                  'compositeProduct.data.attributes.compositeProduct.data.attributes.products.data[1].attributes.files.data[0].attributes.url',
                  '',
                );

              return type === 'invoice' && !isImageShown ? (
                attributes?.compositeProduct?.data?.attributes?.compositeProduct
                  ?.data?.attributes?.name
              ) : (
                <RenderInventoryItem
                  avatarName={
                    attributes?.compositeProduct?.data?.attributes
                      ?.compositeProduct?.data?.attributes?.name ?? ''
                  }
                  itemType={'composite_products'}
                  avatarSrc={getCdnImage(avatarSrc)}
                />
              );
            }
            case 'ServiceOrderItem': {
              return type === 'invoice' && !isImageShown ? (
                attributes?.service?.data?.attributes?.serviceLocationInfo?.data
                  ?.attributes?.service?.data?.attributes?.name
              ) : (
                <RenderShopItem
                  attributes={attributes as ServiceOrderItem}
                  itemType={'services'}
                />
              );
            }
            case 'MembershipOrderItem': {
              return type === 'invoice' ? (
                attributes?.membership?.data?.attributes?.name
              ) : (
                <RenderShopItem
                  attributes={attributes as MembershipOrderItem}
                  itemType={'memberships'}
                />
              );
            }
            case 'ClassOrderItem': {
              return type === 'invoice' ? (
                attributes?.class?.data?.attributes?.classLocationInfo?.data
                  ?.attributes?.class?.data?.attributes?.name
              ) : (
                <RenderShopItem
                  attributes={attributes as ClassOrderItem}
                  itemType={'classes'}
                />
              );
            }
            default: {
              return;
            }
          }
        };
        const shouldShowNote =
          !!attributes?.note &&
          (type !== 'invoice' ||
            (type === 'invoice' && attributes?.isShowInvoiceNote));

        return (
          <Flex vertical gap={3}>
            {type === 'invoice' ? (
              <Typography.Text style={{ fontWeight: 500 }}>
                {getName()}
              </Typography.Text>
            ) : (
              getName()
            )}
            {shouldShowNote && (
              <Typography.Text
                style={{ color: '#959595', whiteSpace: 'break-spaces' }}
              >
                {attributes?.note}
              </Typography.Text>
            )}
          </Flex>
        );
      },
      width: '45%',
    }),
    baseColumnFactory({
      title: 'QTY',
      dataIndex: ['attributes', 'quantity'],
      width: '10%',
    }),
    baseColumnFactory({
      title: 'UNIT PRICE',
      dataIndex: ['attributes', 'price'],
      render: (value) => (
        <InvoiceTableCell count={value as number} currency={currency} />
      ),
      width: '15%',
    }),
    baseColumnFactory({
      title: 'AMOUNT',
      dataIndex: ['attributes'],
      render: (value) => {
        const { quantity, price, discountAmountPerItem } = value;
        return (
          <InvoiceTableCell
            count={price * quantity - discountAmountPerItem}
            currency={currency}
          />
        );
      },
      width: '15%',
    }),
  ];

  if (isDiscountShown) {
    columns.splice(
      3,
      0,
      baseColumnFactory({
        title: 'DISCOUNT',
        dataIndex: ['attributes', 'discountAmountPerItem'],
        render: (value) => (
          <InvoiceTableCell count={value as number} currency={currency} />
        ),
        width: '15%',
      }),
    );
  }

  return columns;
};

export const rentDataColumns: TableColumnsType<possibleInvoiceTableTypes> = [
  baseColumnFactory({
    title: 'START',
    dataIndex: ['attributes', 'rentStart'],
    render: (value) => dayjs(value).format('MMM DD, YYYY'),
    width: '10%',
  }),
  baseColumnFactory({
    title: 'END',
    dataIndex: ['attributes', 'rentEnd'],
    render: (value) => dayjs(value).format('MMM DD, YYYY'),
    width: '10%',
  }),
];

export const repairTicketTableColumns =
  (): TableColumnsType<possibleInvoiceTableTypes> => [
    baseColumnFactory<possibleInvoiceTableTypes>({
      title: 'DESCRIPTION',
      dataIndex: ['attributes'],
      render: (_, { attributes }) => {
        const getName = () => {
          switch (attributes?.__typename) {
            case 'ProductOrderItem': {
              return attributes?.product?.data?.attributes?.product?.data
                ?.attributes?.name;
            }
            case 'CompositeProductOrderItem': {
              return attributes?.compositeProduct?.data?.attributes
                ?.compositeProduct?.data?.attributes?.name;
            }
            case 'ServiceOrderItem': {
              return attributes?.service?.data?.attributes?.serviceLocationInfo
                ?.data?.attributes?.service?.data?.attributes?.name;
            }
            case 'ClassOrderItem': {
              return attributes?.class?.data?.attributes?.classLocationInfo
                ?.data?.attributes?.class?.data?.attributes?.name;
            }
            case 'MembershipOrderItem': {
              return attributes?.membership?.data?.attributes?.name;
            }
            default: {
              return;
            }
          }
        };
        const shouldShowNote =
          !!attributes?.note && attributes?.isShowInvoiceNote;

        return (
          <Flex vertical gap={3}>
            <Typography.Text style={{ fontWeight: 500 }}>
              {getName()}
            </Typography.Text>
            {shouldShowNote && (
              <Typography.Text
                style={{ color: '#959595', whiteSpace: 'break-spaces' }}
              >
                {attributes?.note}
              </Typography.Text>
            )}
          </Flex>
        );
      },
      width: '45%',
    }),
    baseColumnFactory({
      title: 'QTY',
      dataIndex: ['attributes', 'quantity'],
      width: '10%',
    }),
  ];
