import { FC, useCallback, useEffect, useMemo } from 'react';

import { SelectProps } from 'antd/lib';

import {
  useCreatePaymentMethodMutation,
  usePaymentMethodQuery,
} from '@/graphql';

import { AddNewSelectButton } from '@ui/form/AddNewSelectButton';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

import { useStatusMessage } from '@/components/app/StatusMessageContext/statusMessageContext';
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter';
import { useDataSelect } from '@inventory/inventoryManagement/products/inputs/useDataSelect';

interface Props extends SelectProps {
  onChange?: (value: string | null) => void;
  initialValue?: string;
}

export const PaymentMethodTypeInput: FC<Props> = ({
  onChange,
  initialValue,
  ...props
}) => {
  const message = useStatusMessage();
  const { handleChange, handleSearch, queryParams } = useDataSelect({
    onChange,
    initialSelectedValue: initialValue,
  });

  const { data, refetch, loading } = usePaymentMethodQuery({
    variables: {
      ...queryParams,
      sort: ['name:asc'],
    },
  });

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const [createPaymentMethod, { loading: mutationLoading }] =
    useCreatePaymentMethodMutation({
      refetchQueries: ['paymentMethod'],
    });

  const options = useMemo(() => {
    const paymentMethodData = data?.paymentMethods?.data || [];

    return paymentMethodData?.map((brand) => ({
      value: brand?.id,
      label: capitalizeFirstLetter(brand?.attributes?.name as string),
    }));
  }, [data?.paymentMethods?.data]);

  const createNewType = useCallback(
    async (name: string) => {
      if (
        options?.find(
          (option) => option.label?.toLowerCase() === name.toLowerCase(),
        )
      ) {
        message.open('error', 'Payment method already exists');
        return;
      }

      await createPaymentMethod({
        variables: {
          input: {
            name: name.toLowerCase(),
          },
        },
        onCompleted: (data) => {
          if (data?.createPaymentMethod?.data?.id) {
            handleChange(data.createPaymentMethod.data.id);
          }
        },
        onError: (error) => {
          message.open('error', error.message);
        },
      });
    },
    [createPaymentMethod, handleChange, message, options],
  );

  return (
    <SearchSelect
      defaultValue={initialValue}
      placeholder={'Select payment method or create new one'}
      onChange={handleChange}
      value={initialValue}
      options={options}
      loading={loading || mutationLoading}
      onSearch={handleSearch}
      style={{ width: '100%' }}
      {...props}
      dropdownRender={(menu) => (
        <>
          {menu}
          <AddNewSelectButton handleAddNew={createNewType} maxNameLength={15} />
        </>
      )}
    />
  );
};
