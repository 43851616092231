import RenderContact from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderContact';
import RenderDate from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderDate';
import RenderId from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderId';
import RenderInventoryItem from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderInventoryItem';
import { MemoOutReportFullReturnActions } from '@components/reports/memoOut/MemoOutReportFullReturnActions';
import { MemoOutReportPartialReturnActions } from '@components/reports/memoOut/MemoOutReportPartialReturnActions';
import { MemoOutReportReturnedDateActions } from '@components/reports/memoOut/MemoOutReportReturnedDateActions';
import { TableElementLink } from '@ui/table/TableElementLink/TableElementLink';
import { COLUMN_WIDTH_L } from '@ui/table/helpers';
import { ColumnsType } from 'antd/es/table';

export const memoOutDefaultFilters = {
  memo: {
    ne: null,
  },
  status: {
    ne: 'draft',
  },
};

export function memoOutStatus(
  data: ProductOrderItemFragment['attributes'],
): string {
  let status = '-';

  if (data) {
    const { order } = data;
    const orderCreationDate = order?.data?.attributes?.createdAt;
    const createdAtDate = orderCreationDate
      ? new Date(orderCreationDate)
      : null;
    const memoDays = order?.data?.attributes?.memo
      ? Number(order?.data?.attributes?.memo)
      : 0;
    const memoExpiryDate = createdAtDate
      ? new Date(createdAtDate.setDate(createdAtDate.getDate() + memoDays))
      : null;
    const amountPaid = order?.data?.attributes?.paidSummary;
    const orderTotal = order?.data?.attributes?.total;

    const isDateExpired = !!(memoExpiryDate && memoExpiryDate < new Date());
    const isExpired = Number(amountPaid) === 0 && isDateExpired;
    const isPartiallyPaid =
      Number(amountPaid) > 0 &&
      Number(amountPaid) < Number(orderTotal) &&
      isDateExpired;
    const isPaid = Number(amountPaid) >= Number(orderTotal) && isDateExpired;

    if (!isDateExpired) {
      status = 'On Sale';
    } else if (isExpired) {
      status = 'Expired';
    } else if (isPartiallyPaid) {
      status = 'Partially Paid';
    } else if (isPaid) {
      status = 'Paid';
    }

    return status;
  } else {
    return status;
  }
}

export const memoOutProductColumns: ColumnsType<ProductOrderItemWithCustomerFragment> =
  [
    {
      key: 'salesOrder',
      title: 'SALES ORDER',
      render: (record) =>
        record?.attributes?.order?.data?.id ? (
          <TableElementLink
            relationId={record?.attributes?.order?.data?.id}
            relationUuid={record?.attributes?.order?.data?.attributes?.orderId}
            eventType={'sold'}
          >
            {record?.attributes?.order?.data?.attributes?.orderId}
          </TableElementLink>
        ) : (
          '-'
        ),
    },
    {
      key: 'productName',
      title: 'PRODUCT NAME',
      dataIndex: [
        'attributes',
        'product',
        'data',
        'attributes',
        'product',
        'data',
        'attributes',
        'name',
      ],
      width: COLUMN_WIDTH_L,
      render: (value, { attributes }) => {
        const data =
          attributes?.product?.data?.attributes?.product?.data?.attributes;

        return (
          <TableElementLink
            eventType={'products'}
            relationUuid={data?.uuid}
            customStyles={{
              width: '170px',
            }}
          >
            <RenderInventoryItem
              avatarName={value}
              avatarSrc={data?.files?.data?.[0]?.attributes?.url}
            />
          </TableElementLink>
        );
      },
      sorter: true,
    },
    {
      key: 'customer',
      title: 'CUSTOMER',
      dataIndex: [
        'attributes',
        'order',
        'data',
        'attributes',
        'contact',
        'data',
        'attributes',
        'fullName',
      ],
      render: (value, { attributes }: ProductOrderItemWithCustomerFragment) => {
        const customerUuid =
          attributes?.order?.data?.attributes?.contact?.data?.attributes
            ?.uuid ??
          attributes?.order?.data?.attributes?.company?.data?.attributes?.uuid;
        const customerType = attributes?.order?.data?.attributes?.contact?.data
          ?.attributes?.uuid
          ? 'contacts'
          : 'companies';

        return (
          <TableElementLink
            eventType={customerType}
            relationUuid={customerUuid}
            customStyles={{
              width: '170px',
            }}
          >
            <RenderContact
              value={value}
              attributes={attributes?.order?.data?.attributes}
              showImage={false}
            />
          </TableElementLink>
        );
      },
      sorter: true,
    },
    {
      key: 'sku',
      title: 'SKU',
      dataIndex: [
        'attributes',
        'product',
        'data',
        'attributes',
        'product',
        'data',
        'attributes',
        'SKU',
      ],
      render: (value) => <RenderId value={value || '-'} />,
    },
    {
      key: 'unitPrice',
      title: 'UNIT PRICE',
      dataIndex: ['attributes', 'price'],
      render: (value) => <RenderId value={value ?? 0} />,
      sorter: true,
    },
    {
      key: 'quantityOut',
      title: 'QUANTITY OUT',
      dataIndex: ['attributes'],
      render: (data) => (
        <RenderId value={`${data?.quantity}/${Number(data.quantity)}`} />
      ),
    },
    {
      key: 'sentDate',
      title: 'SENT DATE',
      dataIndex: ['attributes', 'order', 'data', 'attributes', 'createdAt'],
      render: (date) => <RenderDate value={date} />,
      sorter: true,
    },
    {
      key: 'expiryReceived',
      title: 'EXPIRY DATE',
      dataIndex: ['attributes', 'order', 'data', 'attributes', 'expiryDate'],
      render: (date) => <RenderDate value={date} />,
      sorter: true,
    },
    {
      key: 'status',
      title: 'STATUS',
      dataIndex: ['attributes'],
      render: (data) => {
        return <RenderId value={memoOutStatus(data)} />;
      },
    },
    {
      key: 'isPartiallyReturned',
      title: 'PARTIALLY RETURNED',
      render: (_, reportItem) => (
        <MemoOutReportPartialReturnActions
          reportItemId={reportItem?.id}
          isPartiallyReturned={reportItem?.attributes?.isPartiallyReturned}
        />
      ),
    },
    {
      key: 'isFullyReturned',
      title: 'FULLY RETURNED',
      render: (_, reportItem) => (
        <MemoOutReportFullReturnActions
          reportItemId={reportItem?.id}
          isFullyReturned={reportItem?.attributes?.isFullyReturned}
        />
      ),
    },
    {
      key: 'returnedDate',
      title: 'DATE RETURNED',
      width: COLUMN_WIDTH_L,
      render: (_, record) => (
        <MemoOutReportReturnedDateActions
          reportItemId={record?.id}
          returnedDate={record?.attributes?.returnedDate}
        />
      ),
    },
  ];

// export type and columns
export type MemoOutExportDataType = {
  id?: Maybe<string>;
  price?: Maybe<number>;
  quantity?: Maybe<number>;
  isPartiallyReturned?: Maybe<boolean>;
  isFullyReturned?: Maybe<boolean>;
  returnedDate?: Maybe<string | Date>;
  order?: Maybe<{
    id?: Maybe<string>;
    orderId?: Maybe<string>;
    createdAt?: Maybe<string | Date>;
    expiryDate?: Maybe<string | Date>;
    memo?: Maybe<number>;
    paidSummary?: Maybe<number>;
    total?: Maybe<number>;
    contact?: Maybe<{
      fullName?: Maybe<string>;
      uuid?: Maybe<string>;
    }>;
    company?: Maybe<{
      name?: Maybe<string>;
      uuid?: Maybe<string>;
    }>;
  }>;
  product?: Maybe<{
    product?: Maybe<{
      name?: Maybe<string>;
      uuid?: Maybe<string>;
      SKU?: Maybe<string>;
      files?: Maybe<
        {
          previewUrl?: Maybe<string>;
          alternativeText?: Maybe<string>;
          url?: Maybe<string>;
          size?: Maybe<string>;
          name?: Maybe<string>;
          mime?: Maybe<string>;
        }[]
      >;
    }>;
  }>;
};

export function memoOutExportStatus(data: MemoOutExportDataType): string {
  let status = '-';

  if (data) {
    const { order } = data;
    const orderCreationDate = order?.createdAt;
    const createdAtDate = orderCreationDate
      ? new Date(orderCreationDate)
      : null;
    const memoDays = order?.memo ? Number(order?.memo) : 0;
    const memoExpiryDate = createdAtDate
      ? new Date(createdAtDate.setDate(createdAtDate.getDate() + memoDays))
      : null;
    const amountPaid = order?.paidSummary;
    const orderTotal = order?.total;

    const isDateExpired = !!(memoExpiryDate && memoExpiryDate < new Date());
    const isExpired = Number(amountPaid) === 0 && isDateExpired;
    const isPartiallyPaid =
      Number(amountPaid) > 0 &&
      Number(amountPaid) < Number(orderTotal) &&
      isDateExpired;
    const isPaid = Number(amountPaid) >= Number(orderTotal) && isDateExpired;

    if (!isDateExpired) {
      status = 'On Sale';
    } else if (isExpired) {
      status = 'Expired';
    } else if (isPartiallyPaid) {
      status = 'Partially Paid';
    } else if (isPaid) {
      status = 'Paid';
    }

    return status;
  } else {
    return status;
  }
}

export const memoOutProductExportColumns: ColumnsType<MemoOutExportDataType> = [
  {
    key: 'salesOrder',
    title: 'SALES ORDER',
    render: (productOrderItem: MemoOutExportDataType) =>
      productOrderItem?.order?.id ? (
        <TableElementLink
          relationId={productOrderItem?.order?.id}
          relationUuid={productOrderItem?.order?.orderId}
          eventType={'sold'}
        >
          {productOrderItem?.order?.orderId}
        </TableElementLink>
      ) : (
        '-'
      ),
  },
  {
    key: 'productName',
    title: 'PRODUCT NAME',
    dataIndex: ['product', 'product', 'name'],
    width: COLUMN_WIDTH_L,
    render: (value, productOrderItem: MemoOutExportDataType) => {
      const data = productOrderItem?.product?.product;

      return (
        <TableElementLink
          eventType={'products'}
          relationUuid={data?.uuid}
          customStyles={{
            width: '170px',
          }}
        >
          <RenderInventoryItem
            avatarName={value}
            avatarSrc={data?.files?.[0]?.url ?? ''}
          />
        </TableElementLink>
      );
    },
  },
  {
    key: 'customer',
    title: 'CUSTOMER',
    dataIndex: ['order', 'contact', 'fullName'],
    render: (value, productOrderItem: MemoOutExportDataType) => {
      const customerName = value || productOrderItem?.order?.company?.name;

      return <RenderId value={customerName} />;
    },
  },
  {
    key: 'sku',
    title: 'SKU',
    dataIndex: ['product', 'product', 'SKU'],
    render: (value) => <RenderId value={value || '-'} />,
  },
  {
    key: 'unitPrice',
    title: 'UNIT PRICE',
    dataIndex: ['price'],
    render: (value) => <RenderId value={value ?? 0} />,
  },
  {
    key: 'quantityOut',
    title: 'QUANTITY OUT',
    render: (productOrderItem: MemoOutExportDataType) => (
      <RenderId
        value={`${productOrderItem?.quantity}/${Number(
          productOrderItem.quantity,
        )}`}
      />
    ),
  },
  {
    key: 'sentDate',
    title: 'SENT DATE',
    dataIndex: ['order', 'createdAt'],
    render: (date) => <RenderDate value={date} />,
  },
  {
    key: 'expiryReceived',
    title: 'EXPIRY DATE',
    dataIndex: ['order', 'expiryDate'],
    render: (date) => <RenderDate value={date} />,
  },
  {
    key: 'status',
    title: 'STATUS',
    render: (productOrderItem: MemoOutExportDataType) => {
      return <RenderId value={memoOutExportStatus(productOrderItem)} />;
    },
  },
  {
    key: 'isPartiallyReturned',
    title: 'PARTIALLY RETURNED',
    render: (_, productOrderItem: MemoOutExportDataType) => (
      <MemoOutReportPartialReturnActions
        reportItemId={productOrderItem?.id}
        isPartiallyReturned={productOrderItem?.isPartiallyReturned}
      />
    ),
  },
  {
    key: 'isFullyReturned',
    title: 'FULLY RETURNED',
    render: (_, productOrderItem: MemoOutExportDataType) => (
      <MemoOutReportFullReturnActions
        reportItemId={productOrderItem?.id}
        isFullyReturned={productOrderItem?.isFullyReturned}
      />
    ),
  },
  {
    key: 'returnedDate',
    title: 'DATE RETURNED',
    width: COLUMN_WIDTH_L,
    render: (_, productOrderItem: MemoOutExportDataType) => (
      <MemoOutReportReturnedDateActions
        reportItemId={productOrderItem?.id}
        returnedDate={productOrderItem?.returnedDate as Date}
      />
    ),
  },
];
