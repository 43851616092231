import React from 'react';

import { ConfigProvider, Space, Typography } from 'antd';

import { defaultPlatformCurrency, formatToCurrency } from '@/helpers/formatter';
import {
  AmountSummeriedColor,
  DealTransactionStatus,
} from '@components/accounting/transactions/types';

interface TransactionDetailsProps {
  value: number;
  attributes: DealTransactionFragment['attributes'];
}

const RenderSummaries: React.FC<TransactionDetailsProps> = ({
  value,
  attributes,
}) => {
  if (!value) {
    return null;
  }

  const isCost = attributes?.chartAccount?.data?.attributes?.type === 'cost';
  const transactionCurrency =
    attributes?.transactionCurrency ?? defaultPlatformCurrency;

  const total = `${
    isCost || attributes?.status === DealTransactionStatus.REFUNDED ? '-' : '+'
  } ${formatToCurrency(value, transactionCurrency)}`;

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            fontWeightStrong: 500,
            fontSizeHeading5: 14,
            fontSize: 12,
          },
        },
      }}
    >
      <Space size={1} direction={'vertical'}>
        <Typography.Title
          level={5}
          style={{
            color: AmountSummeriedColor[`${attributes?.status || 'Open'}`],
          }}
        >
          {total}
        </Typography.Title>
        <Typography.Text type={'secondary'} style={{ fontSize: 12 }}>
          {formatToCurrency(attributes?.paid, transactionCurrency)}
        </Typography.Text>
      </Space>
    </ConfigProvider>
  );
};

export default RenderSummaries;
