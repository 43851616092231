import { getCustomerRepairTicketData } from '@components/contracts/purchaseRequests/helpers/getCustomerRepairTicketData';
import { RepairTicketBusinessContacts } from '@components/contracts/repairTickets/RepairTicketBusinessContacts/RepairTicketBusinessContacts';
import { RepairTicketImage } from '@components/contracts/repairTickets/RepairTicketImage/RepairTicketImage';
import { RepairTicketItems } from '@components/contracts/repairTickets/RepairTicketItems/RepairTicketItems';
import { RepairTicketOrderInstructions } from '@components/contracts/repairTickets/RepairTicketOrderInstructions/RepairTicketOrderInstructions';
import { Loader } from '@components/layout/MainLayout';
import { getCurrency } from '@helpers/getCurrency';
import { useOrderData } from '@hooks/pos/useOrderData';
import { useTenantInfo } from '@hooks/useTenantInfo';
import { CustomDivider } from '@ui/divider/Divider';
import { Col, Row } from 'antd';
import { FC, memo } from 'react';

export type ShippingContactType = {
  id: string;
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
};

interface Props {
  orderId: string;
  useItemsVisibility?: boolean;
  isPriceVisible?: boolean;
  isCustomerInfoVisible?: boolean;
  isOrderNoteVisible?: boolean;
}

export const RepairTicketViewFieldsUi: FC<Props> = memo(
  ({
    orderId,
    useItemsVisibility = false,
    isPriceVisible,
    isCustomerInfoVisible,
    isOrderNoteVisible,
  }) => {
    const {
      orderProductsBuy,
      orderCompositeProducts,
      orderServices,
      orderClasses,
      orderMemberships,
      orderLoading,
      orderBusinessLocation,
      orderContact,
      orderCompany,
      orderCreationDate,
      orderDueDate,
      orderEmployee,
      orderUuid,
      orderFiles,
      orderType,
      orderNote,
    } = useOrderData(orderId, useItemsVisibility);

    const {
      orderCustomerName,
      orderCustomerCode,
      orderCustomerPhone,
      orderCustomerEmail,
    } = getCustomerRepairTicketData(orderContact, orderCompany);
    const orderEmployeeName = orderEmployee?.attributes?.fullName ?? '';
    const orderFileUrl = orderFiles?.[0]?.attributes?.url ?? '';

    const { mainLogo } = useTenantInfo();

    const currency = getCurrency(orderType);

    if (orderLoading) {
      return <Loader />;
    }

    return (
      <>
        <Row style={{ width: '100%', margin: 0 }}>
          <Col span={12} style={{ padding: '20px 20px 5px' }}>
            <RepairTicketOrderInstructions
              orderCustomerCode={orderCustomerCode}
              orderCustomerName={orderCustomerName}
              orderCustomerPhone={orderCustomerPhone}
              orderCustomerEmail={orderCustomerEmail}
              orderEmployeeName={orderEmployeeName}
              orderCreationDate={orderCreationDate}
              orderDueDate={orderDueDate}
              orderId={orderUuid}
              isCustomerInfoVisible={isCustomerInfoVisible}
            />
          </Col>
          <Col span={12} style={{ padding: '20px 20px 5px' }}>
            <RepairTicketBusinessContacts
              locationName={orderBusinessLocation?.attributes?.name ?? ''}
              locationAddress={
                orderBusinessLocation?.attributes?.location?.data?.attributes
                  ?.address ?? ''
              }
              locationPhone={
                orderBusinessLocation?.attributes?.phoneNumber ?? ''
              }
              locationEmail={orderBusinessLocation?.attributes?.email ?? ''}
              companyLogo={mainLogo}
            />
          </Col>
        </Row>

        <CustomDivider margin={0} />

        <Row style={{ width: '100%', margin: 0 }}>
          <Col span={12} style={{ padding: '5px 20px 20px' }}>
            <RepairTicketItems
              orderProductsBuy={orderProductsBuy as ProductOrderItemEntity[]}
              orderCompositeProducts={
                orderCompositeProducts as CompositeProductOrderItemEntity[]
              }
              orderServices={orderServices as ServiceOrderItemEntity[]}
              orderMemberships={orderMemberships as MembershipOrderItemEntity[]}
              orderClasses={orderClasses as ClassOrderItemEntity[]}
              isPriceVisible={isPriceVisible}
              currency={currency}
            />
          </Col>
          <Col span={12} style={{ padding: '5px 20px 20px' }}>
            <RepairTicketImage
              orderImage={orderFileUrl}
              orderNote={orderNote}
              isOrderNoteVisible={isOrderNoteVisible}
            />
          </Col>
        </Row>
      </>
    );
  },
);
