import { FC, useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib';

import { useCompaniesQuery } from '@/graphql';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

interface Props extends SelectProps {
  onChange?: (value: string) => void;
  initValue?: string;
  initFilter?: CompanyFiltersInput;
  isVendor?: boolean;
}

export const CompanySelect: FC<Props> = ({
  value,
  onChange,
  initValue,
  initFilter,
  isVendor = false,
  ...props
}) => {
  const [filters, setFilters] = useState<CompanyFiltersInput>({});
  const companyTypeFilter = isVendor ? { type: { eq: 'vendor' } } : {};

  const { data, refetch, loading } = useCompaniesQuery({
    variables: {
      filters: {
        ...initFilter,
        ...filters,
        ...companyTypeFilter,
      },
      pagination: {
        limit: 10,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const companies = data?.companies?.data || [];

  const storeItems = companies?.map((company) => ({
    value: company?.id,
    label: company?.attributes?.name,
  }));

  const handleSearch = (value: string) => {
    setFilters({
      name: {
        containsi: value,
      },
    });
  };

  return (
    <SearchSelect
      placeholder={'Please select the company'}
      onChange={onChange}
      options={storeItems}
      value={value}
      loading={loading}
      onSearch={handleSearch}
      defaultValue={initValue}
      {...props}
    />
  );
};
