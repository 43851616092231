import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { OrderPurchaseInvoiceInput } from '@components/selling/order-management/OrderCard/OrderPurchaseInvoiceInput';
import { useToken } from '@hooks/useToken';
import CustomTag from '@ui/tag';
import { ConfigProvider, Flex, Typography } from 'antd';
import capitalize from 'lodash/capitalize';
import { FC } from 'react';

interface Props {
  orderId: string;
  createAt: Maybe<Date>;
  orderType: EnumOrderType;
  ecommerceType?: string;
  ecommerceOrderId?: string;
}

export const OrderDrawerTitle: FC<Props> = ({
  orderId,
  createAt,
  orderType,
  ecommerceType,
  ecommerceOrderId,
}) => {
  const { token } = useToken();
  const { formatDateAndTime } = useUserSettingsContext();

  return (
    <Flex vertical gap={12}>
      <Flex gap={10}>
        <Typography.Title level={2}>{`Order ${orderId}`}</Typography.Title>
        <CustomTag
          style={{ maxHeight: 28 }}
          color={'blue'}
          title={capitalize(orderType) ?? 'Sell'}
        />
        {ecommerceType && (
          <CustomTag
            style={{ maxHeight: 28 }}
            color={'gold'}
            title={capitalize(ecommerceType)}
          />
        )}
      </Flex>
      <ConfigProvider
        theme={{
          token: {
            colorText: token.colorTextDescription,
            fontSize: 12,
          },
        }}
      >
        {ecommerceType === 'shopify' && (
          <Typography.Text style={{ fontWeight: '400' }}>
            Shopify Order ID: {ecommerceOrderId}
          </Typography.Text>
        )}
        <Typography.Text style={{ fontWeight: '400' }}>
          Created: {formatDateAndTime(createAt, true, 'DD MMMM YYYY')}
        </Typography.Text>
        <Typography.Text style={{ display: 'none' }}>
          Created: {`${formatDateAndTime(createAt, true, 'DD MMMM YYYY')}`}
        </Typography.Text>
      </ConfigProvider>
      {orderType === 'purchase' && (
        <OrderPurchaseInvoiceInput orderId={orderId} />
      )}
    </Flex>
  );
};
