import { getCustomAttributeOptionsArray } from '@filters/helpers/customAttribute/getCustomAttributeOptionsArray';
import { salesItemReportFilterKeys } from '@filters/helpers/customAttribute/static';
import {
  FilterFunction,
  LocationSublocationFilterInput,
  ShopProductFilterInput,
} from '@filters/types';

export const createSalesItemReportOrderFilter: FilterFunction<string> = (
  orderId?: string,
): SalesItemReportFiltersInput => {
  return orderId
    ? {
        order: {
          id: {
            eq: orderId,
          },
        },
      }
    : {};
};

export const createSalesItemReportShopItemFilter: FilterFunction<
  ShopProductFilterInput
> = ({
  productId,
  conditionType,
  genderType,
  process,
  metalFinishType,
  platting,
  metalType,
  materialGradeType,
  engravingType,
  timePeriod,
  productType,
  specificType,
  size,
  shankStyle,
  designStyle,
  backing,
  strand,
  strandsLength,
  knotStyle,
  linkStyle,
  linkType,
  brand,
  country,
  boxPaper,
  pieces,
  compositeProductId,
  serviceId,
  membershipId,
  classId,
  allFilters,
} = {}): SalesItemReportFiltersInput => {
  const { customAttributeOptionFilter, isCustomAttributeOptionSelected } =
    getCustomAttributeOptionsArray(allFilters, salesItemReportFilterKeys);

  const isProductFilters =
    productId ||
    conditionType ||
    genderType ||
    process ||
    metalFinishType ||
    platting ||
    metalType ||
    engravingType ||
    timePeriod ||
    productType ||
    isCustomAttributeOptionSelected;

  const productFilter = isProductFilters
    ? {
        productOrderItem: {
          product: {
            product: {
              id: {
                eq: productId,
              },
              conditionType: {
                id: {
                  eq: conditionType,
                },
              },
              genderType: {
                id: {
                  eq: genderType,
                },
              },
              process: {
                id: {
                  eq: process,
                },
              },
              metalFinishType: {
                id: {
                  eq: metalFinishType,
                },
              },
              platting: {
                id: {
                  eq: platting,
                },
              },
              metalType: {
                id: {
                  eq: metalType,
                },
              },
              materialGradeType: {
                id: {
                  eq: materialGradeType,
                },
              },
              engravingType: {
                id: {
                  eq: engravingType,
                },
              },
              timePeriod: {
                id: {
                  eq: timePeriod,
                },
              },
              productType: {
                id: {
                  eq: productType,
                },
              },
              specificType: {
                id: {
                  eq: specificType,
                },
              },
              size: {
                id: {
                  eq: size,
                },
              },
              shankStyle: {
                id: {
                  eq: shankStyle,
                },
              },
              designStyle: {
                id: {
                  eq: designStyle,
                },
              },
              backing: {
                id: {
                  eq: backing,
                },
              },
              strand: {
                id: {
                  eq: strand,
                },
              },
              strandsLength: {
                id: {
                  eq: strandsLength,
                },
              },
              knotStyle: {
                id: {
                  eq: knotStyle,
                },
              },
              linkStyle: {
                id: {
                  eq: linkStyle,
                },
              },
              linkType: {
                id: {
                  eq: linkType,
                },
              },
              brand: {
                id: {
                  eq: brand,
                },
              },
              country: {
                id: {
                  eq: country,
                },
              },
              boxPaper: {
                id: {
                  eq: boxPaper,
                },
              },
              pieces: {
                id: {
                  eq: pieces,
                },
              },
              and: customAttributeOptionFilter,
            },
          },
        },
      }
    : {};

  const compositeProductFilter = compositeProductId
    ? {
        compositeProductOrderItem: {
          compositeProduct: {
            compositeProduct: {
              id: {
                eq: compositeProductId,
              },
            },
          },
        },
      }
    : {};

  const serviceFilter = serviceId
    ? {
        serviceOrderItem: {
          service: {
            serviceLocationInfo: {
              service: {
                id: {
                  eq: serviceId,
                },
              },
            },
          },
        },
      }
    : {};

  const membershipFilter = membershipId
    ? {
        membershipOrderItem: {
          membership: {
            id: {
              eq: membershipId,
            },
          },
        },
      }
    : {};

  const classFilter = classId
    ? {
        classOrderItem: {
          class: {
            classLocationInfo: {
              class: {
                id: {
                  eq: classId,
                },
              },
            },
          },
        },
      }
    : {};

  return {
    or: [
      productFilter,
      compositeProductFilter,
      serviceFilter,
      membershipFilter,
      classFilter,
    ].filter((filterObject) => Object.keys(filterObject).length > 0),
  };
};

export const createSalesItemReportTypeFilter: FilterFunction<string> = (
  orderType?: string,
): SalesItemReportFiltersInput => {
  return orderType
    ? {
        type: {
          eq: orderType,
        },
      }
    : {};
};

export const createSalesItemReportBusinessLocationFilter: FilterFunction<
  LocationSublocationFilterInput
> = ({ locationIds, sublocation } = {}): SalesItemReportFiltersInput => {
  return locationIds?.length !== 0 || sublocation
    ? ({
        businessLocation: {
          id: {
            in: locationIds,
          },
        },
        sublocation: {
          id: {
            eq: sublocation,
          },
        },
      } as SalesItemReportFiltersInput)
    : {};
};

export const createSalesItemReportSalesFilter: FilterFunction<string> = (
  employee?: string,
): SalesItemReportFiltersInput => {
  return employee
    ? {
        sales: {
          id: {
            eq: employee,
          },
        },
      }
    : {};
};
