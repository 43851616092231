import { invoiceTableColumns } from '@components/contracts/invoices/helpers/static';
import { ClientMessageSection } from '@components/contracts/invoices/view/ClientMessageSection';
import { ContactInfoFields } from '@components/contracts/invoices/view/ContactInfoFields';
import { ContactInfoOrder } from '@components/contracts/invoices/view/ContactInfoOrder';
import { CountInfo } from '@components/contracts/invoices/view/CountInfo';
import { InvoiceFooterSection } from '@components/contracts/invoices/view/InvoiceFooterSection';
import { Loader } from '@components/layout/MainLayout';
import { getCurrency } from '@helpers/getCurrency';
import { useOrderData } from '@hooks/pos/useOrderData';
import { useTenantInfo } from '@hooks/useTenantInfo';
import { Col, ConfigProvider, Row, Table } from 'antd';
import { FC, memo, useMemo } from 'react';

export type ShippingContactType = {
  id: string;
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
};

interface Props {
  orderId: string;
  clientMessage?: string;
  terms?: string;
  entityType?: 'request' | 'invoice' | 'estimate';
  useItemsVisibility?: boolean;
  isShowOrderItemsImages?: boolean;
  shippingContact?: Maybe<ShippingContactType>;
  businessLocation?: Maybe<BusinessLocationFragment>;
  isInvoiceDiscountEnabled?: boolean;
  isInvoiceTaxEnabled?: boolean;
  isInvoiceTipEnabled?: boolean;
  isPurchaseDiscountEnabled?: boolean;
  isPurchaseTaxEnabled?: boolean;
  isPurchaseTipEnabled?: boolean;
  invoiceSignature?: boolean;
}

export const InvoiceViewFieldsUi: FC<Props> = memo(
  ({
    orderId,
    clientMessage,
    terms,
    entityType = 'invoice',
    useItemsVisibility = false,
    shippingContact,
    businessLocation,
    isShowOrderItemsImages,
    isInvoiceDiscountEnabled,
    isInvoiceTaxEnabled,
    isInvoiceTipEnabled,
    isPurchaseDiscountEnabled,
    isPurchaseTaxEnabled,
    isPurchaseTipEnabled,
    invoiceSignature,
  }) => {
    const {
      order,
      orderProductsBuy,
      orderCompositeProducts,
      orderServices,
      orderClasses,
      orderMemberships,
      orderLoading,
      orderType,
      orderCompany,
      orderContact,
    } = useOrderData(orderId, useItemsVisibility);

    const currency = getCurrency(orderType);

    const { mainSiteUrl, mainEmail, mainPhone, mainCompanyName } =
      useTenantInfo();

    const dataSource = useMemo(
      () => [
        ...orderProductsBuy,
        ...orderCompositeProducts,
        ...orderServices,
        ...orderClasses,
        ...orderMemberships,
      ],
      [
        orderProductsBuy,
        orderCompositeProducts,
        orderServices,
        orderClasses,
        orderMemberships,
      ],
    );

    if (orderLoading) {
      return <Loader />;
    }

    return (
      <>
        <Row style={{ width: '100%', margin: 0 }}>
          <Col span={12}>
            <ContactInfoOrder
              title={entityType === 'request' ? 'Vendor:' : 'Billing to :'}
              orderId={orderId}
            />
          </Col>

          <Col span={12}>
            {shippingContact ? (
              <ContactInfoFields
                shippingContact={shippingContact}
                title={'Shipping to :'}
              />
            ) : (
              <ContactInfoOrder
                title={'Shipping to :'}
                orderId={orderId}
                clientName={mainCompanyName}
                clientAddress={
                  businessLocation?.attributes?.location?.data?.attributes
                    ?.address || ''
                }
                clientPhone={businessLocation?.attributes?.phoneNumber || ''}
                clientEmail={businessLocation?.attributes?.email || ''}
              />
            )}
          </Col>
        </Row>

        <ConfigProvider
          theme={{
            token: {
              colorSplit: '#FFF',
              colorBorderSecondary: '#F7F8FA',
              lineWidth: 2,
              fontSize: 12,
              colorTextHeading: '#9E9E9E',
            },
          }}
        >
          <Table
            columns={invoiceTableColumns(
              'invoice',
              isShowOrderItemsImages,
              currency,
              entityType === 'request' || entityType === 'estimate'
                ? isPurchaseDiscountEnabled
                : isInvoiceDiscountEnabled,
            )}
            dataSource={dataSource}
            pagination={false}
            rowKey={'id'}
            style={{ width: '100%', zIndex: '1000' }}
          />
        </ConfigProvider>

        <CountInfo
          orderId={orderId}
          isDiscountEnabled={
            entityType === 'request' || entityType === 'estimate'
              ? isPurchaseDiscountEnabled
              : isInvoiceDiscountEnabled
          }
          isTaxEnabled={
            entityType === 'request' || entityType === 'estimate'
              ? isPurchaseTaxEnabled
              : isInvoiceTaxEnabled
          }
          isTipEnabled={
            entityType === 'request' || entityType === 'estimate'
              ? isPurchaseTipEnabled
              : isInvoiceTipEnabled
          }
          invoiceSignature={entityType === 'request' ? false : invoiceSignature}
        />

        <ClientMessageSection
          clientMessage={clientMessage ?? ''}
          orderTotal={order?.attributes?.total ?? 0}
          paidAmount={order?.attributes?.paidSummary ?? 0}
          currency={currency}
        />

        <InvoiceFooterSection
          terms={terms ?? ''}
          email={mainEmail}
          phoneNumber={mainPhone}
          url={mainSiteUrl}
        />
      </>
    );
  },
);
