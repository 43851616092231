import { useCallback, useMemo, useState } from 'react';

type Filter = {
  orderId?: InputMaybe<StringFilterInput>;
};

interface Props {
  onChange?: (value: string) => void;
}

export const useOrderIdDataSelect = ({ onChange }: Props) => {
  const [filters, setFilters] = useState<Filter>({});
  const [isClearSearch, setIsClearSearch] = useState(false);

  const handleChange = useCallback(
    (value: string) => {
      onChange?.(value);
    },
    [onChange],
  );

  const queryParams = useMemo(
    () => ({
      filters,
      sort: ['customCreationDate:desc'],
    }),
    [filters],
  );

  const handleSearch = (value: string) => {
    setFilters({
      orderId: {
        containsi: value,
      },
    });
  };

  const handleClearSearch = () => {
    setIsClearSearch(true);

    setTimeout(() => {
      setIsClearSearch(false);
    }, 10);
  };

  return {
    handleChange,
    handleSearch,
    queryParams,
    isClearSearch,
    handleClearSearch,
  };
};
