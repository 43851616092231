import { useUpdateMemoOutReportPartialReturn } from '@components/reports/memoOut/hooks/useUpdateMemoOutReportPartialReturn';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC } from 'react';

type Props = {
  reportItemId: Maybe<string>;
  isPartiallyReturned: Maybe<boolean>;
};

export const MemoOutReportPartialReturnActions: FC<Props> = ({
  reportItemId,
  isPartiallyReturned,
}) => {
  const { handleMemoOutReportPartialReturnChange, loading } =
    useUpdateMemoOutReportPartialReturn(reportItemId);

  const onCheckboxChange = async (e: CheckboxChangeEvent) => {
    await handleMemoOutReportPartialReturnChange(e.target.checked);
  };

  return (
    <Checkbox
      onChange={onCheckboxChange}
      defaultChecked={!!isPartiallyReturned}
      disabled={loading as boolean}
    />
  );
};
