import { useUpdateMemoOutReportFullReturn } from '@components/reports/memoOut/hooks/useUpdateMemoOutReportFullReturn';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC } from 'react';

type Props = {
  reportItemId: Maybe<string>;
  isFullyReturned: Maybe<boolean>;
};

export const MemoOutReportFullReturnActions: FC<Props> = ({
  reportItemId,
  isFullyReturned,
}) => {
  const { handleMemoOutReportFullReturnChange, loading } =
    useUpdateMemoOutReportFullReturn(reportItemId);

  const onCheckboxChange = async (e: CheckboxChangeEvent) => {
    await handleMemoOutReportFullReturnChange(e.target.checked);
  };

  return (
    <Checkbox
      onChange={onCheckboxChange}
      defaultChecked={!!isFullyReturned}
      disabled={loading as boolean}
    />
  );
};
