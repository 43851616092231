import { useUpdateInvoice } from '@components/contracts/invoices/hooks/useUpdateInvoice';
import { InvoiceViewFieldsUi } from '@components/contracts/invoices/view/InvoiceViewFieldsUi';
import { useDocumentPermissionInfo } from '@hooks/documentPermissions/useDocumentPermissionInfo';
import { FC } from 'react';

interface Props {
  uuid: string;
  useItemsVisibility?: boolean;
}

export const InvoiceViewFields: FC<Props> = ({ uuid, useItemsVisibility }) => {
  const { initialValues } = useUpdateInvoice(uuid as string);
  const {
    isShowOrderItemsImages,
    isInvoiceTermsEnabled,
    isInvoiceClientMessageEnabled,
    invoiceClientMessage,
    invoiceTerms,
    isInvoiceDiscountEnabled,
    isInvoiceTaxEnabled,
    isInvoiceTipEnabled,
    invoiceSignature,
  } = useDocumentPermissionInfo();

  return (
    <InvoiceViewFieldsUi
      shippingContact={initialValues.shippingContact}
      orderId={initialValues.order ?? ''}
      clientMessage={
        isInvoiceClientMessageEnabled
          ? invoiceClientMessage
          : initialValues.clientMessage ?? ''
      }
      terms={isInvoiceTermsEnabled ? invoiceTerms : initialValues.terms ?? ''}
      useItemsVisibility={useItemsVisibility}
      isShowOrderItemsImages={
        isShowOrderItemsImages || initialValues.isShowOrderItemsImages
      }
      isInvoiceDiscountEnabled={isInvoiceDiscountEnabled}
      isInvoiceTaxEnabled={isInvoiceTaxEnabled}
      isInvoiceTipEnabled={isInvoiceTipEnabled}
      invoiceSignature={invoiceSignature}
    />
  );
};
