import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';

type StringObject = { [key: string]: any };
const twoDigitsAfterComma = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
const zeroDigitsAfterComma = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const formatNumber = (
  value?: number | null,
  additionalOptions?: Intl.NumberFormatOptions,
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    ...twoDigitsAfterComma,
    ...additionalOptions,
  }).format(value || 0);
};

export const defaultPlatformCurrency = 'USD';

export const formatToCurrency = (
  value?: number | null,
  currency = defaultPlatformCurrency,
  zeroDigitsOptions = false,
): string => {
  if (currency === 'POINTS') {
    return `${formatNumber(value, zeroDigitsAfterComma)} Points`;
  } else {
    return formatNumber(value, {
      currency: currency,
      style: 'currency',
      ...(zeroDigitsOptions && zeroDigitsAfterComma),
    });
  }
};

export const formatToPercentage = (value?: number | null): string => {
  if (value == null) return '';

  return `${value.toFixed(2)}%`;
};

export const formatToDays = (value?: number | null): string => {
  if (value == null) return '';

  return `${value} ${value === 1 ? 'day' : 'days'}`;
};

export const convertToMegabytes = (
  value: number,
  unit: 'bytes' | 'kilobytes' = 'bytes',
): number => {
  if (unit === 'bytes') {
    return value / (1024 * 1024);
  } else if (unit === 'kilobytes') {
    return value / 1024;
  } else {
    throw new Error('Invalid unit');
  }
};

export const formatFileSize = (sizeInBytes?: number): string => {
  if (!sizeInBytes) return 'Unknown size';

  const sizeInKb = sizeInBytes / 1024;
  const sizeInMb = sizeInKb / 1024;

  const size = sizeInMb >= 0.01 ? sizeInMb : sizeInKb;
  const unit = sizeInMb >= 0.01 ? 'MB' : 'KB';

  return `${size.toFixed(2)} ${unit}`;
};

export const removeUnwantedChars = (inputString?: string): string => {
  if (!inputString) return '';
  const unwantedChars = /[\r\n\t\f]/g;
  return inputString ? toString(inputString).replace(unwantedChars, '') : '';
};

export const cleanObjectValues = (obj: StringObject) => {
  return Object.keys(obj).reduce((acc: StringObject, key) => {
    acc[key] =
      typeof obj[key] === 'string' ? removeUnwantedChars(obj[key]) : obj[key];
    return acc;
  }, {});
};

export const toCamelCase = (header: string): string => {
  return header
    .trim()
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const padArray = <T>(
  arr: T[],
  minLength: number,
  fillValue?: T,
): T[] => {
  return arr.length >= minLength
    ? arr
    : [...arr, ...new Array(minLength - arr.length).fill(fillValue)];
};

export const replaceUnderscoresWithSpaces = (arr: string[]) => {
  return arr.map((item) => item.replace(/_/g, ' '));
};

export const formatNumberWithCommas = (
  value: Maybe<number | string>,
): string => {
  if (isNil(value)) return '';

  const numberValue = toNumber(value);

  if (isNaN(numberValue)) {
    return value.toString();
  }

  return numberValue.toLocaleString('en-US');
};

export const formattedWebsite = (website: string): string =>
  website && !website.match(/^https?:\/\//) ? `https://${website}` : website;

export const formatCurrencyTwoDecimal = (amount: number): string => {
  return amount.toFixed(2);
};
