import { useSendInvoiceMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

type PropsType = {
  id: string;
  subjectEmail?: string;
  subjectPhone?: string;
  contactId?: string;
  fileType?: string;
  customSmsSubjectContent?: string;
  customSmsContactContent?: string;
  sendBySms?: boolean;
  sendByEmail?: boolean;
};

export function useSendInvoice(): {
  handleSendInvoice: (data: PropsType) => Promise<void>;
} {
  const message = useStatusMessage();
  const [send] = useSendInvoiceMutation({
    onCompleted: (data) => {
      if (data?.sendInvoice?.errors?.length) {
        data.sendInvoice.errors.forEach((error) => {
          error && message.open('error', error);
        });
        message.open('success', 'Request completed with some errors');
      } else if (
        data?.sendInvoice?.success &&
        data?.sendInvoice?.errors?.length === 0
      ) {
        message.open('success', 'Request completed successfully');
      }
    },
    onError: () => {
      message.open('error', 'Something went wrong');
    },
  });

  const handleSendInvoice = async (data: PropsType) => {
    await send({
      variables: {
        id: data.id,
        email: data.subjectEmail,
        contactId: data.contactId,
        fileType: data.fileType,
        phone: data.subjectPhone,
        customContactMessage: data.customSmsContactContent,
        customSubjectMessage: data.customSmsSubjectContent,
        sendByEmail: data.sendByEmail,
        sendBySms: data.sendBySms,
      },
    });
  };

  return {
    handleSendInvoice,
  };
}
