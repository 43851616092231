import { useUpdateInvoice } from '@components/contracts/invoices/hooks/useUpdateInvoice';
import { RepairTicketViewFieldsUi } from '@components/contracts/repairTickets/RepairTicketViewFieldsUi';
import { useDocumentPermissionInfo } from '@hooks/documentPermissions/useDocumentPermissionInfo';
import { FC } from 'react';

interface Props {
  uuid: string;
  useItemsVisibility?: boolean;
}

export const RepairTicketViewFields: FC<Props> = ({
  uuid,
  useItemsVisibility,
}) => {
  const { initialValues } = useUpdateInvoice(uuid as string);
  const {
    isInternalRepairTicketPriceEnabled,
    isShowOrderNoteAtInternalRepairTicker,
  } = useDocumentPermissionInfo();

  return (
    <RepairTicketViewFieldsUi
      orderId={initialValues.order ?? ''}
      useItemsVisibility={useItemsVisibility}
      isPriceVisible={isInternalRepairTicketPriceEnabled}
      isOrderNoteVisible={isShowOrderNoteAtInternalRepairTicker}
    />
  );
};
