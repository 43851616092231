import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import { CustomFormItem } from '@form/item/FormItem';
import { CompanySelect } from '@ui/form/CompanySelect';
import ContactsSelect from '@ui/form/ContactsSelect';
import { CustomDayPicker } from '@ui/form/CustomPicker/CustomDayPicker';
import { CustomMonthPicker } from '@ui/form/CustomPicker/CustomMonthPicker';
import EmployeeSelect from '@ui/form/EmployeeSelect';
import LeadSourceSelect from '@ui/form/LeadSourceSelect';
import { Col, Row } from 'antd';
import { FC } from 'react';

const customInputStyles = { margin: 0 };

export const ContactAdditionalFields: FC<
  AdditionalFieldProps & { entityName: string }
> = ({ values, entityName }) => (
  <>
    <ExpandableFormItem
      name={'contact'}
      label={'Contact'}
      defaultOpen={!!values?.contact}
    >
      <ContactsSelect defaultValue={values?.contact ?? undefined} />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'leadSource'}
      label={'Lead Source'}
      defaultOpen={!!values?.leadSource?.length}
      withDivider={entityName === 'contacts'}
    >
      <LeadSourceSelect mode={'multiple'} />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'leadOwner'}
      label={'Contact Owner'}
      defaultOpen={!!values?.leadOwner?.length}
      withDivider
    >
      <EmployeeSelect
        mode={'multiple'}
        shouldApplyDefaultValue={false}
        placeholder={'Filter contacts by Contact Owner'}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'company'}
      label={'Company'}
      defaultOpen={!!values?.company?.length}
      withDivider
    >
      <CompanySelect allowClear />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Birth Date'}
      defaultOpen={
        !!values?.birthdayDateRange ||
        !!values?.birthdayDateMonth ||
        !!values?.birthdayDateDay
      }
      withDivider
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <CustomFormItem
            label={'Birth Date Range'}
            name={'birthdayDateRange'}
            style={customInputStyles}
          >
            <DateRangeInput style={{ width: '100%' }} />
          </CustomFormItem>
        </Col>
        <Col span={12}>
          <CustomFormItem
            label={'Birth Date Month'}
            name={'birthdayDateMonth'}
            style={customInputStyles}
          >
            <CustomMonthPicker />
          </CustomFormItem>
        </Col>
        <Col span={12}>
          <CustomFormItem
            label={'Birth Date Day'}
            name={'birthdayDateDay'}
            style={customInputStyles}
          >
            <CustomDayPicker />
          </CustomFormItem>
        </Col>
      </Row>
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Anniversary Date'}
      defaultOpen={
        !!values?.dateAnniversaryRange ||
        !!values?.dateAnniversaryMonth ||
        !!values?.dateAnniversaryDay
      }
      withDivider
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <CustomFormItem
            label={'Anniversary Date Range'}
            name={'dateAnniversaryRange'}
            style={customInputStyles}
          >
            <DateRangeInput style={{ width: '100%' }} />
          </CustomFormItem>
        </Col>
        <Col span={12}>
          <CustomFormItem
            label={'Anniversary Date Month'}
            name={'dateAnniversaryMonth'}
            style={customInputStyles}
          >
            <CustomMonthPicker />
          </CustomFormItem>
        </Col>
        <Col span={12}>
          <CustomFormItem
            label={'Anniversary Date Day'}
            name={'dateAnniversaryDay'}
            style={customInputStyles}
          >
            <CustomDayPicker />
          </CustomFormItem>
        </Col>
      </Row>
    </ExpandableFormItem>
  </>
);
